import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: string;
  /** An ISO 8601-encoded datetime */
  Iso8601DateTimeWithoutZone: string;
  /** Represents untyped JSON */
  JSON: any;
  /** A string with leading / trailing whitespace trimmed */
  StrippedString: string;
};

export type AbilityRule = {
  __typename?: 'AbilityRule';
  action: Array<Scalars['String']>;
  conditions?: Maybe<Scalars['JSON']>;
  fields?: Maybe<Array<Scalars['String']>>;
  inverted: Scalars['Boolean'];
  subject: Array<Scalars['String']>;
};

export type CancelOrderMutationInput = {
  id: Scalars['ID'];
};

/** Autogenerated return type of CancelOrderMutation */
export type CancelOrderMutationPayload = {
  __typename?: 'CancelOrderMutationPayload';
  errors: Array<MutationError>;
  order?: Maybe<Order>;
  success: Scalars['Boolean'];
};

export enum ClienteleKindEnum {
  B2B = 'B2B',
  B2C = 'B2C'
}

export type CreateApiClientFileMutationInput = {
  file: Scalars['String'];
  fileName: Scalars['StrippedString'];
  platformName: Scalars['StrippedString'];
};

/** Autogenerated return type of CreateAPIClientFileMutation */
export type CreateApiClientFileMutationPayload = {
  __typename?: 'CreateAPIClientFileMutationPayload';
  apiClientFile: CustomerApiClientFile;
  errors: Array<MutationError>;
  success: Scalars['Boolean'];
};

export type CreateApiTokenMutationInput = {
  customerId: Scalars['ID'];
};

/** Autogenerated return type of CreateAPITokenMutation */
export type CreateApiTokenMutationPayload = {
  __typename?: 'CreateAPITokenMutationPayload';
  apiToken: CustomerApiToken;
  errors: Array<MutationError>;
  success: Scalars['Boolean'];
};

export type CreateCustomerMutationInput = {
  adminEmail: Scalars['StrippedString'];
  clienteleKind?: Maybe<ClienteleKindEnum>;
  contactEmail: Scalars['StrippedString'];
  contactPhone: Scalars['StrippedString'];
  country: ServiceCountryEnum;
  logId: Scalars['StrippedString'];
  name: Scalars['StrippedString'];
};

/** Autogenerated return type of CreateCustomerMutation */
export type CreateCustomerMutationPayload = {
  __typename?: 'CreateCustomerMutationPayload';
  customer?: Maybe<Customer>;
  errors: Array<MutationError>;
  success: Scalars['Boolean'];
};

export type CreateCustomerUserMutationInput = {
  customerId: Scalars['ID'];
  customerSettings: Scalars['Boolean'];
  email: Scalars['StrippedString'];
  invoiceAccess: Scalars['Boolean'];
  name?: Maybe<Scalars['StrippedString']>;
  orderManagement: Scalars['Boolean'];
  password?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['StrippedString']>;
  userManagement: Scalars['Boolean'];
};

/** Autogenerated return type of CreateCustomerUserMutation */
export type CreateCustomerUserMutationPayload = {
  __typename?: 'CreateCustomerUserMutationPayload';
  errors: Array<MutationError>;
  success: Scalars['Boolean'];
  user?: Maybe<User>;
};

/** Autogenerated return type of CreateGlobalAPITokenMutation */
export type CreateGlobalApiTokenMutationPayload = {
  __typename?: 'CreateGlobalAPITokenMutationPayload';
  apiToken: CustomerApiToken;
  errors: Array<MutationError>;
  success: Scalars['Boolean'];
};

export type CreateGlobalUserMutationInput = {
  email: Scalars['StrippedString'];
  globalSettings: Scalars['Boolean'];
  name?: Maybe<Scalars['StrippedString']>;
  password?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['StrippedString']>;
  primaryCountry?: Maybe<Scalars['StrippedString']>;
};

/** Autogenerated return type of CreateGlobalUserMutation */
export type CreateGlobalUserMutationPayload = {
  __typename?: 'CreateGlobalUserMutationPayload';
  errors: Array<MutationError>;
  success: Scalars['Boolean'];
  user?: Maybe<User>;
};

export type CreateOrderMutationInput = {
  deliveryKind: DeliveryKindEnum;
  deliveryLocationId?: Maybe<Scalars['ID']>;
  destinationAddress?: Maybe<Scalars['StrippedString']>;
  destinationCity?: Maybe<Scalars['StrippedString']>;
  destinationCountry?: Maybe<ServiceCountryEnum>;
  destinationZip?: Maybe<Scalars['StrippedString']>;
  heightCm: Scalars['Float'];
  lengthCm: Scalars['Float'];
  pickupLocationId: Scalars['ID'];
  recipientEmail: Scalars['StrippedString'];
  recipientName: Scalars['StrippedString'];
  recipientPhone: Scalars['StrippedString'];
  senderComment?: Maybe<Scalars['StrippedString']>;
  senderReference?: Maybe<Scalars['StrippedString']>;
  weightKg: Scalars['Float'];
  widthCm: Scalars['Float'];
};

/** Autogenerated return type of CreateOrderMutation */
export type CreateOrderMutationPayload = {
  __typename?: 'CreateOrderMutationPayload';
  errors: Array<MutationError>;
  order?: Maybe<Order>;
  success: Scalars['Boolean'];
};

export type CreatePickupLocationMutationInput = {
  active: Scalars['Boolean'];
  address: Scalars['StrippedString'];
  city: Scalars['StrippedString'];
  country: Scalars['StrippedString'];
  customerId: Scalars['ID'];
  defaultHeightCm?: Maybe<Scalars['Float']>;
  defaultLengthCm?: Maybe<Scalars['Float']>;
  defaultWeightKg?: Maybe<Scalars['Float']>;
  defaultWidthCm?: Maybe<Scalars['Float']>;
  email: Scalars['StrippedString'];
  name: Scalars['StrippedString'];
  phone: Scalars['StrippedString'];
  zip: Scalars['StrippedString'];
};

/** Autogenerated return type of CreatePickupLocationMutation */
export type CreatePickupLocationMutationPayload = {
  __typename?: 'CreatePickupLocationMutationPayload';
  errors: Array<MutationError>;
  pickupLocation?: Maybe<Order>;
  success: Scalars['Boolean'];
};

export type CreatePickupScheduleMutationInput = {
  customerId: Scalars['ID'];
  friday?: Maybe<Scalars['Boolean']>;
  monday?: Maybe<Scalars['Boolean']>;
  pickupFrom: Scalars['Iso8601DateTimeWithoutZone'];
  pickupLocationId: Scalars['ID'];
  pickupTo: Scalars['Iso8601DateTimeWithoutZone'];
  saturday?: Maybe<Scalars['Boolean']>;
  sunday?: Maybe<Scalars['Boolean']>;
  thursday?: Maybe<Scalars['Boolean']>;
  tuesday?: Maybe<Scalars['Boolean']>;
  wednesday?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of CreatePickupScheduleMutation */
export type CreatePickupScheduleMutationPayload = {
  __typename?: 'CreatePickupScheduleMutationPayload';
  errors: Array<MutationError>;
  pickupSchedule?: Maybe<PickupSchedule>;
  success: Scalars['Boolean'];
};

export enum CurrencyEnum {
  Eur = 'EUR',
  Pln = 'PLN',
  Sek = 'SEK'
}

export type Customer = {
  __typename?: 'Customer';
  clienteleKind: ClienteleKindEnum;
  contactEmail?: Maybe<Scalars['String']>;
  contactName?: Maybe<Scalars['String']>;
  contactPhone?: Maybe<Scalars['String']>;
  country: ServiceCountryEnum;
  createdAt: Scalars['ISO8601DateTime'];
  defaultDeliveryCountries: DefaultDeliveryCountryConnection;
  deliveryCountries: CustomerDeliveryCountryConnection;
  freeformPickupAddressAllowed: Scalars['Boolean'];
  id: Scalars['ID'];
  invoiceEmail?: Maybe<Scalars['String']>;
  logId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  supportEmail?: Maybe<Scalars['String']>;
  supportName?: Maybe<Scalars['String']>;
  supportPhone?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
};


export type CustomerDefaultDeliveryCountriesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type CustomerDeliveryCountriesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type CustomerApiClientFile = {
  __typename?: 'CustomerAPIClientFile';
  createdAt: Scalars['ISO8601DateTime'];
  fileName: Scalars['String'];
  fileUrl?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  platformName: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
};

/** The connection type for CustomerAPIClientFile. */
export type CustomerApiClientFileConnection = {
  __typename?: 'CustomerAPIClientFileConnection';
  /** A list of edges. */
  edges: Array<CustomerApiClientFileEdge>;
  /** A list of nodes. */
  nodes: Array<CustomerApiClientFile>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalNodes: Scalars['Int'];
};

/** An edge in a connection. */
export type CustomerApiClientFileEdge = {
  __typename?: 'CustomerAPIClientFileEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<CustomerApiClientFile>;
};

export type CustomerApiClientFileFilter = {
  m?: Maybe<FilterGroupingEnum>;
  sorts?: Maybe<Array<CustomerApiClientFileSort>>;
};

export type CustomerApiClientFileSort = {
  dir: SortDirectionEnum;
  name: CustomerApiClientFileSortEnum;
};

export enum CustomerApiClientFileSortEnum {
  CreatedAt = 'created_at',
  FileName = 'file_name',
  PlatformName = 'platform_name'
}

export type CustomerApiToken = {
  __typename?: 'CustomerAPIToken';
  accessedAt?: Maybe<Scalars['ISO8601DateTime']>;
  createdAt: Scalars['ISO8601DateTime'];
  customer?: Maybe<Customer>;
  customerId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  token?: Maybe<Scalars['String']>;
};

/** The connection type for CustomerAPIToken. */
export type CustomerApiTokenConnection = {
  __typename?: 'CustomerAPITokenConnection';
  /** A list of edges. */
  edges: Array<CustomerApiTokenEdge>;
  /** A list of nodes. */
  nodes: Array<CustomerApiToken>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalNodes: Scalars['Int'];
};

/** An edge in a connection. */
export type CustomerApiTokenEdge = {
  __typename?: 'CustomerAPITokenEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<CustomerApiToken>;
};

export type CustomerApiTokenFilter = {
  customerIdEq?: Maybe<Scalars['ID']>;
  customerIdIn?: Maybe<Array<Scalars['ID']>>;
  m?: Maybe<FilterGroupingEnum>;
  scopeEq?: Maybe<Scalars['String']>;
  scopeIn?: Maybe<Array<Scalars['String']>>;
  scopeMatches?: Maybe<Scalars['String']>;
  sorts?: Maybe<Array<CustomerApiTokenSort>>;
};

export type CustomerApiTokenSort = {
  dir: SortDirectionEnum;
  name: CustomerApiTokenSortEnum;
};

export enum CustomerApiTokenSortEnum {
  AccessedAt = 'accessed_at',
  CreatedAt = 'created_at'
}

/** The connection type for Customer. */
export type CustomerConnection = {
  __typename?: 'CustomerConnection';
  /** A list of edges. */
  edges: Array<CustomerEdge>;
  /** A list of nodes. */
  nodes: Array<Customer>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalNodes: Scalars['Int'];
};

export type CustomerDeliveryCountry = {
  __typename?: 'CustomerDeliveryCountry';
  country: ServiceCountryEnum;
  courierDeliverTime?: Maybe<Scalars['String']>;
  courierDestinationZipRegexp?: Maybe<Scalars['String']>;
  courierDirections?: Maybe<Scalars['JSON']>;
  courierMaxEdgeSumCm?: Maybe<Scalars['Float']>;
  courierMaxHeightCm?: Maybe<Scalars['Float']>;
  courierMaxLengthCm?: Maybe<Scalars['Float']>;
  courierMaxWeightKg?: Maybe<Scalars['Float']>;
  courierMaxWidthCm?: Maybe<Scalars['Float']>;
  courierMinHeightCm?: Maybe<Scalars['Float']>;
  courierMinLengthCm?: Maybe<Scalars['Float']>;
  courierMinWeightKg?: Maybe<Scalars['Float']>;
  courierMinWidthCm?: Maybe<Scalars['Float']>;
  courierPartnerConfiguration?: Maybe<PartnerConfiguration>;
  courierRecipientPhonePrefix?: Maybe<Scalars['String']>;
  courierRecipientPhoneRegexp?: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  customer: Customer;
  customerId: Scalars['ID'];
  deliveryLocationPartnerConfiguration?: Maybe<PartnerConfiguration>;
  destinationZipRegexp?: Maybe<Scalars['String']>;
  directions?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  insuranceValue?: Maybe<Scalars['Float']>;
  insuranceValueCurrency?: Maybe<CurrencyEnum>;
  maxEdgeSumCm?: Maybe<Scalars['Float']>;
  maxHeightCm?: Maybe<Scalars['Float']>;
  maxLengthCm?: Maybe<Scalars['Float']>;
  maxWeightKg?: Maybe<Scalars['Float']>;
  maxWidthCm?: Maybe<Scalars['Float']>;
  minHeightCm?: Maybe<Scalars['Float']>;
  minLengthCm?: Maybe<Scalars['Float']>;
  minWeightKg?: Maybe<Scalars['Float']>;
  minWidthCm?: Maybe<Scalars['Float']>;
  priceRules: CustomerDeliveryCountryPriceRuleConnection;
  recipientPhonePrefix?: Maybe<Scalars['String']>;
  recipientPhoneRegexp?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
  volumeWeightKg?: Maybe<Scalars['Float']>;
};


export type CustomerDeliveryCountryPriceRulesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** The connection type for CustomerDeliveryCountry. */
export type CustomerDeliveryCountryConnection = {
  __typename?: 'CustomerDeliveryCountryConnection';
  /** A list of edges. */
  edges: Array<CustomerDeliveryCountryEdge>;
  /** A list of nodes. */
  nodes: Array<CustomerDeliveryCountry>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalNodes: Scalars['Int'];
};

/** An edge in a connection. */
export type CustomerDeliveryCountryEdge = {
  __typename?: 'CustomerDeliveryCountryEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<CustomerDeliveryCountry>;
};

export type CustomerDeliveryCountryFilter = {
  countryEq?: Maybe<Scalars['String']>;
  countryIn?: Maybe<Array<Scalars['String']>>;
  countryMatches?: Maybe<Scalars['String']>;
  customerDeliveryCountryPriceRulesDeliveryKindEq?: Maybe<Scalars['String']>;
  customerDeliveryCountryPriceRulesDeliveryKindIn?: Maybe<Array<Scalars['String']>>;
  customerDeliveryCountryPriceRulesDeliveryKindMatches?: Maybe<Scalars['String']>;
  customerDeliveryCountryPriceRulesFixedPriceExVatNotNull?: Maybe<Scalars['Boolean']>;
  customerIdEq?: Maybe<Scalars['ID']>;
  customerIdIn?: Maybe<Array<Scalars['ID']>>;
  m?: Maybe<FilterGroupingEnum>;
  sorts?: Maybe<Array<CustomerDeliveryCountrySort>>;
};

export type CustomerDeliveryCountryPriceRule = {
  __typename?: 'CustomerDeliveryCountryPriceRule';
  createdAt: Scalars['ISO8601DateTime'];
  currency: CurrencyEnum;
  customer: Customer;
  customerId: Scalars['ID'];
  deliveryKind: DeliveryKindEnum;
  fixedPriceExVat: Scalars['Float'];
  id: Scalars['ID'];
  maxWeightKg?: Maybe<Scalars['Float']>;
  minWeightKg?: Maybe<Scalars['Float']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

/** The connection type for CustomerDeliveryCountryPriceRule. */
export type CustomerDeliveryCountryPriceRuleConnection = {
  __typename?: 'CustomerDeliveryCountryPriceRuleConnection';
  /** A list of edges. */
  edges: Array<CustomerDeliveryCountryPriceRuleEdge>;
  /** A list of nodes. */
  nodes: Array<CustomerDeliveryCountryPriceRule>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalNodes: Scalars['Int'];
};

/** An edge in a connection. */
export type CustomerDeliveryCountryPriceRuleEdge = {
  __typename?: 'CustomerDeliveryCountryPriceRuleEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<CustomerDeliveryCountryPriceRule>;
};

export type CustomerDeliveryCountrySort = {
  dir: SortDirectionEnum;
  name: CustomerDeliveryCountrySortEnum;
};

export enum CustomerDeliveryCountrySortEnum {
  Country = 'country'
}

/** An edge in a connection. */
export type CustomerEdge = {
  __typename?: 'CustomerEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Customer>;
};

export type CustomerFilter = {
  contactEmailEq?: Maybe<Scalars['String']>;
  contactEmailIn?: Maybe<Array<Scalars['String']>>;
  contactEmailMatches?: Maybe<Scalars['String']>;
  contactPhoneEq?: Maybe<Scalars['String']>;
  contactPhoneIn?: Maybe<Array<Scalars['String']>>;
  contactPhoneMatches?: Maybe<Scalars['String']>;
  countryEq?: Maybe<Scalars['String']>;
  countryIn?: Maybe<Array<Scalars['String']>>;
  countryMatches?: Maybe<Scalars['String']>;
  m?: Maybe<FilterGroupingEnum>;
  nameEq?: Maybe<Scalars['String']>;
  nameIn?: Maybe<Array<Scalars['String']>>;
  nameMatches?: Maybe<Scalars['String']>;
  nameOrContactEmailOrContactPhoneEq?: Maybe<Scalars['String']>;
  nameOrContactEmailOrContactPhoneIn?: Maybe<Array<Scalars['String']>>;
  nameOrContactEmailOrContactPhoneMatches?: Maybe<Scalars['String']>;
  sorts?: Maybe<Array<CustomerSort>>;
};

export type CustomerPricesMutationCountryInput = {
  country: ServiceCountryEnum;
  courierDeliverTime?: Maybe<Scalars['String']>;
  courierPartnerConfigurationId?: Maybe<Scalars['ID']>;
  courierPrice1Kg?: Maybe<Scalars['Float']>;
  courierPrice3Kg?: Maybe<Scalars['Float']>;
  courierPrice5Kg?: Maybe<Scalars['Float']>;
  courierPrice10Kg?: Maybe<Scalars['Float']>;
  courierPrice15Kg?: Maybe<Scalars['Float']>;
  courierPrice20Kg?: Maybe<Scalars['Float']>;
  courierPriceMaxKg?: Maybe<Scalars['Float']>;
  currency: CurrencyEnum;
  deliveryLocationPartnerConfigurationId?: Maybe<Scalars['ID']>;
  insuranceValue?: Maybe<Scalars['Float']>;
  insuranceValueCurrency?: Maybe<CurrencyEnum>;
  price1Kg?: Maybe<Scalars['Float']>;
  price3Kg?: Maybe<Scalars['Float']>;
  price5Kg?: Maybe<Scalars['Float']>;
  price10Kg?: Maybe<Scalars['Float']>;
  price15Kg?: Maybe<Scalars['Float']>;
  price20Kg?: Maybe<Scalars['Float']>;
  priceMaxKg?: Maybe<Scalars['Float']>;
  volumeWeightKg: Scalars['Float'];
};

export type CustomerSort = {
  dir: SortDirectionEnum;
  name: CustomerSortEnum;
};

export enum CustomerSortEnum {
  ContactEmail = 'contact_email',
  ContactPhone = 'contact_phone',
  Country = 'country',
  Name = 'name'
}

export type DefaultDeliveryCountry = {
  __typename?: 'DefaultDeliveryCountry';
  country: ServiceCountryEnum;
  courierDeliverTime?: Maybe<Scalars['String']>;
  courierPartnerConfiguration?: Maybe<PartnerConfiguration>;
  createdAt: Scalars['ISO8601DateTime'];
  deliveryLocationPartnerConfiguration?: Maybe<PartnerConfiguration>;
  id: Scalars['ID'];
  insuranceValue?: Maybe<Scalars['Float']>;
  insuranceValueCurrency?: Maybe<CurrencyEnum>;
  priceRules: DefaultDeliveryCountryPriceRuleConnection;
  updatedAt: Scalars['ISO8601DateTime'];
  volumeWeightKg?: Maybe<Scalars['Float']>;
};


export type DefaultDeliveryCountryPriceRulesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** The connection type for DefaultDeliveryCountry. */
export type DefaultDeliveryCountryConnection = {
  __typename?: 'DefaultDeliveryCountryConnection';
  /** A list of edges. */
  edges: Array<DefaultDeliveryCountryEdge>;
  /** A list of nodes. */
  nodes: Array<DefaultDeliveryCountry>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalNodes: Scalars['Int'];
};

/** An edge in a connection. */
export type DefaultDeliveryCountryEdge = {
  __typename?: 'DefaultDeliveryCountryEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<DefaultDeliveryCountry>;
};

export type DefaultDeliveryCountryFilter = {
  customerCountryEq?: Maybe<Scalars['String']>;
  customerCountryIn?: Maybe<Array<Scalars['String']>>;
  customerCountryMatches?: Maybe<Scalars['String']>;
  m?: Maybe<FilterGroupingEnum>;
  sorts?: Maybe<Array<DefaultDeliveryCountrySort>>;
};

export type DefaultDeliveryCountryPriceRule = {
  __typename?: 'DefaultDeliveryCountryPriceRule';
  createdAt: Scalars['ISO8601DateTime'];
  currency: CurrencyEnum;
  deliveryKind: DeliveryKindEnum;
  fixedPriceExVat: Scalars['Float'];
  id: Scalars['ID'];
  maxWeightKg?: Maybe<Scalars['Float']>;
  minWeightKg?: Maybe<Scalars['Float']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

/** The connection type for DefaultDeliveryCountryPriceRule. */
export type DefaultDeliveryCountryPriceRuleConnection = {
  __typename?: 'DefaultDeliveryCountryPriceRuleConnection';
  /** A list of edges. */
  edges: Array<DefaultDeliveryCountryPriceRuleEdge>;
  /** A list of nodes. */
  nodes: Array<DefaultDeliveryCountryPriceRule>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalNodes: Scalars['Int'];
};

/** An edge in a connection. */
export type DefaultDeliveryCountryPriceRuleEdge = {
  __typename?: 'DefaultDeliveryCountryPriceRuleEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<DefaultDeliveryCountryPriceRule>;
};

export type DefaultDeliveryCountrySort = {
  dir: SortDirectionEnum;
  name: DefaultDeliveryCountrySortEnum;
};

export enum DefaultDeliveryCountrySortEnum {
  Country = 'country',
  CustomerCountry = 'customer_country'
}

export enum DeliveryKindEnum {
  Courier = 'courier',
  DeliveryLocation = 'delivery_location'
}

export type DeliveryLocation = {
  __typename?: 'DeliveryLocation';
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<ServiceCountryEnum>;
  createdAt: Scalars['ISO8601DateTime'];
  directions?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  maxEdgeSumCm?: Maybe<Scalars['Float']>;
  maxHeightCm?: Maybe<Scalars['Float']>;
  maxLengthCm?: Maybe<Scalars['Float']>;
  maxWeightKg?: Maybe<Scalars['Float']>;
  maxWidthCm?: Maybe<Scalars['Float']>;
  minHeightCm?: Maybe<Scalars['Float']>;
  minLengthCm?: Maybe<Scalars['Float']>;
  minWeightKg?: Maybe<Scalars['Float']>;
  minWidthCm?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  recipientPhonePrefix?: Maybe<Scalars['String']>;
  recipientPhoneRegexp?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
  zip?: Maybe<Scalars['String']>;
};

/** The connection type for DeliveryLocation. */
export type DeliveryLocationConnection = {
  __typename?: 'DeliveryLocationConnection';
  /** A list of edges. */
  edges: Array<DeliveryLocationEdge>;
  /** A list of nodes. */
  nodes: Array<DeliveryLocation>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalActive: Scalars['Int'];
  totalNodes: Scalars['Int'];
};

/** An edge in a connection. */
export type DeliveryLocationEdge = {
  __typename?: 'DeliveryLocationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<DeliveryLocation>;
};

export type DeliveryLocationFilter = {
  activeEq?: Maybe<Scalars['Boolean']>;
  activeIn?: Maybe<Array<Scalars['Boolean']>>;
  addressEq?: Maybe<Scalars['String']>;
  addressIn?: Maybe<Array<Scalars['String']>>;
  addressMatches?: Maybe<Scalars['String']>;
  cityEq?: Maybe<Scalars['String']>;
  cityIn?: Maybe<Array<Scalars['String']>>;
  cityMatches?: Maybe<Scalars['String']>;
  countryEq?: Maybe<Scalars['String']>;
  countryIn?: Maybe<Array<Scalars['String']>>;
  countryMatches?: Maybe<Scalars['String']>;
  customerDeliveryCountriesCountryEq?: Maybe<Scalars['String']>;
  customerDeliveryCountriesCountryIn?: Maybe<Array<Scalars['String']>>;
  customerDeliveryCountriesCountryMatches?: Maybe<Scalars['String']>;
  customerDeliveryCountriesCustomerIdEq?: Maybe<Scalars['ID']>;
  customerDeliveryCountriesCustomerIdIn?: Maybe<Array<Scalars['ID']>>;
  m?: Maybe<FilterGroupingEnum>;
  partnerEq?: Maybe<Scalars['String']>;
  partnerIn?: Maybe<Array<Scalars['String']>>;
  partnerMatches?: Maybe<Scalars['String']>;
  sorts?: Maybe<Array<DeliveryLocationSort>>;
  zipEq?: Maybe<Scalars['String']>;
  zipIn?: Maybe<Array<Scalars['String']>>;
  zipMatches?: Maybe<Scalars['String']>;
};

export type DeliveryLocationSort = {
  dir: SortDirectionEnum;
  name: DeliveryLocationSortEnum;
};

export enum DeliveryLocationSortEnum {
  Active = 'active',
  Address = 'address',
  City = 'city',
  Country = 'country',
  Partner = 'partner',
  Zip = 'zip'
}

export type DestroyApiClientFileMutationInput = {
  id: Scalars['ID'];
};

/** Autogenerated return type of DestroyAPIClientFileMutation */
export type DestroyApiClientFileMutationPayload = {
  __typename?: 'DestroyAPIClientFileMutationPayload';
  errors: Array<MutationError>;
  success: Scalars['Boolean'];
};

export type DestroyApiTokenMutationInput = {
  id: Scalars['ID'];
};

/** Autogenerated return type of DestroyAPITokenMutation */
export type DestroyApiTokenMutationPayload = {
  __typename?: 'DestroyAPITokenMutationPayload';
  errors: Array<MutationError>;
  success: Scalars['Boolean'];
};

export type ExternalRequest = {
  __typename?: 'ExternalRequest';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  requestBody?: Maybe<Scalars['String']>;
  requestMethod?: Maybe<Scalars['String']>;
  requestUrl?: Maybe<Scalars['String']>;
  responseBody?: Maybe<Scalars['String']>;
  responseCode?: Maybe<Scalars['String']>;
  service?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export enum FilterGroupingEnum {
  And = 'and',
  Or = 'or'
}

export type HistoryEvent = {
  __typename?: 'HistoryEvent';
  author?: Maybe<Scalars['String']>;
  changes?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['ISO8601DateTime'];
  event: HistoryEventEnum;
  id: Scalars['ID'];
};

export enum HistoryEventEnum {
  Create = 'create',
  Destroy = 'destroy',
  Update = 'update'
}




export enum LocaleEnum {
  Ee = 'ee',
  En = 'en',
  Fi = 'fi',
  Lv = 'lv'
}

export type MarkOrderAsReturnedToPartnerInput = {
  id: Scalars['ID'];
};

/** Autogenerated return type of MarkOrderAsReturnedToPartner */
export type MarkOrderAsReturnedToPartnerPayload = {
  __typename?: 'MarkOrderAsReturnedToPartnerPayload';
  errors: Array<MutationError>;
  order?: Maybe<Order>;
  success: Scalars['Boolean'];
};

export type Mutation = {
  __typename?: 'Mutation';
  cancelOrder: CancelOrderMutationPayload;
  createApiClientFile: CreateApiClientFileMutationPayload;
  createApiToken: CreateApiTokenMutationPayload;
  createCustomer: CreateCustomerMutationPayload;
  createCustomerUser: CreateCustomerUserMutationPayload;
  createGlobalApiToken: CreateGlobalApiTokenMutationPayload;
  createGlobalUser: CreateGlobalUserMutationPayload;
  createOrder: CreateOrderMutationPayload;
  createPickupLocation: CreatePickupLocationMutationPayload;
  createPickupSchedule: CreatePickupScheduleMutationPayload;
  destroyApiClientFile: DestroyApiClientFileMutationPayload;
  destroyApiToken: DestroyApiTokenMutationPayload;
  markAsReturnedToPartner: MarkOrderAsReturnedToPartnerPayload;
  retryUploadOrder: RetryUploadOrderMutationPayload;
  sendOrderTrackingEmail: SendOrderTrackingEmailMutationPayload;
  updateApiClientFile: UpdateApiClientFileMutationPayload;
  updateCurrentUser: UpdateCurrentUserPayload;
  updateCurrentUserViewConfiguration: UpdateCurrentUserViewConfigurationPayload;
  updateCustomer: UpdateCustomerMutationPayload;
  updateCustomerPrices: UpdateCustomerPricesMutationPayload;
  updateCustomerUser: UpdateCustomerUserMutationPayload;
  updateDefaultPrices: UpdateDefaultPricesMutationPayload;
  updateGlobalUser: UpdateGlobalUserMutationPayload;
  updateOrder: UpdateOrderMutationPayload;
  updateOrderReadyState: UpdateOrderReadyStatePayload;
  updatePartnerConfiguration: UpdatePartnerConfigurationMutationPayload;
  updatePickupLocation: UpdatePickupLocationMutationPayload;
  updatePickupLocationActiveState: UpdatePickupLocationActiveStateMutationPayload;
  updatePickupSchedule: UpdatePickupScheduleMutationPayload;
  uploadPartnerDeliveryLocations: UploadPartnerDeliveryLocationsMutationPayload;
};


export type MutationCancelOrderArgs = {
  input: CancelOrderMutationInput;
};


export type MutationCreateApiClientFileArgs = {
  input: CreateApiClientFileMutationInput;
};


export type MutationCreateApiTokenArgs = {
  input: CreateApiTokenMutationInput;
};


export type MutationCreateCustomerArgs = {
  input: CreateCustomerMutationInput;
};


export type MutationCreateCustomerUserArgs = {
  input: CreateCustomerUserMutationInput;
};


export type MutationCreateGlobalUserArgs = {
  input: CreateGlobalUserMutationInput;
};


export type MutationCreateOrderArgs = {
  input: CreateOrderMutationInput;
};


export type MutationCreatePickupLocationArgs = {
  input: CreatePickupLocationMutationInput;
};


export type MutationCreatePickupScheduleArgs = {
  input: CreatePickupScheduleMutationInput;
};


export type MutationDestroyApiClientFileArgs = {
  input: DestroyApiClientFileMutationInput;
};


export type MutationDestroyApiTokenArgs = {
  input: DestroyApiTokenMutationInput;
};


export type MutationMarkAsReturnedToPartnerArgs = {
  input: MarkOrderAsReturnedToPartnerInput;
};


export type MutationRetryUploadOrderArgs = {
  input: RetryUploadOrderMutationInput;
};


export type MutationSendOrderTrackingEmailArgs = {
  input: SendOrderTrackingEmailMutationInput;
};


export type MutationUpdateApiClientFileArgs = {
  input: UpdateApiClientFileMutationInput;
};


export type MutationUpdateCurrentUserArgs = {
  input: UpdateCurrentUserInput;
};


export type MutationUpdateCurrentUserViewConfigurationArgs = {
  input: UpdateCurrentUserViewConfigurationInput;
};


export type MutationUpdateCustomerArgs = {
  input: UpdateCustomerMutationInput;
};


export type MutationUpdateCustomerPricesArgs = {
  input: UpdateCustomerPricesMutationInput;
};


export type MutationUpdateCustomerUserArgs = {
  input: UpdateCustomerUserMutationInput;
};


export type MutationUpdateDefaultPricesArgs = {
  input: UpdateDefaultPricesMutationInput;
};


export type MutationUpdateGlobalUserArgs = {
  input: UpdateGlobalUserMutationInput;
};


export type MutationUpdateOrderArgs = {
  input: UpdateOrderMutationInput;
};


export type MutationUpdateOrderReadyStateArgs = {
  input: UpdateOrderReadyStateInput;
};


export type MutationUpdatePartnerConfigurationArgs = {
  input: UpdatePartnerConfigurationMutationInput;
};


export type MutationUpdatePickupLocationArgs = {
  input: UpdatePickupLocationMutationInput;
};


export type MutationUpdatePickupLocationActiveStateArgs = {
  input: UpdatePickupLocationActiveStateMutationInput;
};


export type MutationUpdatePickupScheduleArgs = {
  input: UpdatePickupScheduleMutationInput;
};


export type MutationUploadPartnerDeliveryLocationsArgs = {
  input: UploadPartnerDeliveryLocationsMutationInput;
};

export type MutationError = {
  __typename?: 'MutationError';
  attribute: Scalars['String'];
  message: Scalars['String'];
  type: Scalars['String'];
};

export type Order = {
  __typename?: 'Order';
  canPrintReturnLabel: Scalars['Boolean'];
  createdAt: Scalars['ISO8601DateTime'];
  customer: Customer;
  customerId: Scalars['ID'];
  deliveryKind: DeliveryKindEnum;
  deliveryLocation?: Maybe<DeliveryLocation>;
  destinationAddress?: Maybe<Scalars['String']>;
  destinationCity?: Maybe<Scalars['String']>;
  destinationCountry?: Maybe<ServiceCountryEnum>;
  destinationZip?: Maybe<Scalars['String']>;
  expectedPickupAt?: Maybe<Scalars['ISO8601DateTime']>;
  externalRequests: Array<ExternalRequest>;
  heightCm?: Maybe<Scalars['Float']>;
  historyEvents: Array<HistoryEvent>;
  id: Scalars['ID'];
  lengthCm?: Maybe<Scalars['Float']>;
  partnerAlternateTrackingNumber?: Maybe<Scalars['String']>;
  partnerTrackingEmailSentAt?: Maybe<Scalars['ISO8601DateTime']>;
  partnerTrackingNumber?: Maybe<Scalars['String']>;
  partnerTrackingUrl?: Maybe<Scalars['String']>;
  pickupLocation?: Maybe<PickupLocation>;
  pickupLocationAddress?: Maybe<Scalars['String']>;
  pickupLocationCity?: Maybe<Scalars['String']>;
  pickupLocationCountry?: Maybe<ServiceCountryEnum>;
  pickupLocationName?: Maybe<Scalars['String']>;
  pickupLocationPhone?: Maybe<Scalars['String']>;
  pickupLocationZip?: Maybe<Scalars['String']>;
  priceExVat?: Maybe<Scalars['Float']>;
  priceExVatCurrency?: Maybe<CurrencyEnum>;
  recipientEmail?: Maybe<Scalars['String']>;
  recipientName?: Maybe<Scalars['String']>;
  recipientPhone?: Maybe<Scalars['String']>;
  recipientPhonePrefix?: Maybe<Scalars['String']>;
  senderComment?: Maybe<Scalars['String']>;
  senderReference?: Maybe<Scalars['String']>;
  shippingLabel?: Maybe<ShippingLabel>;
  status: OrderStatusEnum;
  trackingEmailSentAt?: Maybe<Scalars['ISO8601DateTime']>;
  trackingEvents: Array<TrackingEvent>;
  trackingUrl?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
  weightKg?: Maybe<Scalars['Float']>;
  widthCm?: Maybe<Scalars['Float']>;
};


export type OrderShippingLabelArgs = {
  kind: ShippingLabelKindEnum;
};

/** The connection type for Order. */
export type OrderConnection = {
  __typename?: 'OrderConnection';
  /** A list of edges. */
  edges: Array<OrderEdge>;
  /** A list of nodes. */
  nodes: Array<Order>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  statusReport: OrderStatusReport;
  totalNodes: Scalars['Int'];
};

/** An edge in a connection. */
export type OrderEdge = {
  __typename?: 'OrderEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Order>;
};

export type OrderFilter = {
  barcodeEq?: Maybe<Scalars['String']>;
  barcodeIn?: Maybe<Array<Scalars['String']>>;
  barcodeMatches?: Maybe<Scalars['String']>;
  createdAtGt?: Maybe<Scalars['ISO8601DateTime']>;
  createdAtGteq?: Maybe<Scalars['ISO8601DateTime']>;
  createdAtLt?: Maybe<Scalars['ISO8601DateTime']>;
  createdAtLteq?: Maybe<Scalars['ISO8601DateTime']>;
  createdAtNull?: Maybe<Scalars['Boolean']>;
  customerIdEq?: Maybe<Scalars['ID']>;
  customerIdIn?: Maybe<Array<Scalars['ID']>>;
  destinationCountryEq?: Maybe<Scalars['String']>;
  destinationCountryIn?: Maybe<Array<Scalars['String']>>;
  destinationCountryMatches?: Maybe<Scalars['String']>;
  m?: Maybe<FilterGroupingEnum>;
  partnerAlternateReturnTrackingNumberEq?: Maybe<Scalars['String']>;
  partnerAlternateReturnTrackingNumberIn?: Maybe<Array<Scalars['String']>>;
  partnerAlternateReturnTrackingNumberMatches?: Maybe<Scalars['String']>;
  partnerAlternateTrackingNumberEq?: Maybe<Scalars['String']>;
  partnerAlternateTrackingNumberIn?: Maybe<Array<Scalars['String']>>;
  partnerAlternateTrackingNumberMatches?: Maybe<Scalars['String']>;
  partnerReturnTrackingNumberEq?: Maybe<Scalars['String']>;
  partnerReturnTrackingNumberIn?: Maybe<Array<Scalars['String']>>;
  partnerReturnTrackingNumberMatches?: Maybe<Scalars['String']>;
  partnerTrackingNumberEq?: Maybe<Scalars['String']>;
  partnerTrackingNumberIn?: Maybe<Array<Scalars['String']>>;
  partnerTrackingNumberMatches?: Maybe<Scalars['String']>;
  pickupLocationActiveEq?: Maybe<Scalars['Boolean']>;
  pickupLocationActiveIn?: Maybe<Array<Scalars['Boolean']>>;
  pickupLocationCountryEq?: Maybe<Scalars['String']>;
  pickupLocationCountryIn?: Maybe<Array<Scalars['String']>>;
  pickupLocationCountryMatches?: Maybe<Scalars['String']>;
  pickupLocationCustomerIdEq?: Maybe<Scalars['ID']>;
  pickupLocationCustomerIdIn?: Maybe<Array<Scalars['ID']>>;
  pickupLocationIdEq?: Maybe<Scalars['ID']>;
  pickupLocationIdIn?: Maybe<Array<Scalars['ID']>>;
  pickupLocationNameEq?: Maybe<Scalars['String']>;
  pickupLocationNameIn?: Maybe<Array<Scalars['String']>>;
  pickupLocationNameMatches?: Maybe<Scalars['String']>;
  recipientEmailEq?: Maybe<Scalars['String']>;
  recipientEmailIn?: Maybe<Array<Scalars['String']>>;
  recipientEmailMatches?: Maybe<Scalars['String']>;
  recipientNameEq?: Maybe<Scalars['String']>;
  recipientNameIn?: Maybe<Array<Scalars['String']>>;
  recipientNameMatches?: Maybe<Scalars['String']>;
  returnBarcodeEq?: Maybe<Scalars['String']>;
  returnBarcodeIn?: Maybe<Array<Scalars['String']>>;
  returnBarcodeMatches?: Maybe<Scalars['String']>;
  senderReferenceEq?: Maybe<Scalars['String']>;
  senderReferenceIn?: Maybe<Array<Scalars['String']>>;
  senderReferenceMatches?: Maybe<Scalars['String']>;
  sorts?: Maybe<Array<OrderSort>>;
  statusEq?: Maybe<Scalars['String']>;
  statusIn?: Maybe<Array<Scalars['String']>>;
  statusMatches?: Maybe<Scalars['String']>;
};

export type OrderSort = {
  dir: SortDirectionEnum;
  name: OrderSortEnum;
};

export enum OrderSortEnum {
  CreatedAt = 'created_at'
}

export enum OrderStatusEnum {
  Cancelled = 'cancelled',
  Delivered = 'delivered',
  Error = 'error',
  InDelivery = 'in_delivery',
  InReturn = 'in_return',
  New = 'new',
  Ready = 'ready',
  Returned = 'returned'
}

export type OrderStatusReport = {
  __typename?: 'OrderStatusReport';
  cancelledCount: Scalars['Int'];
  deliveredCount: Scalars['Int'];
  errorCount: Scalars['Int'];
  inDeliveryCount: Scalars['Int'];
  inReturnCount: Scalars['Int'];
  newCount: Scalars['Int'];
  readyCount: Scalars['Int'];
  returnedCount: Scalars['Int'];
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
};

export type PartnerConfiguration = {
  __typename?: 'PartnerConfiguration';
  country: ServiceCountryEnum;
  countryCourierDefault?: Maybe<Scalars['Boolean']>;
  countryDeliveryLocationDefault?: Maybe<Scalars['Boolean']>;
  courierDirections: Scalars['JSON'];
  courierMaxEdgeSumCm?: Maybe<Scalars['Float']>;
  courierMaxHeightCm?: Maybe<Scalars['Float']>;
  courierMaxLengthCm?: Maybe<Scalars['Float']>;
  courierMaxWeightKg?: Maybe<Scalars['Float']>;
  courierMaxWidthCm?: Maybe<Scalars['Float']>;
  courierMinHeightCm?: Maybe<Scalars['Float']>;
  courierMinLengthCm?: Maybe<Scalars['Float']>;
  courierMinWeightKg?: Maybe<Scalars['Float']>;
  courierMinWidthCm?: Maybe<Scalars['Float']>;
  destinationZipRegexp?: Maybe<Scalars['String']>;
  directions: Scalars['JSON'];
  facilityAddress?: Maybe<Scalars['String']>;
  facilityCity?: Maybe<Scalars['String']>;
  facilityCountry?: Maybe<ServiceCountryEnum>;
  facilityZip?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  insuranceValue?: Maybe<Scalars['Float']>;
  insuranceValueCurrency?: Maybe<CurrencyEnum>;
  maxEdgeSumCm?: Maybe<Scalars['Float']>;
  maxHeightCm?: Maybe<Scalars['Float']>;
  maxLengthCm?: Maybe<Scalars['Float']>;
  maxWeightKg?: Maybe<Scalars['Float']>;
  maxWidthCm?: Maybe<Scalars['Float']>;
  minHeightCm?: Maybe<Scalars['Float']>;
  minLengthCm?: Maybe<Scalars['Float']>;
  minWeightKg?: Maybe<Scalars['Float']>;
  minWidthCm?: Maybe<Scalars['Float']>;
  mode: PartnerConfigurationModeEnum;
  name: Scalars['String'];
  partner: Scalars['String'];
  preferAlternateTrackingNumber: Scalars['Boolean'];
  recipientPhonePrefix?: Maybe<Scalars['String']>;
  recipientPhoneRegexp?: Maybe<Scalars['String']>;
  returnAddress?: Maybe<Scalars['String']>;
  returnCity?: Maybe<Scalars['String']>;
  returnCountry?: Maybe<ServiceCountryEnum>;
  returnZip?: Maybe<Scalars['String']>;
  senderPhone?: Maybe<Scalars['String']>;
};

/** The connection type for PartnerConfiguration. */
export type PartnerConfigurationConnection = {
  __typename?: 'PartnerConfigurationConnection';
  /** A list of edges. */
  edges: Array<PartnerConfigurationEdge>;
  /** A list of nodes. */
  nodes: Array<PartnerConfiguration>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalNodes: Scalars['Int'];
};

/** An edge in a connection. */
export type PartnerConfigurationEdge = {
  __typename?: 'PartnerConfigurationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<PartnerConfiguration>;
};

export type PartnerConfigurationFilter = {
  countryEq?: Maybe<Scalars['String']>;
  countryIn?: Maybe<Array<Scalars['String']>>;
  countryMatches?: Maybe<Scalars['String']>;
  m?: Maybe<FilterGroupingEnum>;
  modeEq?: Maybe<Scalars['String']>;
  modeIn?: Maybe<Array<Scalars['String']>>;
  modeMatches?: Maybe<Scalars['String']>;
  sorts?: Maybe<Array<PartnerConfigurationSort>>;
};

export enum PartnerConfigurationModeEnum {
  Active = 'active',
  Disabled = 'disabled',
  Readonly = 'readonly'
}

export type PartnerConfigurationSort = {
  dir: SortDirectionEnum;
  name: PartnerConfigurationSortEnum;
};

export enum PartnerConfigurationSortEnum {
  Country = 'country',
  Id = 'id'
}

export type PickupLocation = {
  __typename?: 'PickupLocation';
  active: Scalars['Boolean'];
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<ServiceCountryEnum>;
  createdAt: Scalars['ISO8601DateTime'];
  customer: Customer;
  customerId: Scalars['ID'];
  defaultHeightCm?: Maybe<Scalars['Float']>;
  defaultLengthCm?: Maybe<Scalars['Float']>;
  defaultWeightKg?: Maybe<Scalars['Float']>;
  defaultWidthCm?: Maybe<Scalars['Float']>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
  zip?: Maybe<Scalars['String']>;
};

/** The connection type for PickupLocation. */
export type PickupLocationConnection = {
  __typename?: 'PickupLocationConnection';
  /** A list of edges. */
  edges: Array<PickupLocationEdge>;
  /** A list of nodes. */
  nodes: Array<PickupLocation>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalNodes: Scalars['Int'];
};

/** An edge in a connection. */
export type PickupLocationEdge = {
  __typename?: 'PickupLocationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<PickupLocation>;
};

export type PickupLocationFilter = {
  activeEq?: Maybe<Scalars['Boolean']>;
  activeIn?: Maybe<Array<Scalars['Boolean']>>;
  countryEq?: Maybe<Scalars['String']>;
  countryIn?: Maybe<Array<Scalars['String']>>;
  countryMatches?: Maybe<Scalars['String']>;
  customerIdEq?: Maybe<Scalars['ID']>;
  customerIdIn?: Maybe<Array<Scalars['ID']>>;
  m?: Maybe<FilterGroupingEnum>;
  nameEq?: Maybe<Scalars['String']>;
  nameIn?: Maybe<Array<Scalars['String']>>;
  nameMatches?: Maybe<Scalars['String']>;
  sorts?: Maybe<Array<PickupLocationSort>>;
};

export type PickupLocationSort = {
  dir: SortDirectionEnum;
  name: PickupLocationSortEnum;
};

export enum PickupLocationSortEnum {
  Active = 'active',
  Country = 'country',
  CustomerId = 'customer_id',
  Name = 'name'
}

export type PickupSchedule = {
  __typename?: 'PickupSchedule';
  createdAt: Scalars['ISO8601DateTime'];
  customer: Customer;
  customerId: Scalars['ID'];
  friday: Scalars['Boolean'];
  id: Scalars['ID'];
  monday: Scalars['Boolean'];
  pickupFrom?: Maybe<Scalars['Iso8601DateTimeWithoutZone']>;
  pickupLocation: PickupLocation;
  pickupTo?: Maybe<Scalars['Iso8601DateTimeWithoutZone']>;
  saturday: Scalars['Boolean'];
  sunday: Scalars['Boolean'];
  thursday: Scalars['Boolean'];
  tuesday: Scalars['Boolean'];
  updatedAt: Scalars['ISO8601DateTime'];
  wednesday: Scalars['Boolean'];
};

/** The connection type for PickupSchedule. */
export type PickupScheduleConnection = {
  __typename?: 'PickupScheduleConnection';
  /** A list of edges. */
  edges: Array<PickupScheduleEdge>;
  /** A list of nodes. */
  nodes: Array<PickupSchedule>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalNodes: Scalars['Int'];
};

/** An edge in a connection. */
export type PickupScheduleEdge = {
  __typename?: 'PickupScheduleEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<PickupSchedule>;
};

export type PickupScheduleFilter = {
  customerIdEq?: Maybe<Scalars['ID']>;
  customerIdIn?: Maybe<Array<Scalars['ID']>>;
  m?: Maybe<FilterGroupingEnum>;
  sorts?: Maybe<Array<PickupScheduleSort>>;
};

export type PickupScheduleSort = {
  dir: SortDirectionEnum;
  name: PickupScheduleSortEnum;
};

export enum PickupScheduleSortEnum {
  CreatedAt = 'created_at'
}

export type Query = {
  __typename?: 'Query';
  courierDeliveryLocationCountries: Array<ServiceCountryEnum>;
  currentAbilities: Array<AbilityRule>;
  currentUser: User;
  customer?: Maybe<Customer>;
  customerApiClientFile?: Maybe<CustomerApiClientFile>;
  customerApiClientFiles: CustomerApiClientFileConnection;
  customerApiToken?: Maybe<CustomerApiToken>;
  customerApiTokens: CustomerApiTokenConnection;
  customerDeliveryCountries: CustomerDeliveryCountryConnection;
  customerDeliveryCountry?: Maybe<CustomerDeliveryCountry>;
  customers: CustomerConnection;
  defaultDeliveryCountries: DefaultDeliveryCountryConnection;
  defaultDeliveryCountry?: Maybe<DefaultDeliveryCountry>;
  deliveryLocation?: Maybe<DeliveryLocation>;
  deliveryLocationCities: Array<Scalars['String']>;
  deliveryLocationCountries: Array<ServiceCountryEnum>;
  deliveryLocations: DeliveryLocationConnection;
  order?: Maybe<Order>;
  orders: OrderConnection;
  partnerConfiguration?: Maybe<PartnerConfiguration>;
  partnerConfigurations: PartnerConfigurationConnection;
  pickupLocation?: Maybe<PickupLocation>;
  pickupLocations: PickupLocationConnection;
  pickupSchedule?: Maybe<PickupSchedule>;
  pickupSchedules: PickupScheduleConnection;
  user?: Maybe<User>;
  users: UserConnection;
};


export type QueryCourierDeliveryLocationCountriesArgs = {
  q?: Maybe<CustomerDeliveryCountryFilter>;
};


export type QueryCustomerArgs = {
  id: Scalars['ID'];
};


export type QueryCustomerApiClientFileArgs = {
  id: Scalars['ID'];
};


export type QueryCustomerApiClientFilesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  q?: Maybe<CustomerApiClientFileFilter>;
};


export type QueryCustomerApiTokenArgs = {
  id: Scalars['ID'];
};


export type QueryCustomerApiTokensArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  q?: Maybe<CustomerApiTokenFilter>;
};


export type QueryCustomerDeliveryCountriesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  q?: Maybe<CustomerDeliveryCountryFilter>;
};


export type QueryCustomerDeliveryCountryArgs = {
  id: Scalars['ID'];
};


export type QueryCustomersArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  q?: Maybe<CustomerFilter>;
};


export type QueryDefaultDeliveryCountriesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  q?: Maybe<DefaultDeliveryCountryFilter>;
};


export type QueryDefaultDeliveryCountryArgs = {
  id: Scalars['ID'];
};


export type QueryDeliveryLocationArgs = {
  id: Scalars['ID'];
};


export type QueryDeliveryLocationCitiesArgs = {
  q: DeliveryLocationFilter;
};


export type QueryDeliveryLocationCountriesArgs = {
  q?: Maybe<DeliveryLocationFilter>;
};


export type QueryDeliveryLocationsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  q?: Maybe<DeliveryLocationFilter>;
};


export type QueryOrderArgs = {
  id: Scalars['ID'];
};


export type QueryOrdersArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  q?: Maybe<OrderFilter>;
};


export type QueryPartnerConfigurationArgs = {
  id: Scalars['ID'];
};


export type QueryPartnerConfigurationsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  q?: Maybe<PartnerConfigurationFilter>;
};


export type QueryPickupLocationArgs = {
  id: Scalars['ID'];
};


export type QueryPickupLocationsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  q?: Maybe<PickupLocationFilter>;
};


export type QueryPickupScheduleArgs = {
  id: Scalars['ID'];
};


export type QueryPickupSchedulesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  q?: Maybe<PickupScheduleFilter>;
};


export type QueryUserArgs = {
  id: Scalars['ID'];
};


export type QueryUsersArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  q?: Maybe<UserFilter>;
};

export type RetryUploadOrderMutationInput = {
  id: Scalars['ID'];
};

/** Autogenerated return type of RetryUploadOrderMutation */
export type RetryUploadOrderMutationPayload = {
  __typename?: 'RetryUploadOrderMutationPayload';
  errors: Array<MutationError>;
  order?: Maybe<Order>;
  success: Scalars['Boolean'];
};

export type Role = {
  __typename?: 'Role';
  active: Scalars['Boolean'];
  customer?: Maybe<Customer>;
  customerId?: Maybe<Scalars['ID']>;
  customerSettings?: Maybe<Scalars['Boolean']>;
  globalSettings?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  invoiceAccess?: Maybe<Scalars['Boolean']>;
  orderManagement?: Maybe<Scalars['Boolean']>;
  primaryCountry?: Maybe<ServiceCountryEnum>;
  scope: RoleScopeEnum;
  userManagement?: Maybe<Scalars['Boolean']>;
};

/** The connection type for Role. */
export type RoleConnection = {
  __typename?: 'RoleConnection';
  /** A list of edges. */
  edges: Array<RoleEdge>;
  /** A list of nodes. */
  nodes: Array<Role>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalNodes: Scalars['Int'];
};

/** An edge in a connection. */
export type RoleEdge = {
  __typename?: 'RoleEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Role>;
};

export type RoleFilter = {
  createdAtGt?: Maybe<Scalars['ISO8601DateTime']>;
  createdAtGteq?: Maybe<Scalars['ISO8601DateTime']>;
  createdAtLt?: Maybe<Scalars['ISO8601DateTime']>;
  createdAtLteq?: Maybe<Scalars['ISO8601DateTime']>;
  createdAtNull?: Maybe<Scalars['Boolean']>;
  customerIdEq?: Maybe<Scalars['ID']>;
  customerIdIn?: Maybe<Array<Scalars['ID']>>;
  deactivatedAtGt?: Maybe<Scalars['ISO8601DateTime']>;
  deactivatedAtGteq?: Maybe<Scalars['ISO8601DateTime']>;
  deactivatedAtLt?: Maybe<Scalars['ISO8601DateTime']>;
  deactivatedAtLteq?: Maybe<Scalars['ISO8601DateTime']>;
  deactivatedAtNull?: Maybe<Scalars['Boolean']>;
  m?: Maybe<FilterGroupingEnum>;
  scopeEq?: Maybe<Scalars['String']>;
  scopeIn?: Maybe<Array<Scalars['String']>>;
  scopeMatches?: Maybe<Scalars['String']>;
  sorts?: Maybe<Array<RoleSort>>;
};

export enum RoleScopeEnum {
  Customer = 'customer',
  Global = 'global'
}

export type RoleSort = {
  dir: SortDirectionEnum;
  name: RoleSortEnum;
};

export enum RoleSortEnum {
  CreatedAt = 'created_at',
  CustomerId = 'customer_id',
  DeactivatedAt = 'deactivated_at',
  Scope = 'scope'
}

export type SendOrderTrackingEmailMutationInput = {
  id: Scalars['ID'];
  partner?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of SendOrderTrackingEmailMutation */
export type SendOrderTrackingEmailMutationPayload = {
  __typename?: 'SendOrderTrackingEmailMutationPayload';
  errors: Array<MutationError>;
  order?: Maybe<Order>;
  success: Scalars['Boolean'];
};

export enum ServiceCountryEnum {
  Ae = 'AE',
  At = 'AT',
  Be = 'BE',
  Bg = 'BG',
  Ch = 'CH',
  Cz = 'CZ',
  De = 'DE',
  Dk = 'DK',
  Ee = 'EE',
  Es = 'ES',
  Fi = 'FI',
  Fr = 'FR',
  Gb = 'GB',
  Gr = 'GR',
  Hr = 'HR',
  Hu = 'HU',
  Ie = 'IE',
  Il = 'IL',
  It = 'IT',
  Lt = 'LT',
  Lu = 'LU',
  Lv = 'LV',
  Nl = 'NL',
  Pl = 'PL',
  Pt = 'PT',
  Ro = 'RO',
  Se = 'SE',
  Si = 'SI',
  Sk = 'SK',
  Tr = 'TR',
  Ua = 'UA',
  Us = 'US'
}

export type ShippingLabel = {
  __typename?: 'ShippingLabel';
  createdAt: Scalars['ISO8601DateTime'];
  customer: Customer;
  customerId: Scalars['ID'];
  fileContent: Scalars['String'];
  fileName: Scalars['String'];
  id: Scalars['ID'];
  kind: ShippingLabelKindEnum;
  order: Order;
  updatedAt: Scalars['ISO8601DateTime'];
};

export enum ShippingLabelKindEnum {
  Delivery = 'delivery',
  Return = 'return'
}

export enum SortDirectionEnum {
  Asc = 'asc',
  Desc = 'desc'
}


export enum TrackingAliasedEventEnum {
  Cancelled = 'cancelled',
  Created = 'created',
  CustomerReturned = 'customer_returned',
  Delivered = 'delivered',
  DeliveredToPartner = 'delivered_to_partner',
  HrxPickedUp = 'hrx_picked_up',
  HrxTerminalIn = 'hrx_terminal_in',
  HrxTerminalOut = 'hrx_terminal_out',
  Moved = 'moved',
  NotReady = 'not_ready',
  Notified = 'notified',
  Other = 'other',
  Ready = 'ready',
  Returned = 'returned',
  ScannedByPartner = 'scanned_by_partner',
  Writeoff = 'writeoff'
}

export type TrackingEvent = {
  __typename?: 'TrackingEvent';
  additionalData?: Maybe<Scalars['JSON']>;
  aliasedEvent: TrackingAliasedEventEnum;
  createdAt: Scalars['ISO8601DateTime'];
  customer: Customer;
  event: TrackingEventEnum;
  id: Scalars['ID'];
  location?: Maybe<Scalars['String']>;
  order: Order;
  timestamp: Scalars['ISO8601DateTime'];
};

export enum TrackingEventEnum {
  Cancelled = 'cancelled',
  Created = 'created',
  CustomerReturned = 'customer_returned',
  Delivered = 'delivered',
  Moved = 'moved',
  NotReady = 'not_ready',
  Notified = 'notified',
  Other = 'other',
  Ready = 'ready',
  Returned = 'returned',
  Writeoff = 'writeoff'
}

export type UpdateApiClientFileMutationInput = {
  fileName: Scalars['StrippedString'];
  id: Scalars['ID'];
  platformName: Scalars['StrippedString'];
};

/** Autogenerated return type of UpdateAPIClientFileMutation */
export type UpdateApiClientFileMutationPayload = {
  __typename?: 'UpdateAPIClientFileMutationPayload';
  apiClientFile?: Maybe<CustomerApiClientFile>;
  errors: Array<MutationError>;
  success: Scalars['Boolean'];
};

export type UpdateCurrentUserInput = {
  currentPassword?: Maybe<Scalars['String']>;
  locale?: Maybe<LocaleEnum>;
  name?: Maybe<Scalars['StrippedString']>;
  password?: Maybe<Scalars['String']>;
  passwordConfirmation?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['StrippedString']>;
};

/** Autogenerated return type of UpdateCurrentUser */
export type UpdateCurrentUserPayload = {
  __typename?: 'UpdateCurrentUserPayload';
  errors: Array<MutationError>;
  success: Scalars['Boolean'];
  user?: Maybe<User>;
};

export type UpdateCurrentUserViewConfigurationInput = {
  fields: Scalars['JSON'];
  key: ViewConfigurationKeyEnum;
};

/** Autogenerated return type of UpdateCurrentUserViewConfiguration */
export type UpdateCurrentUserViewConfigurationPayload = {
  __typename?: 'UpdateCurrentUserViewConfigurationPayload';
  errors: Array<MutationError>;
  success: Scalars['Boolean'];
  viewConfiguration?: Maybe<ViewConfiguration>;
};

export type UpdateCustomerMutationInput = {
  clienteleKind?: Maybe<ClienteleKindEnum>;
  contactEmail?: Maybe<Scalars['StrippedString']>;
  contactName?: Maybe<Scalars['StrippedString']>;
  contactPhone?: Maybe<Scalars['StrippedString']>;
  country?: Maybe<Scalars['StrippedString']>;
  freeformPickupAddressAllowed?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  invoiceEmail?: Maybe<Scalars['StrippedString']>;
  logId: Scalars['StrippedString'];
  name: Scalars['StrippedString'];
  supportEmail?: Maybe<Scalars['StrippedString']>;
  supportName?: Maybe<Scalars['StrippedString']>;
  supportPhone?: Maybe<Scalars['StrippedString']>;
};

/** Autogenerated return type of UpdateCustomerMutation */
export type UpdateCustomerMutationPayload = {
  __typename?: 'UpdateCustomerMutationPayload';
  customer?: Maybe<Customer>;
  errors: Array<MutationError>;
  success: Scalars['Boolean'];
};

export type UpdateCustomerPricesMutationInput = {
  countries: Array<CustomerPricesMutationCountryInput>;
  id: Scalars['ID'];
};

/** Autogenerated return type of UpdateCustomerPricesMutation */
export type UpdateCustomerPricesMutationPayload = {
  __typename?: 'UpdateCustomerPricesMutationPayload';
  customer?: Maybe<Customer>;
  errors: Array<MutationError>;
  success: Scalars['Boolean'];
};

export type UpdateCustomerUserMutationInput = {
  active: Scalars['Boolean'];
  customerId: Scalars['ID'];
  customerSettings: Scalars['Boolean'];
  email: Scalars['StrippedString'];
  id: Scalars['ID'];
  invoiceAccess: Scalars['Boolean'];
  name?: Maybe<Scalars['StrippedString']>;
  orderManagement: Scalars['Boolean'];
  password?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['StrippedString']>;
  userManagement: Scalars['Boolean'];
};

/** Autogenerated return type of UpdateCustomerUserMutation */
export type UpdateCustomerUserMutationPayload = {
  __typename?: 'UpdateCustomerUserMutationPayload';
  errors: Array<MutationError>;
  success: Scalars['Boolean'];
  user?: Maybe<User>;
};

export type UpdateDefaultPricesMutationInput = {
  countries: Array<CustomerPricesMutationCountryInput>;
  customerCountry: ServiceCountryEnum;
};

/** Autogenerated return type of UpdateDefaultPricesMutation */
export type UpdateDefaultPricesMutationPayload = {
  __typename?: 'UpdateDefaultPricesMutationPayload';
  errors: Array<MutationError>;
  success: Scalars['Boolean'];
};

export type UpdateGlobalUserMutationInput = {
  active: Scalars['Boolean'];
  email: Scalars['StrippedString'];
  globalSettings: Scalars['Boolean'];
  id: Scalars['ID'];
  name?: Maybe<Scalars['StrippedString']>;
  password?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['StrippedString']>;
  primaryCountry?: Maybe<Scalars['StrippedString']>;
};

/** Autogenerated return type of UpdateGlobalUserMutation */
export type UpdateGlobalUserMutationPayload = {
  __typename?: 'UpdateGlobalUserMutationPayload';
  errors: Array<MutationError>;
  success: Scalars['Boolean'];
  user?: Maybe<User>;
};

export type UpdateOrderMutationInput = {
  deliveryKind: DeliveryKindEnum;
  deliveryLocationId?: Maybe<Scalars['ID']>;
  destinationAddress?: Maybe<Scalars['StrippedString']>;
  destinationCity?: Maybe<Scalars['StrippedString']>;
  destinationCountry?: Maybe<ServiceCountryEnum>;
  destinationZip?: Maybe<Scalars['StrippedString']>;
  heightCm: Scalars['Float'];
  id: Scalars['ID'];
  lengthCm: Scalars['Float'];
  pickupLocationId: Scalars['ID'];
  recipientEmail: Scalars['StrippedString'];
  recipientName: Scalars['StrippedString'];
  recipientPhone: Scalars['StrippedString'];
  senderComment?: Maybe<Scalars['StrippedString']>;
  senderReference?: Maybe<Scalars['StrippedString']>;
  weightKg: Scalars['Float'];
  widthCm: Scalars['Float'];
};

/** Autogenerated return type of UpdateOrderMutation */
export type UpdateOrderMutationPayload = {
  __typename?: 'UpdateOrderMutationPayload';
  errors: Array<MutationError>;
  order?: Maybe<Order>;
  success: Scalars['Boolean'];
};

export type UpdateOrderReadyStateInput = {
  orderIds: Array<Scalars['ID']>;
  ready: Scalars['Boolean'];
};

/** Autogenerated return type of UpdateOrderReadyState */
export type UpdateOrderReadyStatePayload = {
  __typename?: 'UpdateOrderReadyStatePayload';
  errors: Array<MutationError>;
  orders?: Maybe<Array<Order>>;
  success: Scalars['Boolean'];
};

export type UpdatePartnerConfigurationMutationInput = {
  countryCourierDefault?: Maybe<Scalars['Boolean']>;
  countryDeliveryLocationDefault?: Maybe<Scalars['Boolean']>;
  courierDirections?: Maybe<Scalars['JSON']>;
  courierMaxEdgeSumCm?: Maybe<Scalars['Float']>;
  courierMaxHeightCm?: Maybe<Scalars['Float']>;
  courierMaxLengthCm?: Maybe<Scalars['Float']>;
  courierMaxWeightKg?: Maybe<Scalars['Float']>;
  courierMaxWidthCm?: Maybe<Scalars['Float']>;
  courierMinHeightCm?: Maybe<Scalars['Float']>;
  courierMinLengthCm?: Maybe<Scalars['Float']>;
  courierMinWeightKg?: Maybe<Scalars['Float']>;
  courierMinWidthCm?: Maybe<Scalars['Float']>;
  destinationZipRegexp?: Maybe<Scalars['StrippedString']>;
  directions?: Maybe<Scalars['JSON']>;
  facilityAddress?: Maybe<Scalars['StrippedString']>;
  facilityCity?: Maybe<Scalars['StrippedString']>;
  facilityCountry?: Maybe<ServiceCountryEnum>;
  facilityZip?: Maybe<Scalars['StrippedString']>;
  id: Scalars['ID'];
  insuranceValue?: Maybe<Scalars['Float']>;
  insuranceValueCurrency?: Maybe<CurrencyEnum>;
  maxEdgeSumCm?: Maybe<Scalars['Float']>;
  maxHeightCm?: Maybe<Scalars['Float']>;
  maxLengthCm?: Maybe<Scalars['Float']>;
  maxWeightKg?: Maybe<Scalars['Float']>;
  maxWidthCm?: Maybe<Scalars['Float']>;
  minHeightCm?: Maybe<Scalars['Float']>;
  minLengthCm?: Maybe<Scalars['Float']>;
  minWeightKg?: Maybe<Scalars['Float']>;
  minWidthCm?: Maybe<Scalars['Float']>;
  mode?: Maybe<PartnerConfigurationModeEnum>;
  preferAlternateTrackingNumber?: Maybe<Scalars['Boolean']>;
  recipientPhonePrefix?: Maybe<Scalars['StrippedString']>;
  recipientPhoneRegexp?: Maybe<Scalars['StrippedString']>;
  returnAddress?: Maybe<Scalars['StrippedString']>;
  returnCity?: Maybe<Scalars['StrippedString']>;
  returnCountry?: Maybe<ServiceCountryEnum>;
  returnZip?: Maybe<Scalars['StrippedString']>;
  senderPhone?: Maybe<Scalars['StrippedString']>;
};

/** Autogenerated return type of UpdatePartnerConfigurationMutation */
export type UpdatePartnerConfigurationMutationPayload = {
  __typename?: 'UpdatePartnerConfigurationMutationPayload';
  errors: Array<MutationError>;
  partnerConfiguration?: Maybe<PartnerConfiguration>;
  success: Scalars['Boolean'];
};

export type UpdatePickupLocationActiveStateMutationInput = {
  active: Scalars['Boolean'];
  id: Scalars['ID'];
};

/** Autogenerated return type of UpdatePickupLocationActiveStateMutation */
export type UpdatePickupLocationActiveStateMutationPayload = {
  __typename?: 'UpdatePickupLocationActiveStateMutationPayload';
  errors: Array<MutationError>;
  pickupLocation?: Maybe<PickupLocation>;
  success: Scalars['Boolean'];
};

export type UpdatePickupLocationMutationInput = {
  active: Scalars['Boolean'];
  address: Scalars['StrippedString'];
  city: Scalars['StrippedString'];
  country: Scalars['StrippedString'];
  defaultHeightCm?: Maybe<Scalars['Float']>;
  defaultLengthCm?: Maybe<Scalars['Float']>;
  defaultWeightKg?: Maybe<Scalars['Float']>;
  defaultWidthCm?: Maybe<Scalars['Float']>;
  email: Scalars['StrippedString'];
  id: Scalars['ID'];
  name: Scalars['StrippedString'];
  phone: Scalars['StrippedString'];
  zip: Scalars['StrippedString'];
};

/** Autogenerated return type of UpdatePickupLocationMutation */
export type UpdatePickupLocationMutationPayload = {
  __typename?: 'UpdatePickupLocationMutationPayload';
  errors: Array<MutationError>;
  pickupLocation?: Maybe<Order>;
  success: Scalars['Boolean'];
};

export type UpdatePickupScheduleMutationInput = {
  friday?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  monday?: Maybe<Scalars['Boolean']>;
  pickupFrom: Scalars['Iso8601DateTimeWithoutZone'];
  pickupLocationId: Scalars['ID'];
  pickupTo: Scalars['Iso8601DateTimeWithoutZone'];
  saturday?: Maybe<Scalars['Boolean']>;
  sunday?: Maybe<Scalars['Boolean']>;
  thursday?: Maybe<Scalars['Boolean']>;
  tuesday?: Maybe<Scalars['Boolean']>;
  wednesday?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of UpdatePickupScheduleMutation */
export type UpdatePickupScheduleMutationPayload = {
  __typename?: 'UpdatePickupScheduleMutationPayload';
  errors: Array<MutationError>;
  pickupSchedule?: Maybe<PickupSchedule>;
  success: Scalars['Boolean'];
};

export type UploadPartnerDeliveryLocationsMutationInput = {
  country: ServiceCountryEnum;
  file: Scalars['String'];
  partner: Scalars['String'];
};

/** Autogenerated return type of UploadPartnerDeliveryLocationsMutation */
export type UploadPartnerDeliveryLocationsMutationPayload = {
  __typename?: 'UploadPartnerDeliveryLocationsMutationPayload';
  errors: Array<MutationError>;
  success: Scalars['Boolean'];
};

export type User = {
  __typename?: 'User';
  createdAt: Scalars['ISO8601DateTime'];
  email: Scalars['String'];
  firstLogin: Scalars['Boolean'];
  id: Scalars['ID'];
  locale?: Maybe<LocaleEnum>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  role?: Maybe<Role>;
  roles: RoleConnection;
  updatedAt: Scalars['ISO8601DateTime'];
  viewConfigurations: Array<ViewConfiguration>;
};


export type UserRoleArgs = {
  customerId?: Maybe<Scalars['ID']>;
};


export type UserRolesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  q?: Maybe<RoleFilter>;
};

/** The connection type for User. */
export type UserConnection = {
  __typename?: 'UserConnection';
  /** A list of edges. */
  edges: Array<UserEdge>;
  /** A list of nodes. */
  nodes: Array<User>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalNodes: Scalars['Int'];
};

/** An edge in a connection. */
export type UserEdge = {
  __typename?: 'UserEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<User>;
};

export type UserFilter = {
  createdAtGt?: Maybe<Scalars['ISO8601DateTime']>;
  createdAtGteq?: Maybe<Scalars['ISO8601DateTime']>;
  createdAtLt?: Maybe<Scalars['ISO8601DateTime']>;
  createdAtLteq?: Maybe<Scalars['ISO8601DateTime']>;
  createdAtNull?: Maybe<Scalars['Boolean']>;
  emailEq?: Maybe<Scalars['String']>;
  emailIn?: Maybe<Array<Scalars['String']>>;
  emailMatches?: Maybe<Scalars['String']>;
  m?: Maybe<FilterGroupingEnum>;
  rolesCreatedAtGt?: Maybe<Scalars['ISO8601DateTime']>;
  rolesCreatedAtGteq?: Maybe<Scalars['ISO8601DateTime']>;
  rolesCreatedAtLt?: Maybe<Scalars['ISO8601DateTime']>;
  rolesCreatedAtLteq?: Maybe<Scalars['ISO8601DateTime']>;
  rolesCreatedAtNull?: Maybe<Scalars['Boolean']>;
  rolesCustomerIdEq?: Maybe<Scalars['ID']>;
  rolesCustomerIdIn?: Maybe<Array<Scalars['ID']>>;
  rolesDeactivatedAtGt?: Maybe<Scalars['ISO8601DateTime']>;
  rolesDeactivatedAtGteq?: Maybe<Scalars['ISO8601DateTime']>;
  rolesDeactivatedAtLt?: Maybe<Scalars['ISO8601DateTime']>;
  rolesDeactivatedAtLteq?: Maybe<Scalars['ISO8601DateTime']>;
  rolesDeactivatedAtNull?: Maybe<Scalars['Boolean']>;
  rolesScopeEq?: Maybe<Scalars['String']>;
  rolesScopeIn?: Maybe<Array<Scalars['String']>>;
  rolesScopeMatches?: Maybe<Scalars['String']>;
  sorts?: Maybe<Array<UserSort>>;
};

export type UserSort = {
  dir: SortDirectionEnum;
  name: UserSortEnum;
};

export enum UserSortEnum {
  CreatedAt = 'created_at',
  Email = 'email'
}

export type ViewConfiguration = {
  __typename?: 'ViewConfiguration';
  createdAt: Scalars['ISO8601DateTime'];
  fields: Scalars['JSON'];
  id: Scalars['ID'];
  key: ViewConfigurationKeyEnum;
  updatedAt: Scalars['ISO8601DateTime'];
};

export enum ViewConfigurationKeyEnum {
  Customers = 'customers',
  Orders = 'orders',
  PickupLocations = 'pickup_locations',
  PickupSchedules = 'pickup_schedules',
  Users = 'users',
  UsersGlobal = 'users_global'
}

export type CreateApiTokenMutationVariables = Exact<{
  input: CreateApiTokenMutationInput;
}>;


export type CreateApiTokenMutation = { __typename?: 'Mutation', createApiToken: { __typename?: 'CreateAPITokenMutationPayload', success: boolean, apiToken: { __typename?: 'CustomerAPIToken', id: string, name: string, token?: Maybe<string> } } };

export type CreateGlobalApiTokenMutationVariables = Exact<{ [key: string]: never; }>;


export type CreateGlobalApiTokenMutation = { __typename?: 'Mutation', createGlobalApiToken: { __typename?: 'CreateGlobalAPITokenMutationPayload', success: boolean, apiToken: { __typename?: 'CustomerAPIToken', id: string, name: string, token?: Maybe<string> } } };

export type DestroyApiTokenMutationVariables = Exact<{
  input: DestroyApiTokenMutationInput;
}>;


export type DestroyApiTokenMutation = { __typename?: 'Mutation', destroyApiToken: { __typename?: 'DestroyAPITokenMutationPayload', success: boolean } };

export type PartnerDeliveryLocationStatsQueryVariables = Exact<{
  q?: Maybe<DeliveryLocationFilter>;
}>;


export type PartnerDeliveryLocationStatsQuery = { __typename?: 'Query', deliveryLocations: { __typename?: 'DeliveryLocationConnection', totalNodes: number, totalActive: number } };

export type UploadPartnerDeliveryLocationStatsMutationVariables = Exact<{
  input: UploadPartnerDeliveryLocationsMutationInput;
}>;


export type UploadPartnerDeliveryLocationStatsMutation = { __typename?: 'Mutation', uploadPartnerDeliveryLocations: { __typename?: 'UploadPartnerDeliveryLocationsMutationPayload', success: boolean, errors: Array<{ __typename?: 'MutationError', type: string, message: string, attribute: string }> } };

export type OrdersAutocompleteQueryVariables = Exact<{
  q?: Maybe<OrderFilter>;
  first?: Maybe<Scalars['Int']>;
}>;


export type OrdersAutocompleteQuery = { __typename?: 'Query', orders: { __typename?: 'OrderConnection', nodes: Array<{ __typename?: 'Order', id: string, customerId: string, senderReference?: Maybe<string>, partnerTrackingNumber?: Maybe<string> }> } };

export type PickupLocationSelectorOptionsQueryVariables = Exact<{
  q?: Maybe<PickupLocationFilter>;
}>;


export type PickupLocationSelectorOptionsQuery = { __typename?: 'Query', pickupLocations: { __typename?: 'PickupLocationConnection', nodes: Array<{ __typename?: 'PickupLocation', id: string, customerId: string, name: string }> } };

export type OrderShippingLabelQueryVariables = Exact<{
  orderId: Scalars['ID'];
  kind: ShippingLabelKindEnum;
}>;


export type OrderShippingLabelQuery = { __typename?: 'Query', order?: Maybe<{ __typename?: 'Order', id: string, canPrintReturnLabel: boolean, shippingLabel?: Maybe<{ __typename?: 'ShippingLabel', id: string, fileName: string, fileContent: string }> }> };

export type OrderTrackingStatusQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type OrderTrackingStatusQuery = { __typename?: 'Query', order?: Maybe<{ __typename?: 'Order', id: string, status: OrderStatusEnum, trackingUrl?: Maybe<string>, partnerTrackingNumber?: Maybe<string>, partnerTrackingUrl?: Maybe<string> }> };

export type RetryUploadOderMutationVariables = Exact<{
  input: RetryUploadOrderMutationInput;
}>;


export type RetryUploadOderMutation = { __typename?: 'Mutation', retryUploadOrder: { __typename?: 'RetryUploadOrderMutationPayload', success: boolean } };

export type MarkOrderAsReturnedToPartnerMutationVariables = Exact<{
  input: MarkOrderAsReturnedToPartnerInput;
}>;


export type MarkOrderAsReturnedToPartnerMutation = { __typename?: 'Mutation', markAsReturnedToPartner: { __typename?: 'MarkOrderAsReturnedToPartnerPayload', success: boolean } };

export type OrderStatusesQueryVariables = Exact<{
  q?: Maybe<OrderFilter>;
}>;


export type OrderStatusesQuery = { __typename?: 'Query', orders: { __typename?: 'OrderConnection', statusReport: { __typename?: 'OrderStatusReport', newCount: number, readyCount: number, inDeliveryCount: number, deliveredCount: number, cancelledCount: number, inReturnCount: number, returnedCount: number, errorCount: number } } };

export type UpdateViewConfigurationMutationVariables = Exact<{
  input: UpdateCurrentUserViewConfigurationInput;
}>;


export type UpdateViewConfigurationMutation = { __typename?: 'Mutation', updateCurrentUserViewConfiguration: { __typename?: 'UpdateCurrentUserViewConfigurationPayload', success: boolean, viewConfiguration?: Maybe<{ __typename?: 'ViewConfiguration', id: string, key: ViewConfigurationKeyEnum, fields: any }> } };

export type CurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentUserQuery = { __typename?: 'Query', currentUser: { __typename?: 'User', id: string, locale?: Maybe<LocaleEnum>, email: string, name?: Maybe<string>, phone?: Maybe<string>, firstLogin: boolean, viewConfigurations: Array<{ __typename?: 'ViewConfiguration', id: string, key: ViewConfigurationKeyEnum, fields: any }>, roles: { __typename?: 'RoleConnection', nodes: Array<{ __typename?: 'Role', id: string, scope: RoleScopeEnum, customerId?: Maybe<string>, active: boolean, primaryCountry?: Maybe<ServiceCountryEnum>, customer?: Maybe<{ __typename?: 'Customer', id: string, name: string, country: ServiceCountryEnum }> }> } }, currentAbilities: Array<{ __typename?: 'AbilityRule', action: Array<string>, subject: Array<string>, fields?: Maybe<Array<string>>, conditions?: Maybe<any>, inverted: boolean }> };

export type DeliveryCountryOptionsQueryVariables = Exact<{
  customerId?: Maybe<Scalars['ID']>;
}>;


export type DeliveryCountryOptionsQuery = { __typename?: 'Query', deliveryLocationCountries: Array<ServiceCountryEnum> };

export type UpdateCurrentUserLanguageMutationVariables = Exact<{
  input: UpdateCurrentUserInput;
}>;


export type UpdateCurrentUserLanguageMutation = { __typename?: 'Mutation', updateCurrentUser: { __typename?: 'UpdateCurrentUserPayload', success: boolean, user?: Maybe<{ __typename?: 'User', id: string, locale?: Maybe<LocaleEnum> }> } };

export type ApiClientFileEditQueryVariables = Exact<{
  apiClientFileId: Scalars['ID'];
}>;


export type ApiClientFileEditQuery = { __typename?: 'Query', customerApiClientFile?: Maybe<{ __typename?: 'CustomerAPIClientFile', id: string, platformName: string, fileName: string, fileUrl?: Maybe<string> }> };

export type UpdateApiClientFileMutationVariables = Exact<{
  input: UpdateApiClientFileMutationInput;
}>;


export type UpdateApiClientFileMutation = { __typename?: 'Mutation', updateApiClientFile: { __typename?: 'UpdateAPIClientFileMutationPayload', success: boolean, errors: Array<{ __typename?: 'MutationError', type: string, message: string, attribute: string }> } };

export type DestroyApiClientFileMutationVariables = Exact<{
  input: DestroyApiClientFileMutationInput;
}>;


export type DestroyApiClientFileMutation = { __typename?: 'Mutation', destroyApiClientFile: { __typename?: 'DestroyAPIClientFileMutationPayload', success: boolean } };

export type ApiClientFilesListQueryVariables = Exact<{
  q?: Maybe<CustomerApiClientFileFilter>;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
}>;


export type ApiClientFilesListQuery = { __typename?: 'Query', customerApiClientFiles: { __typename?: 'CustomerAPIClientFileConnection', totalNodes: number, nodes: Array<{ __typename?: 'CustomerAPIClientFile', id: string, platformName: string, fileName: string, createdAt: string }> } };

export type CreateApiClientFileMutationVariables = Exact<{
  input: CreateApiClientFileMutationInput;
}>;


export type CreateApiClientFileMutation = { __typename?: 'Mutation', createApiClientFile: { __typename?: 'CreateAPIClientFileMutationPayload', success: boolean, errors: Array<{ __typename?: 'MutationError', type: string, message: string, attribute: string }> } };

export type CustomerEditQueryVariables = Exact<{
  customerId: Scalars['ID'];
}>;


export type CustomerEditQuery = { __typename?: 'Query', customer?: Maybe<{ __typename?: 'Customer', id: string, name: string, country: ServiceCountryEnum, invoiceEmail?: Maybe<string>, contactName?: Maybe<string>, contactEmail?: Maybe<string>, contactPhone?: Maybe<string>, supportName?: Maybe<string>, supportEmail?: Maybe<string>, supportPhone?: Maybe<string>, logId?: Maybe<string>, freeformPickupAddressAllowed: boolean, clienteleKind: ClienteleKindEnum }> };

export type UpdateCustomerMutationVariables = Exact<{
  input: UpdateCustomerMutationInput;
}>;


export type UpdateCustomerMutation = { __typename?: 'Mutation', updateCustomer: { __typename?: 'UpdateCustomerMutationPayload', success: boolean, errors: Array<{ __typename?: 'MutationError', type: string, message: string, attribute: string }> } };

export type CustomersListQueryVariables = Exact<{
  q?: Maybe<CustomerFilter>;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
}>;


export type CustomersListQuery = { __typename?: 'Query', customers: { __typename?: 'CustomerConnection', totalNodes: number, nodes: Array<{ __typename?: 'Customer', id: string, name: string, country: ServiceCountryEnum }> } };

export type CreateCustomerMutationVariables = Exact<{
  input: CreateCustomerMutationInput;
}>;


export type CreateCustomerMutation = { __typename?: 'Mutation', createCustomer: { __typename?: 'CreateCustomerMutationPayload', success: boolean, customer?: Maybe<{ __typename?: 'Customer', id: string }> } };

export type CustomerPricesQueryVariables = Exact<{
  customerId: Scalars['ID'];
}>;


export type CustomerPricesQuery = { __typename?: 'Query', customer?: Maybe<{ __typename?: 'Customer', id: string, name: string, deliveryCountries: { __typename?: 'CustomerDeliveryCountryConnection', nodes: Array<{ __typename?: 'CustomerDeliveryCountry', id: string, customerId: string, country: ServiceCountryEnum, volumeWeightKg?: Maybe<number>, insuranceValue?: Maybe<number>, courierDeliverTime?: Maybe<string>, courierPartnerConfiguration?: Maybe<{ __typename?: 'PartnerConfiguration', id: string, name: string, mode: PartnerConfigurationModeEnum }>, deliveryLocationPartnerConfiguration?: Maybe<{ __typename?: 'PartnerConfiguration', id: string, name: string, mode: PartnerConfigurationModeEnum }>, priceRules: { __typename?: 'CustomerDeliveryCountryPriceRuleConnection', nodes: Array<{ __typename?: 'CustomerDeliveryCountryPriceRule', id: string, customerId: string, fixedPriceExVat: number, minWeightKg?: Maybe<number>, maxWeightKg?: Maybe<number>, deliveryKind: DeliveryKindEnum, currency: CurrencyEnum }> } }> }, defaultDeliveryCountries: { __typename?: 'DefaultDeliveryCountryConnection', nodes: Array<{ __typename?: 'DefaultDeliveryCountry', id: string, country: ServiceCountryEnum, volumeWeightKg?: Maybe<number>, insuranceValue?: Maybe<number>, courierDeliverTime?: Maybe<string>, courierPartnerConfiguration?: Maybe<{ __typename?: 'PartnerConfiguration', id: string, name: string, mode: PartnerConfigurationModeEnum }>, deliveryLocationPartnerConfiguration?: Maybe<{ __typename?: 'PartnerConfiguration', id: string, name: string, mode: PartnerConfigurationModeEnum }>, priceRules: { __typename?: 'DefaultDeliveryCountryPriceRuleConnection', nodes: Array<{ __typename?: 'DefaultDeliveryCountryPriceRule', id: string, fixedPriceExVat: number, minWeightKg?: Maybe<number>, maxWeightKg?: Maybe<number>, deliveryKind: DeliveryKindEnum, currency: CurrencyEnum }> } }> } }> };

export type UpdateCustomerPricesMutationVariables = Exact<{
  input: UpdateCustomerPricesMutationInput;
}>;


export type UpdateCustomerPricesMutation = { __typename?: 'Mutation', updateCustomerPrices: { __typename?: 'UpdateCustomerPricesMutationPayload', success: boolean, errors: Array<{ __typename?: 'MutationError', type: string, message: string, attribute: string }> } };

export type PartnerEditQueryVariables = Exact<{
  partnerId: Scalars['ID'];
}>;


export type PartnerEditQuery = { __typename?: 'Query', partnerConfiguration?: Maybe<{ __typename?: 'PartnerConfiguration', id: string, mode: PartnerConfigurationModeEnum, name: string, country: ServiceCountryEnum, partner: string, minHeightCm?: Maybe<number>, minLengthCm?: Maybe<number>, minWidthCm?: Maybe<number>, minWeightKg?: Maybe<number>, maxHeightCm?: Maybe<number>, maxLengthCm?: Maybe<number>, maxWidthCm?: Maybe<number>, maxWeightKg?: Maybe<number>, maxEdgeSumCm?: Maybe<number>, courierMinHeightCm?: Maybe<number>, courierMinLengthCm?: Maybe<number>, courierMinWidthCm?: Maybe<number>, courierMinWeightKg?: Maybe<number>, courierMaxHeightCm?: Maybe<number>, courierMaxLengthCm?: Maybe<number>, courierMaxWidthCm?: Maybe<number>, courierMaxWeightKg?: Maybe<number>, courierMaxEdgeSumCm?: Maybe<number>, facilityAddress?: Maybe<string>, facilityCity?: Maybe<string>, facilityZip?: Maybe<string>, facilityCountry?: Maybe<ServiceCountryEnum>, returnAddress?: Maybe<string>, returnCity?: Maybe<string>, returnZip?: Maybe<string>, returnCountry?: Maybe<ServiceCountryEnum>, senderPhone?: Maybe<string>, recipientPhonePrefix?: Maybe<string>, recipientPhoneRegexp?: Maybe<string>, destinationZipRegexp?: Maybe<string>, insuranceValue?: Maybe<number>, countryCourierDefault?: Maybe<boolean>, countryDeliveryLocationDefault?: Maybe<boolean>, directions: any, courierDirections: any, preferAlternateTrackingNumber: boolean }> };

export type UpdatePartnerMutationVariables = Exact<{
  input: UpdatePartnerConfigurationMutationInput;
}>;


export type UpdatePartnerMutation = { __typename?: 'Mutation', updatePartnerConfiguration: { __typename?: 'UpdatePartnerConfigurationMutationPayload', success: boolean, errors: Array<{ __typename?: 'MutationError', type: string, message: string, attribute: string }> } };

export type PartnersListQueryVariables = Exact<{
  q?: Maybe<PartnerConfigurationFilter>;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
}>;


export type PartnersListQuery = { __typename?: 'Query', partnerConfigurations: { __typename?: 'PartnerConfigurationConnection', totalNodes: number, nodes: Array<{ __typename?: 'PartnerConfiguration', id: string, country: ServiceCountryEnum, name: string, mode: PartnerConfigurationModeEnum, preferAlternateTrackingNumber: boolean }> } };

export type DefaultPricesQueryVariables = Exact<{
  q?: Maybe<DefaultDeliveryCountryFilter>;
}>;


export type DefaultPricesQuery = { __typename?: 'Query', defaultDeliveryCountries: { __typename?: 'DefaultDeliveryCountryConnection', nodes: Array<{ __typename?: 'DefaultDeliveryCountry', id: string, country: ServiceCountryEnum, volumeWeightKg?: Maybe<number>, insuranceValue?: Maybe<number>, courierDeliverTime?: Maybe<string>, courierPartnerConfiguration?: Maybe<{ __typename?: 'PartnerConfiguration', id: string, name: string, mode: PartnerConfigurationModeEnum }>, deliveryLocationPartnerConfiguration?: Maybe<{ __typename?: 'PartnerConfiguration', id: string, name: string, mode: PartnerConfigurationModeEnum }>, priceRules: { __typename?: 'DefaultDeliveryCountryPriceRuleConnection', nodes: Array<{ __typename?: 'DefaultDeliveryCountryPriceRule', id: string, fixedPriceExVat: number, minWeightKg?: Maybe<number>, maxWeightKg?: Maybe<number>, deliveryKind: DeliveryKindEnum, currency: CurrencyEnum }> } }> } };

export type UpdateDefaultPricesMutationVariables = Exact<{
  input: UpdateDefaultPricesMutationInput;
}>;


export type UpdateDefaultPricesMutation = { __typename?: 'Mutation', updateDefaultPrices: { __typename?: 'UpdateDefaultPricesMutationPayload', success: boolean, errors: Array<{ __typename?: 'MutationError', type: string, message: string, attribute: string }> } };

export type UserGlobalEditQueryVariables = Exact<{
  userId: Scalars['ID'];
}>;


export type UserGlobalEditQuery = { __typename?: 'Query', user?: Maybe<{ __typename?: 'User', id: string, name?: Maybe<string>, email: string, phone?: Maybe<string>, role?: Maybe<{ __typename?: 'Role', id: string, globalSettings?: Maybe<boolean>, primaryCountry?: Maybe<ServiceCountryEnum>, active: boolean }> }> };

export type UpdateGlobalUserMutationVariables = Exact<{
  input: UpdateGlobalUserMutationInput;
}>;


export type UpdateGlobalUserMutation = { __typename?: 'Mutation', updateGlobalUser: { __typename?: 'UpdateGlobalUserMutationPayload', success: boolean, errors: Array<{ __typename?: 'MutationError', type: string, message: string, attribute: string }> } };

export type CreateGlobalUserMutationVariables = Exact<{
  input: CreateGlobalUserMutationInput;
}>;


export type CreateGlobalUserMutation = { __typename?: 'Mutation', createGlobalUser: { __typename?: 'CreateGlobalUserMutationPayload', success: boolean, errors: Array<{ __typename?: 'MutationError', type: string, message: string, attribute: string }> } };

export type UpdateCurrentUserProfileMutationVariables = Exact<{
  input: UpdateCurrentUserInput;
}>;


export type UpdateCurrentUserProfileMutation = { __typename?: 'Mutation', updateCurrentUser: { __typename?: 'UpdateCurrentUserPayload', success: boolean, errors: Array<{ __typename?: 'MutationError', type: string, message: string, attribute: string }>, user?: Maybe<{ __typename?: 'User', id: string, name?: Maybe<string>, phone?: Maybe<string> }> } };

export type UpdateOrderMutationVariables = Exact<{
  input: UpdateOrderMutationInput;
}>;


export type UpdateOrderMutation = { __typename?: 'Mutation', updateOrder: { __typename?: 'UpdateOrderMutationPayload', success: boolean, order?: Maybe<{ __typename?: 'Order', id: string }> } };

export type OrderEditQueryVariables = Exact<{
  orderId: Scalars['ID'];
}>;


export type OrderEditQuery = { __typename?: 'Query', order?: Maybe<{ __typename?: 'Order', id: string, deliveryKind: DeliveryKindEnum, status: OrderStatusEnum, partnerTrackingNumber?: Maybe<string>, senderReference?: Maybe<string>, senderComment?: Maybe<string>, weightKg?: Maybe<number>, lengthCm?: Maybe<number>, widthCm?: Maybe<number>, heightCm?: Maybe<number>, destinationCountry?: Maybe<ServiceCountryEnum>, destinationCity?: Maybe<string>, destinationZip?: Maybe<string>, destinationAddress?: Maybe<string>, recipientPhone?: Maybe<string>, recipientEmail?: Maybe<string>, recipientName?: Maybe<string>, customer: { __typename?: 'Customer', id: string, name: string }, pickupLocation?: Maybe<{ __typename?: 'PickupLocation', id: string, name: string, defaultHeightCm?: Maybe<number>, defaultLengthCm?: Maybe<number>, defaultWeightKg?: Maybe<number>, defaultWidthCm?: Maybe<number> }>, deliveryLocation?: Maybe<{ __typename?: 'DeliveryLocation', id: string, name: string, country?: Maybe<ServiceCountryEnum>, city?: Maybe<string>, address?: Maybe<string>, minHeightCm?: Maybe<number>, minLengthCm?: Maybe<number>, minWeightKg?: Maybe<number>, minWidthCm?: Maybe<number>, maxHeightCm?: Maybe<number>, maxLengthCm?: Maybe<number>, maxWeightKg?: Maybe<number>, maxWidthCm?: Maybe<number>, maxEdgeSumCm?: Maybe<number>, recipientPhonePrefix?: Maybe<string>, recipientPhoneRegexp?: Maybe<string>, directions?: Maybe<any> }> }> };

export type OrderShowExternalRequestsQueryVariables = Exact<{
  orderId: Scalars['ID'];
}>;


export type OrderShowExternalRequestsQuery = { __typename?: 'Query', order?: Maybe<{ __typename?: 'Order', id: string, customerId: string, senderReference?: Maybe<string>, partnerTrackingNumber?: Maybe<string>, status: OrderStatusEnum, externalRequests: Array<{ __typename?: 'ExternalRequest', id: string, service?: Maybe<string>, requestMethod?: Maybe<string>, requestUrl?: Maybe<string>, requestBody?: Maybe<string>, responseCode?: Maybe<string>, responseBody?: Maybe<string>, createdAt: string, updatedAt: string }> }> };

export type OrderShowHistoryQueryVariables = Exact<{
  orderId: Scalars['ID'];
}>;


export type OrderShowHistoryQuery = { __typename?: 'Query', order?: Maybe<{ __typename?: 'Order', id: string, customerId: string, senderReference?: Maybe<string>, partnerTrackingNumber?: Maybe<string>, status: OrderStatusEnum, historyEvents: Array<{ __typename?: 'HistoryEvent', id: string, event: HistoryEventEnum, changes?: Maybe<any>, author?: Maybe<string>, createdAt: string }> }> };

export type OrdersListQueryVariables = Exact<{
  q?: Maybe<OrderFilter>;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
}>;


export type OrdersListQuery = { __typename?: 'Query', orders: { __typename?: 'OrderConnection', totalNodes: number, nodes: Array<{ __typename?: 'Order', id: string, customerId: string, weightKg?: Maybe<number>, lengthCm?: Maybe<number>, widthCm?: Maybe<number>, heightCm?: Maybe<number>, deliveryKind: DeliveryKindEnum, destinationCountry?: Maybe<ServiceCountryEnum>, destinationCity?: Maybe<string>, destinationZip?: Maybe<string>, destinationAddress?: Maybe<string>, recipientPhone?: Maybe<string>, recipientPhonePrefix?: Maybe<string>, recipientEmail?: Maybe<string>, recipientName?: Maybe<string>, senderReference?: Maybe<string>, status: OrderStatusEnum, partnerTrackingNumber?: Maybe<string>, pickupLocationName?: Maybe<string>, createdAt: string, priceExVat?: Maybe<number>, priceExVatCurrency?: Maybe<CurrencyEnum>, customer: { __typename?: 'Customer', id: string, name: string } }> } };

export type UpdateOrderReadyStateMutationVariables = Exact<{
  input: UpdateOrderReadyStateInput;
}>;


export type UpdateOrderReadyStateMutation = { __typename?: 'Mutation', updateOrderReadyState: { __typename?: 'UpdateOrderReadyStatePayload', success: boolean, orders?: Maybe<Array<{ __typename?: 'Order', id: string, status: OrderStatusEnum }>> } };

export type NewOrderPickupLocationsQueryVariables = Exact<{
  q?: Maybe<PickupLocationFilter>;
}>;


export type NewOrderPickupLocationsQuery = { __typename?: 'Query', pickupLocations: { __typename?: 'PickupLocationConnection', nodes: Array<{ __typename?: 'PickupLocation', id: string, name: string, defaultHeightCm?: Maybe<number>, defaultLengthCm?: Maybe<number>, defaultWeightKg?: Maybe<number>, defaultWidthCm?: Maybe<number> }> } };

export type NewOrderCitiesQueryVariables = Exact<{
  q: DeliveryLocationFilter;
}>;


export type NewOrderCitiesQuery = { __typename?: 'Query', deliveryLocationCities: Array<string> };

export type NewOrderDeliveryLocationsQueryVariables = Exact<{
  q?: Maybe<DeliveryLocationFilter>;
}>;


export type NewOrderDeliveryLocationsQuery = { __typename?: 'Query', deliveryLocations: { __typename?: 'DeliveryLocationConnection', nodes: Array<{ __typename?: 'DeliveryLocation', id: string, name: string, address?: Maybe<string>, minHeightCm?: Maybe<number>, minLengthCm?: Maybe<number>, minWeightKg?: Maybe<number>, minWidthCm?: Maybe<number>, maxHeightCm?: Maybe<number>, maxLengthCm?: Maybe<number>, maxWeightKg?: Maybe<number>, maxWidthCm?: Maybe<number>, maxEdgeSumCm?: Maybe<number>, recipientPhonePrefix?: Maybe<string>, recipientPhoneRegexp?: Maybe<string>, directions?: Maybe<any> }> } };

export type NewOrderVolumeWeightQueryVariables = Exact<{
  q?: Maybe<CustomerDeliveryCountryFilter>;
}>;


export type NewOrderVolumeWeightQuery = { __typename?: 'Query', customerDeliveryCountries: { __typename?: 'CustomerDeliveryCountryConnection', nodes: Array<{ __typename?: 'CustomerDeliveryCountry', id: string, volumeWeightKg?: Maybe<number> }> } };

export type CreateOrderMutationVariables = Exact<{
  input: CreateOrderMutationInput;
}>;


export type CreateOrderMutation = { __typename?: 'Mutation', createOrder: { __typename?: 'CreateOrderMutationPayload', success: boolean, order?: Maybe<{ __typename?: 'Order', id: string }> } };

export type NewOrderSourceOrderQueryVariables = Exact<{
  sourceOrderId: Scalars['ID'];
}>;


export type NewOrderSourceOrderQuery = { __typename?: 'Query', order?: Maybe<{ __typename?: 'Order', id: string, deliveryKind: DeliveryKindEnum, weightKg?: Maybe<number>, lengthCm?: Maybe<number>, widthCm?: Maybe<number>, heightCm?: Maybe<number>, destinationCountry?: Maybe<ServiceCountryEnum>, destinationCity?: Maybe<string>, destinationZip?: Maybe<string>, destinationAddress?: Maybe<string>, recipientPhone?: Maybe<string>, recipientEmail?: Maybe<string>, recipientName?: Maybe<string>, customer: { __typename?: 'Customer', id: string, name: string }, pickupLocation?: Maybe<{ __typename?: 'PickupLocation', id: string, name: string, defaultHeightCm?: Maybe<number>, defaultLengthCm?: Maybe<number>, defaultWeightKg?: Maybe<number>, defaultWidthCm?: Maybe<number> }>, deliveryLocation?: Maybe<{ __typename?: 'DeliveryLocation', id: string, name: string, country?: Maybe<ServiceCountryEnum>, city?: Maybe<string>, address?: Maybe<string>, minHeightCm?: Maybe<number>, minLengthCm?: Maybe<number>, minWeightKg?: Maybe<number>, minWidthCm?: Maybe<number>, maxHeightCm?: Maybe<number>, maxLengthCm?: Maybe<number>, maxWeightKg?: Maybe<number>, maxWidthCm?: Maybe<number>, maxEdgeSumCm?: Maybe<number>, recipientPhonePrefix?: Maybe<string>, recipientPhoneRegexp?: Maybe<string>, directions?: Maybe<any> }> }> };

export type NewOrderHistoryListQueryVariables = Exact<{
  q?: Maybe<OrderFilter>;
}>;


export type NewOrderHistoryListQuery = { __typename?: 'Query', orders: { __typename?: 'OrderConnection', nodes: Array<{ __typename?: 'Order', id: string, recipientName?: Maybe<string>, partnerTrackingNumber?: Maybe<string>, createdAt: string }> } };

export type CourierOrdersCustomerDeliveryCountryQueryVariables = Exact<{
  q?: Maybe<CustomerDeliveryCountryFilter>;
}>;


export type CourierOrdersCustomerDeliveryCountryQuery = { __typename?: 'Query', customerDeliveryCountries: { __typename?: 'CustomerDeliveryCountryConnection', nodes: Array<{ __typename?: 'CustomerDeliveryCountry', id: string, courierMinHeightCm?: Maybe<number>, courierMinLengthCm?: Maybe<number>, courierMinWidthCm?: Maybe<number>, courierMinWeightKg?: Maybe<number>, courierMaxHeightCm?: Maybe<number>, courierMaxLengthCm?: Maybe<number>, courierMaxWidthCm?: Maybe<number>, courierMaxWeightKg?: Maybe<number>, courierMaxEdgeSumCm?: Maybe<number>, courierRecipientPhoneRegexp?: Maybe<string>, courierRecipientPhonePrefix?: Maybe<string>, courierDestinationZipRegexp?: Maybe<string>, courierDirections?: Maybe<any> }> } };

export type CourierCustomerDeliveryCountriesQueryVariables = Exact<{
  q?: Maybe<CustomerDeliveryCountryFilter>;
}>;


export type CourierCustomerDeliveryCountriesQuery = { __typename?: 'Query', courierDeliveryLocationCountries: Array<ServiceCountryEnum> };

export type OrderShowQueryVariables = Exact<{
  orderId: Scalars['ID'];
}>;


export type OrderShowQuery = { __typename?: 'Query', order?: Maybe<{ __typename?: 'Order', id: string, customerId: string, createdAt: string, updatedAt: string, weightKg?: Maybe<number>, lengthCm?: Maybe<number>, widthCm?: Maybe<number>, heightCm?: Maybe<number>, destinationCountry?: Maybe<ServiceCountryEnum>, destinationCity?: Maybe<string>, destinationZip?: Maybe<string>, destinationAddress?: Maybe<string>, recipientPhone?: Maybe<string>, recipientPhonePrefix?: Maybe<string>, recipientEmail?: Maybe<string>, recipientName?: Maybe<string>, senderReference?: Maybe<string>, senderComment?: Maybe<string>, status: OrderStatusEnum, partnerTrackingNumber?: Maybe<string>, partnerAlternateTrackingNumber?: Maybe<string>, pickupLocationCountry?: Maybe<ServiceCountryEnum>, pickupLocationCity?: Maybe<string>, pickupLocationZip?: Maybe<string>, pickupLocationAddress?: Maybe<string>, pickupLocationName?: Maybe<string>, trackingUrl?: Maybe<string>, partnerTrackingUrl?: Maybe<string>, trackingEmailSentAt?: Maybe<string>, partnerTrackingEmailSentAt?: Maybe<string>, expectedPickupAt?: Maybe<string>, deliveryKind: DeliveryKindEnum, priceExVat?: Maybe<number>, priceExVatCurrency?: Maybe<CurrencyEnum> }> };

export type CancelOrderMutationVariables = Exact<{
  input: CancelOrderMutationInput;
}>;


export type CancelOrderMutation = { __typename?: 'Mutation', cancelOrder: { __typename?: 'CancelOrderMutationPayload', success: boolean, order?: Maybe<{ __typename?: 'Order', id: string, status: OrderStatusEnum }> } };

export type SendOrderTrackingEmailMutationVariables = Exact<{
  input: SendOrderTrackingEmailMutationInput;
}>;


export type SendOrderTrackingEmailMutation = { __typename?: 'Mutation', sendOrderTrackingEmail: { __typename?: 'SendOrderTrackingEmailMutationPayload', success: boolean, order?: Maybe<{ __typename?: 'Order', id: string, trackingEmailSentAt?: Maybe<string>, partnerTrackingEmailSentAt?: Maybe<string> }> } };

export type OrderShowTrackingQueryVariables = Exact<{
  orderId: Scalars['ID'];
}>;


export type OrderShowTrackingQuery = { __typename?: 'Query', order?: Maybe<{ __typename?: 'Order', id: string, customerId: string, senderReference?: Maybe<string>, partnerTrackingNumber?: Maybe<string>, status: OrderStatusEnum, trackingEvents: Array<{ __typename?: 'TrackingEvent', id: string, event: TrackingEventEnum, aliasedEvent: TrackingAliasedEventEnum, location?: Maybe<string>, timestamp: string, additionalData?: Maybe<any> }> }> };

export type CustomerApiClientFilesListQueryVariables = Exact<{
  q?: Maybe<CustomerApiClientFileFilter>;
}>;


export type CustomerApiClientFilesListQuery = { __typename?: 'Query', customerApiClientFiles: { __typename?: 'CustomerAPIClientFileConnection', nodes: Array<{ __typename?: 'CustomerAPIClientFile', id: string, platformName: string, fileName: string, fileUrl?: Maybe<string> }> } };

export type ApiTokensListQueryVariables = Exact<{
  q?: Maybe<CustomerApiTokenFilter>;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
}>;


export type ApiTokensListQuery = { __typename?: 'Query', customerApiTokens: { __typename?: 'CustomerAPITokenConnection', totalNodes: number, nodes: Array<{ __typename?: 'CustomerAPIToken', id: string, customerId?: Maybe<string>, name: string, createdAt: string, accessedAt?: Maybe<string> }> } };

export type CustomerContactsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CustomerContactsQuery = { __typename?: 'Query', customer?: Maybe<{ __typename?: 'Customer', id: string, invoiceEmail?: Maybe<string>, contactName?: Maybe<string>, contactEmail?: Maybe<string>, contactPhone?: Maybe<string>, supportName?: Maybe<string>, supportEmail?: Maybe<string>, supportPhone?: Maybe<string> }> };

export type PickupLocationEditQueryVariables = Exact<{
  pickupLocationId: Scalars['ID'];
}>;


export type PickupLocationEditQuery = { __typename?: 'Query', pickupLocation?: Maybe<{ __typename?: 'PickupLocation', id: string, customerId: string, name: string, country?: Maybe<ServiceCountryEnum>, city?: Maybe<string>, address?: Maybe<string>, zip?: Maybe<string>, email?: Maybe<string>, phone?: Maybe<string>, defaultHeightCm?: Maybe<number>, defaultLengthCm?: Maybe<number>, defaultWeightKg?: Maybe<number>, defaultWidthCm?: Maybe<number>, active: boolean }> };

export type UpdatePickupLocationMutationVariables = Exact<{
  input: UpdatePickupLocationMutationInput;
}>;


export type UpdatePickupLocationMutation = { __typename?: 'Mutation', updatePickupLocation: { __typename?: 'UpdatePickupLocationMutationPayload', success: boolean, errors: Array<{ __typename?: 'MutationError', type: string, message: string, attribute: string }> } };

export type PickupLocationsListQueryVariables = Exact<{
  q?: Maybe<PickupLocationFilter>;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
}>;


export type PickupLocationsListQuery = { __typename?: 'Query', pickupLocations: { __typename?: 'PickupLocationConnection', totalNodes: number, nodes: Array<{ __typename?: 'PickupLocation', id: string, customerId: string, name: string, country?: Maybe<ServiceCountryEnum>, city?: Maybe<string>, address?: Maybe<string>, email?: Maybe<string>, phone?: Maybe<string>, active: boolean }> } };

export type UpdatePickupLocationActiveStateMutationVariables = Exact<{
  input: UpdatePickupLocationActiveStateMutationInput;
}>;


export type UpdatePickupLocationActiveStateMutation = { __typename?: 'Mutation', updatePickupLocationActiveState: { __typename?: 'UpdatePickupLocationActiveStateMutationPayload', success: boolean, pickupLocation?: Maybe<{ __typename?: 'PickupLocation', id: string, active: boolean }> } };

export type CreatePickupLocationMutationVariables = Exact<{
  input: CreatePickupLocationMutationInput;
}>;


export type CreatePickupLocationMutation = { __typename?: 'Mutation', createPickupLocation: { __typename?: 'CreatePickupLocationMutationPayload', success: boolean, errors: Array<{ __typename?: 'MutationError', type: string, message: string, attribute: string }> } };

export type PickupScheduleEditQueryVariables = Exact<{
  pickupScheduleId: Scalars['ID'];
}>;


export type PickupScheduleEditQuery = { __typename?: 'Query', pickupSchedule?: Maybe<{ __typename?: 'PickupSchedule', id: string, monday: boolean, tuesday: boolean, wednesday: boolean, thursday: boolean, friday: boolean, saturday: boolean, sunday: boolean, pickupFrom?: Maybe<string>, pickupTo?: Maybe<string>, pickupLocation: { __typename?: 'PickupLocation', id: string, name: string } }> };

export type UpdatePickupScheduleMutationVariables = Exact<{
  input: UpdatePickupScheduleMutationInput;
}>;


export type UpdatePickupScheduleMutation = { __typename?: 'Mutation', updatePickupSchedule: { __typename?: 'UpdatePickupScheduleMutationPayload', success: boolean, errors: Array<{ __typename?: 'MutationError', type: string, message: string, attribute: string }> } };

export type PickupSchedulesListQueryVariables = Exact<{
  q?: Maybe<PickupScheduleFilter>;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
}>;


export type PickupSchedulesListQuery = { __typename?: 'Query', pickupSchedules: { __typename?: 'PickupScheduleConnection', totalNodes: number, nodes: Array<{ __typename?: 'PickupSchedule', id: string, customerId: string, monday: boolean, tuesday: boolean, wednesday: boolean, thursday: boolean, friday: boolean, saturday: boolean, sunday: boolean, pickupFrom?: Maybe<string>, pickupTo?: Maybe<string>, pickupLocation: { __typename?: 'PickupLocation', id: string, name: string } }> } };

export type CreatePickupScheduleMutationVariables = Exact<{
  input: CreatePickupScheduleMutationInput;
}>;


export type CreatePickupScheduleMutation = { __typename?: 'Mutation', createPickupSchedule: { __typename?: 'CreatePickupScheduleMutationPayload', success: boolean, errors: Array<{ __typename?: 'MutationError', type: string, message: string, attribute: string }> } };

export type CustomerPricingQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CustomerPricingQuery = { __typename?: 'Query', customer?: Maybe<{ __typename?: 'Customer', id: string, country: ServiceCountryEnum, deliveryCountries: { __typename?: 'CustomerDeliveryCountryConnection', nodes: Array<{ __typename?: 'CustomerDeliveryCountry', id: string, customerId: string, country: ServiceCountryEnum, volumeWeightKg?: Maybe<number>, minHeightCm?: Maybe<number>, minLengthCm?: Maybe<number>, minWidthCm?: Maybe<number>, maxHeightCm?: Maybe<number>, maxLengthCm?: Maybe<number>, maxWidthCm?: Maybe<number>, courierMinHeightCm?: Maybe<number>, courierMinLengthCm?: Maybe<number>, courierMinWidthCm?: Maybe<number>, courierMaxHeightCm?: Maybe<number>, courierMaxLengthCm?: Maybe<number>, courierMaxWidthCm?: Maybe<number>, priceRules: { __typename?: 'CustomerDeliveryCountryPriceRuleConnection', nodes: Array<{ __typename?: 'CustomerDeliveryCountryPriceRule', id: string, customerId: string, fixedPriceExVat: number, minWeightKg?: Maybe<number>, maxWeightKg?: Maybe<number>, currency: CurrencyEnum, deliveryKind: DeliveryKindEnum }> } }> } }> };

export type UserEditQueryVariables = Exact<{
  userId: Scalars['ID'];
  customerId: Scalars['ID'];
}>;


export type UserEditQuery = { __typename?: 'Query', user?: Maybe<{ __typename?: 'User', id: string, name?: Maybe<string>, email: string, phone?: Maybe<string>, role?: Maybe<{ __typename?: 'Role', id: string, customerId?: Maybe<string>, customerSettings?: Maybe<boolean>, invoiceAccess?: Maybe<boolean>, orderManagement?: Maybe<boolean>, userManagement?: Maybe<boolean>, active: boolean }> }> };

export type UpdateCustomerUserMutationVariables = Exact<{
  input: UpdateCustomerUserMutationInput;
}>;


export type UpdateCustomerUserMutation = { __typename?: 'Mutation', updateCustomerUser: { __typename?: 'UpdateCustomerUserMutationPayload', success: boolean, errors: Array<{ __typename?: 'MutationError', type: string, message: string, attribute: string }> } };

export type UsersListQueryVariables = Exact<{
  q?: Maybe<UserFilter>;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['ID']>;
}>;


export type UsersListQuery = { __typename?: 'Query', users: { __typename?: 'UserConnection', totalNodes: number, nodes: Array<{ __typename?: 'User', id: string, name?: Maybe<string>, email: string, phone?: Maybe<string>, role?: Maybe<{ __typename?: 'Role', id: string, active: boolean }> }> } };

export type CreateCustomerUserMutationVariables = Exact<{
  input: CreateCustomerUserMutationInput;
}>;


export type CreateCustomerUserMutation = { __typename?: 'Mutation', createCustomerUser: { __typename?: 'CreateCustomerUserMutationPayload', success: boolean, errors: Array<{ __typename?: 'MutationError', type: string, message: string, attribute: string }> } };


export const CreateApiTokenDocument = gql`
    mutation CreateApiToken($input: CreateAPITokenMutationInput!) {
  createApiToken(input: $input) {
    success
    apiToken {
      id
      name
      token
    }
  }
}
    `;
export type CreateApiTokenMutationFn = Apollo.MutationFunction<CreateApiTokenMutation, CreateApiTokenMutationVariables>;

/**
 * __useCreateApiTokenMutation__
 *
 * To run a mutation, you first call `useCreateApiTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateApiTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createApiTokenMutation, { data, loading, error }] = useCreateApiTokenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateApiTokenMutation(baseOptions?: Apollo.MutationHookOptions<CreateApiTokenMutation, CreateApiTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateApiTokenMutation, CreateApiTokenMutationVariables>(CreateApiTokenDocument, options);
      }
export type CreateApiTokenMutationHookResult = ReturnType<typeof useCreateApiTokenMutation>;
export type CreateApiTokenMutationResult = Apollo.MutationResult<CreateApiTokenMutation>;
export type CreateApiTokenMutationOptions = Apollo.BaseMutationOptions<CreateApiTokenMutation, CreateApiTokenMutationVariables>;
export const CreateGlobalApiTokenDocument = gql`
    mutation CreateGlobalApiToken {
  createGlobalApiToken {
    success
    apiToken {
      id
      name
      token
    }
  }
}
    `;
export type CreateGlobalApiTokenMutationFn = Apollo.MutationFunction<CreateGlobalApiTokenMutation, CreateGlobalApiTokenMutationVariables>;

/**
 * __useCreateGlobalApiTokenMutation__
 *
 * To run a mutation, you first call `useCreateGlobalApiTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGlobalApiTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGlobalApiTokenMutation, { data, loading, error }] = useCreateGlobalApiTokenMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateGlobalApiTokenMutation(baseOptions?: Apollo.MutationHookOptions<CreateGlobalApiTokenMutation, CreateGlobalApiTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateGlobalApiTokenMutation, CreateGlobalApiTokenMutationVariables>(CreateGlobalApiTokenDocument, options);
      }
export type CreateGlobalApiTokenMutationHookResult = ReturnType<typeof useCreateGlobalApiTokenMutation>;
export type CreateGlobalApiTokenMutationResult = Apollo.MutationResult<CreateGlobalApiTokenMutation>;
export type CreateGlobalApiTokenMutationOptions = Apollo.BaseMutationOptions<CreateGlobalApiTokenMutation, CreateGlobalApiTokenMutationVariables>;
export const DestroyApiTokenDocument = gql`
    mutation DestroyApiToken($input: DestroyAPITokenMutationInput!) {
  destroyApiToken(input: $input) {
    success
  }
}
    `;
export type DestroyApiTokenMutationFn = Apollo.MutationFunction<DestroyApiTokenMutation, DestroyApiTokenMutationVariables>;

/**
 * __useDestroyApiTokenMutation__
 *
 * To run a mutation, you first call `useDestroyApiTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDestroyApiTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [destroyApiTokenMutation, { data, loading, error }] = useDestroyApiTokenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDestroyApiTokenMutation(baseOptions?: Apollo.MutationHookOptions<DestroyApiTokenMutation, DestroyApiTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DestroyApiTokenMutation, DestroyApiTokenMutationVariables>(DestroyApiTokenDocument, options);
      }
export type DestroyApiTokenMutationHookResult = ReturnType<typeof useDestroyApiTokenMutation>;
export type DestroyApiTokenMutationResult = Apollo.MutationResult<DestroyApiTokenMutation>;
export type DestroyApiTokenMutationOptions = Apollo.BaseMutationOptions<DestroyApiTokenMutation, DestroyApiTokenMutationVariables>;
export const PartnerDeliveryLocationStatsDocument = gql`
    query PartnerDeliveryLocationStats($q: DeliveryLocationFilter) {
  deliveryLocations(q: $q) {
    totalNodes
    totalActive
  }
}
    `;

/**
 * __usePartnerDeliveryLocationStatsQuery__
 *
 * To run a query within a React component, call `usePartnerDeliveryLocationStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePartnerDeliveryLocationStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePartnerDeliveryLocationStatsQuery({
 *   variables: {
 *      q: // value for 'q'
 *   },
 * });
 */
export function usePartnerDeliveryLocationStatsQuery(baseOptions?: Apollo.QueryHookOptions<PartnerDeliveryLocationStatsQuery, PartnerDeliveryLocationStatsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PartnerDeliveryLocationStatsQuery, PartnerDeliveryLocationStatsQueryVariables>(PartnerDeliveryLocationStatsDocument, options);
      }
export function usePartnerDeliveryLocationStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PartnerDeliveryLocationStatsQuery, PartnerDeliveryLocationStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PartnerDeliveryLocationStatsQuery, PartnerDeliveryLocationStatsQueryVariables>(PartnerDeliveryLocationStatsDocument, options);
        }
export type PartnerDeliveryLocationStatsQueryHookResult = ReturnType<typeof usePartnerDeliveryLocationStatsQuery>;
export type PartnerDeliveryLocationStatsLazyQueryHookResult = ReturnType<typeof usePartnerDeliveryLocationStatsLazyQuery>;
export type PartnerDeliveryLocationStatsQueryResult = Apollo.QueryResult<PartnerDeliveryLocationStatsQuery, PartnerDeliveryLocationStatsQueryVariables>;
export const UploadPartnerDeliveryLocationStatsDocument = gql`
    mutation UploadPartnerDeliveryLocationStats($input: UploadPartnerDeliveryLocationsMutationInput!) {
  uploadPartnerDeliveryLocations(input: $input) {
    success
    errors {
      type
      message
      attribute
    }
  }
}
    `;
export type UploadPartnerDeliveryLocationStatsMutationFn = Apollo.MutationFunction<UploadPartnerDeliveryLocationStatsMutation, UploadPartnerDeliveryLocationStatsMutationVariables>;

/**
 * __useUploadPartnerDeliveryLocationStatsMutation__
 *
 * To run a mutation, you first call `useUploadPartnerDeliveryLocationStatsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadPartnerDeliveryLocationStatsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadPartnerDeliveryLocationStatsMutation, { data, loading, error }] = useUploadPartnerDeliveryLocationStatsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadPartnerDeliveryLocationStatsMutation(baseOptions?: Apollo.MutationHookOptions<UploadPartnerDeliveryLocationStatsMutation, UploadPartnerDeliveryLocationStatsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadPartnerDeliveryLocationStatsMutation, UploadPartnerDeliveryLocationStatsMutationVariables>(UploadPartnerDeliveryLocationStatsDocument, options);
      }
export type UploadPartnerDeliveryLocationStatsMutationHookResult = ReturnType<typeof useUploadPartnerDeliveryLocationStatsMutation>;
export type UploadPartnerDeliveryLocationStatsMutationResult = Apollo.MutationResult<UploadPartnerDeliveryLocationStatsMutation>;
export type UploadPartnerDeliveryLocationStatsMutationOptions = Apollo.BaseMutationOptions<UploadPartnerDeliveryLocationStatsMutation, UploadPartnerDeliveryLocationStatsMutationVariables>;
export const OrdersAutocompleteDocument = gql`
    query OrdersAutocomplete($q: OrderFilter, $first: Int) {
  orders(q: $q, first: $first) {
    nodes {
      id
      customerId
      senderReference
      partnerTrackingNumber
    }
  }
}
    `;

/**
 * __useOrdersAutocompleteQuery__
 *
 * To run a query within a React component, call `useOrdersAutocompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrdersAutocompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrdersAutocompleteQuery({
 *   variables: {
 *      q: // value for 'q'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useOrdersAutocompleteQuery(baseOptions?: Apollo.QueryHookOptions<OrdersAutocompleteQuery, OrdersAutocompleteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrdersAutocompleteQuery, OrdersAutocompleteQueryVariables>(OrdersAutocompleteDocument, options);
      }
export function useOrdersAutocompleteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrdersAutocompleteQuery, OrdersAutocompleteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrdersAutocompleteQuery, OrdersAutocompleteQueryVariables>(OrdersAutocompleteDocument, options);
        }
export type OrdersAutocompleteQueryHookResult = ReturnType<typeof useOrdersAutocompleteQuery>;
export type OrdersAutocompleteLazyQueryHookResult = ReturnType<typeof useOrdersAutocompleteLazyQuery>;
export type OrdersAutocompleteQueryResult = Apollo.QueryResult<OrdersAutocompleteQuery, OrdersAutocompleteQueryVariables>;
export const PickupLocationSelectorOptionsDocument = gql`
    query PickupLocationSelectorOptions($q: PickupLocationFilter) {
  pickupLocations(q: $q) {
    nodes {
      id
      customerId
      name
    }
  }
}
    `;

/**
 * __usePickupLocationSelectorOptionsQuery__
 *
 * To run a query within a React component, call `usePickupLocationSelectorOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePickupLocationSelectorOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePickupLocationSelectorOptionsQuery({
 *   variables: {
 *      q: // value for 'q'
 *   },
 * });
 */
export function usePickupLocationSelectorOptionsQuery(baseOptions?: Apollo.QueryHookOptions<PickupLocationSelectorOptionsQuery, PickupLocationSelectorOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PickupLocationSelectorOptionsQuery, PickupLocationSelectorOptionsQueryVariables>(PickupLocationSelectorOptionsDocument, options);
      }
export function usePickupLocationSelectorOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PickupLocationSelectorOptionsQuery, PickupLocationSelectorOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PickupLocationSelectorOptionsQuery, PickupLocationSelectorOptionsQueryVariables>(PickupLocationSelectorOptionsDocument, options);
        }
export type PickupLocationSelectorOptionsQueryHookResult = ReturnType<typeof usePickupLocationSelectorOptionsQuery>;
export type PickupLocationSelectorOptionsLazyQueryHookResult = ReturnType<typeof usePickupLocationSelectorOptionsLazyQuery>;
export type PickupLocationSelectorOptionsQueryResult = Apollo.QueryResult<PickupLocationSelectorOptionsQuery, PickupLocationSelectorOptionsQueryVariables>;
export const OrderShippingLabelDocument = gql`
    query OrderShippingLabel($orderId: ID!, $kind: ShippingLabelKindEnum!) {
  order(id: $orderId) {
    id
    canPrintReturnLabel
    shippingLabel(kind: $kind) {
      id
      fileName
      fileContent
    }
  }
}
    `;

/**
 * __useOrderShippingLabelQuery__
 *
 * To run a query within a React component, call `useOrderShippingLabelQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderShippingLabelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderShippingLabelQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *      kind: // value for 'kind'
 *   },
 * });
 */
export function useOrderShippingLabelQuery(baseOptions: Apollo.QueryHookOptions<OrderShippingLabelQuery, OrderShippingLabelQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrderShippingLabelQuery, OrderShippingLabelQueryVariables>(OrderShippingLabelDocument, options);
      }
export function useOrderShippingLabelLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrderShippingLabelQuery, OrderShippingLabelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrderShippingLabelQuery, OrderShippingLabelQueryVariables>(OrderShippingLabelDocument, options);
        }
export type OrderShippingLabelQueryHookResult = ReturnType<typeof useOrderShippingLabelQuery>;
export type OrderShippingLabelLazyQueryHookResult = ReturnType<typeof useOrderShippingLabelLazyQuery>;
export type OrderShippingLabelQueryResult = Apollo.QueryResult<OrderShippingLabelQuery, OrderShippingLabelQueryVariables>;
export const OrderTrackingStatusDocument = gql`
    query OrderTrackingStatus($id: ID!) {
  order(id: $id) {
    id
    status
    trackingUrl
    partnerTrackingNumber
    partnerTrackingUrl
  }
}
    `;

/**
 * __useOrderTrackingStatusQuery__
 *
 * To run a query within a React component, call `useOrderTrackingStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderTrackingStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderTrackingStatusQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrderTrackingStatusQuery(baseOptions: Apollo.QueryHookOptions<OrderTrackingStatusQuery, OrderTrackingStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrderTrackingStatusQuery, OrderTrackingStatusQueryVariables>(OrderTrackingStatusDocument, options);
      }
export function useOrderTrackingStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrderTrackingStatusQuery, OrderTrackingStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrderTrackingStatusQuery, OrderTrackingStatusQueryVariables>(OrderTrackingStatusDocument, options);
        }
export type OrderTrackingStatusQueryHookResult = ReturnType<typeof useOrderTrackingStatusQuery>;
export type OrderTrackingStatusLazyQueryHookResult = ReturnType<typeof useOrderTrackingStatusLazyQuery>;
export type OrderTrackingStatusQueryResult = Apollo.QueryResult<OrderTrackingStatusQuery, OrderTrackingStatusQueryVariables>;
export const RetryUploadOderDocument = gql`
    mutation RetryUploadOder($input: RetryUploadOrderMutationInput!) {
  retryUploadOrder(input: $input) {
    success
  }
}
    `;
export type RetryUploadOderMutationFn = Apollo.MutationFunction<RetryUploadOderMutation, RetryUploadOderMutationVariables>;

/**
 * __useRetryUploadOderMutation__
 *
 * To run a mutation, you first call `useRetryUploadOderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRetryUploadOderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [retryUploadOderMutation, { data, loading, error }] = useRetryUploadOderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRetryUploadOderMutation(baseOptions?: Apollo.MutationHookOptions<RetryUploadOderMutation, RetryUploadOderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RetryUploadOderMutation, RetryUploadOderMutationVariables>(RetryUploadOderDocument, options);
      }
export type RetryUploadOderMutationHookResult = ReturnType<typeof useRetryUploadOderMutation>;
export type RetryUploadOderMutationResult = Apollo.MutationResult<RetryUploadOderMutation>;
export type RetryUploadOderMutationOptions = Apollo.BaseMutationOptions<RetryUploadOderMutation, RetryUploadOderMutationVariables>;
export const MarkOrderAsReturnedToPartnerDocument = gql`
    mutation MarkOrderAsReturnedToPartner($input: MarkOrderAsReturnedToPartnerInput!) {
  markAsReturnedToPartner(input: $input) {
    success
  }
}
    `;
export type MarkOrderAsReturnedToPartnerMutationFn = Apollo.MutationFunction<MarkOrderAsReturnedToPartnerMutation, MarkOrderAsReturnedToPartnerMutationVariables>;

/**
 * __useMarkOrderAsReturnedToPartnerMutation__
 *
 * To run a mutation, you first call `useMarkOrderAsReturnedToPartnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkOrderAsReturnedToPartnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markOrderAsReturnedToPartnerMutation, { data, loading, error }] = useMarkOrderAsReturnedToPartnerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMarkOrderAsReturnedToPartnerMutation(baseOptions?: Apollo.MutationHookOptions<MarkOrderAsReturnedToPartnerMutation, MarkOrderAsReturnedToPartnerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkOrderAsReturnedToPartnerMutation, MarkOrderAsReturnedToPartnerMutationVariables>(MarkOrderAsReturnedToPartnerDocument, options);
      }
export type MarkOrderAsReturnedToPartnerMutationHookResult = ReturnType<typeof useMarkOrderAsReturnedToPartnerMutation>;
export type MarkOrderAsReturnedToPartnerMutationResult = Apollo.MutationResult<MarkOrderAsReturnedToPartnerMutation>;
export type MarkOrderAsReturnedToPartnerMutationOptions = Apollo.BaseMutationOptions<MarkOrderAsReturnedToPartnerMutation, MarkOrderAsReturnedToPartnerMutationVariables>;
export const OrderStatusesDocument = gql`
    query OrderStatuses($q: OrderFilter) {
  orders(q: $q) {
    statusReport {
      newCount
      readyCount
      inDeliveryCount
      deliveredCount
      cancelledCount
      inReturnCount
      returnedCount
      errorCount
    }
  }
}
    `;

/**
 * __useOrderStatusesQuery__
 *
 * To run a query within a React component, call `useOrderStatusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderStatusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderStatusesQuery({
 *   variables: {
 *      q: // value for 'q'
 *   },
 * });
 */
export function useOrderStatusesQuery(baseOptions?: Apollo.QueryHookOptions<OrderStatusesQuery, OrderStatusesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrderStatusesQuery, OrderStatusesQueryVariables>(OrderStatusesDocument, options);
      }
export function useOrderStatusesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrderStatusesQuery, OrderStatusesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrderStatusesQuery, OrderStatusesQueryVariables>(OrderStatusesDocument, options);
        }
export type OrderStatusesQueryHookResult = ReturnType<typeof useOrderStatusesQuery>;
export type OrderStatusesLazyQueryHookResult = ReturnType<typeof useOrderStatusesLazyQuery>;
export type OrderStatusesQueryResult = Apollo.QueryResult<OrderStatusesQuery, OrderStatusesQueryVariables>;
export const UpdateViewConfigurationDocument = gql`
    mutation UpdateViewConfiguration($input: UpdateCurrentUserViewConfigurationInput!) {
  updateCurrentUserViewConfiguration(input: $input) {
    success
    viewConfiguration {
      id
      key
      fields
    }
  }
}
    `;
export type UpdateViewConfigurationMutationFn = Apollo.MutationFunction<UpdateViewConfigurationMutation, UpdateViewConfigurationMutationVariables>;

/**
 * __useUpdateViewConfigurationMutation__
 *
 * To run a mutation, you first call `useUpdateViewConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateViewConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateViewConfigurationMutation, { data, loading, error }] = useUpdateViewConfigurationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateViewConfigurationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateViewConfigurationMutation, UpdateViewConfigurationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateViewConfigurationMutation, UpdateViewConfigurationMutationVariables>(UpdateViewConfigurationDocument, options);
      }
export type UpdateViewConfigurationMutationHookResult = ReturnType<typeof useUpdateViewConfigurationMutation>;
export type UpdateViewConfigurationMutationResult = Apollo.MutationResult<UpdateViewConfigurationMutation>;
export type UpdateViewConfigurationMutationOptions = Apollo.BaseMutationOptions<UpdateViewConfigurationMutation, UpdateViewConfigurationMutationVariables>;
export const CurrentUserDocument = gql`
    query CurrentUser {
  currentUser {
    id
    locale
    email
    name
    phone
    firstLogin
    viewConfigurations {
      id
      key
      fields
    }
    roles(q: {sorts: [{name: created_at, dir: asc}]}) {
      nodes {
        id
        scope
        customerId
        customer {
          id
          name
          country
        }
        active
        primaryCountry
      }
    }
  }
  currentAbilities {
    action
    subject
    fields
    conditions
    inverted
  }
}
    `;

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserQuery(baseOptions?: Apollo.QueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, options);
      }
export function useCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, options);
        }
export type CurrentUserQueryHookResult = ReturnType<typeof useCurrentUserQuery>;
export type CurrentUserLazyQueryHookResult = ReturnType<typeof useCurrentUserLazyQuery>;
export type CurrentUserQueryResult = Apollo.QueryResult<CurrentUserQuery, CurrentUserQueryVariables>;
export const DeliveryCountryOptionsDocument = gql`
    query DeliveryCountryOptions($customerId: ID) {
  deliveryLocationCountries(
    q: {activeEq: true, customerDeliveryCountriesCustomerIdEq: $customerId}
  )
}
    `;

/**
 * __useDeliveryCountryOptionsQuery__
 *
 * To run a query within a React component, call `useDeliveryCountryOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeliveryCountryOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeliveryCountryOptionsQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useDeliveryCountryOptionsQuery(baseOptions?: Apollo.QueryHookOptions<DeliveryCountryOptionsQuery, DeliveryCountryOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeliveryCountryOptionsQuery, DeliveryCountryOptionsQueryVariables>(DeliveryCountryOptionsDocument, options);
      }
export function useDeliveryCountryOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeliveryCountryOptionsQuery, DeliveryCountryOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeliveryCountryOptionsQuery, DeliveryCountryOptionsQueryVariables>(DeliveryCountryOptionsDocument, options);
        }
export type DeliveryCountryOptionsQueryHookResult = ReturnType<typeof useDeliveryCountryOptionsQuery>;
export type DeliveryCountryOptionsLazyQueryHookResult = ReturnType<typeof useDeliveryCountryOptionsLazyQuery>;
export type DeliveryCountryOptionsQueryResult = Apollo.QueryResult<DeliveryCountryOptionsQuery, DeliveryCountryOptionsQueryVariables>;
export const UpdateCurrentUserLanguageDocument = gql`
    mutation UpdateCurrentUserLanguage($input: UpdateCurrentUserInput!) {
  updateCurrentUser(input: $input) {
    success
    user {
      id
      locale
    }
  }
}
    `;
export type UpdateCurrentUserLanguageMutationFn = Apollo.MutationFunction<UpdateCurrentUserLanguageMutation, UpdateCurrentUserLanguageMutationVariables>;

/**
 * __useUpdateCurrentUserLanguageMutation__
 *
 * To run a mutation, you first call `useUpdateCurrentUserLanguageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCurrentUserLanguageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCurrentUserLanguageMutation, { data, loading, error }] = useUpdateCurrentUserLanguageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCurrentUserLanguageMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCurrentUserLanguageMutation, UpdateCurrentUserLanguageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCurrentUserLanguageMutation, UpdateCurrentUserLanguageMutationVariables>(UpdateCurrentUserLanguageDocument, options);
      }
export type UpdateCurrentUserLanguageMutationHookResult = ReturnType<typeof useUpdateCurrentUserLanguageMutation>;
export type UpdateCurrentUserLanguageMutationResult = Apollo.MutationResult<UpdateCurrentUserLanguageMutation>;
export type UpdateCurrentUserLanguageMutationOptions = Apollo.BaseMutationOptions<UpdateCurrentUserLanguageMutation, UpdateCurrentUserLanguageMutationVariables>;
export const ApiClientFileEditDocument = gql`
    query ApiClientFileEdit($apiClientFileId: ID!) {
  customerApiClientFile(id: $apiClientFileId) {
    id
    platformName
    fileName
    fileUrl
  }
}
    `;

/**
 * __useApiClientFileEditQuery__
 *
 * To run a query within a React component, call `useApiClientFileEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useApiClientFileEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApiClientFileEditQuery({
 *   variables: {
 *      apiClientFileId: // value for 'apiClientFileId'
 *   },
 * });
 */
export function useApiClientFileEditQuery(baseOptions: Apollo.QueryHookOptions<ApiClientFileEditQuery, ApiClientFileEditQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApiClientFileEditQuery, ApiClientFileEditQueryVariables>(ApiClientFileEditDocument, options);
      }
export function useApiClientFileEditLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApiClientFileEditQuery, ApiClientFileEditQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApiClientFileEditQuery, ApiClientFileEditQueryVariables>(ApiClientFileEditDocument, options);
        }
export type ApiClientFileEditQueryHookResult = ReturnType<typeof useApiClientFileEditQuery>;
export type ApiClientFileEditLazyQueryHookResult = ReturnType<typeof useApiClientFileEditLazyQuery>;
export type ApiClientFileEditQueryResult = Apollo.QueryResult<ApiClientFileEditQuery, ApiClientFileEditQueryVariables>;
export const UpdateApiClientFileDocument = gql`
    mutation UpdateApiClientFile($input: UpdateAPIClientFileMutationInput!) {
  updateApiClientFile(input: $input) {
    success
    errors {
      type
      message
      attribute
    }
  }
}
    `;
export type UpdateApiClientFileMutationFn = Apollo.MutationFunction<UpdateApiClientFileMutation, UpdateApiClientFileMutationVariables>;

/**
 * __useUpdateApiClientFileMutation__
 *
 * To run a mutation, you first call `useUpdateApiClientFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApiClientFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApiClientFileMutation, { data, loading, error }] = useUpdateApiClientFileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateApiClientFileMutation(baseOptions?: Apollo.MutationHookOptions<UpdateApiClientFileMutation, UpdateApiClientFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateApiClientFileMutation, UpdateApiClientFileMutationVariables>(UpdateApiClientFileDocument, options);
      }
export type UpdateApiClientFileMutationHookResult = ReturnType<typeof useUpdateApiClientFileMutation>;
export type UpdateApiClientFileMutationResult = Apollo.MutationResult<UpdateApiClientFileMutation>;
export type UpdateApiClientFileMutationOptions = Apollo.BaseMutationOptions<UpdateApiClientFileMutation, UpdateApiClientFileMutationVariables>;
export const DestroyApiClientFileDocument = gql`
    mutation DestroyApiClientFile($input: DestroyAPIClientFileMutationInput!) {
  destroyApiClientFile(input: $input) {
    success
  }
}
    `;
export type DestroyApiClientFileMutationFn = Apollo.MutationFunction<DestroyApiClientFileMutation, DestroyApiClientFileMutationVariables>;

/**
 * __useDestroyApiClientFileMutation__
 *
 * To run a mutation, you first call `useDestroyApiClientFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDestroyApiClientFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [destroyApiClientFileMutation, { data, loading, error }] = useDestroyApiClientFileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDestroyApiClientFileMutation(baseOptions?: Apollo.MutationHookOptions<DestroyApiClientFileMutation, DestroyApiClientFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DestroyApiClientFileMutation, DestroyApiClientFileMutationVariables>(DestroyApiClientFileDocument, options);
      }
export type DestroyApiClientFileMutationHookResult = ReturnType<typeof useDestroyApiClientFileMutation>;
export type DestroyApiClientFileMutationResult = Apollo.MutationResult<DestroyApiClientFileMutation>;
export type DestroyApiClientFileMutationOptions = Apollo.BaseMutationOptions<DestroyApiClientFileMutation, DestroyApiClientFileMutationVariables>;
export const ApiClientFilesListDocument = gql`
    query ApiClientFilesList($q: CustomerAPIClientFileFilter, $first: Int, $after: String) {
  customerApiClientFiles(q: $q, first: $first, after: $after) {
    nodes {
      id
      platformName
      fileName
      createdAt
    }
    totalNodes
  }
}
    `;

/**
 * __useApiClientFilesListQuery__
 *
 * To run a query within a React component, call `useApiClientFilesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useApiClientFilesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApiClientFilesListQuery({
 *   variables: {
 *      q: // value for 'q'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useApiClientFilesListQuery(baseOptions?: Apollo.QueryHookOptions<ApiClientFilesListQuery, ApiClientFilesListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApiClientFilesListQuery, ApiClientFilesListQueryVariables>(ApiClientFilesListDocument, options);
      }
export function useApiClientFilesListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApiClientFilesListQuery, ApiClientFilesListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApiClientFilesListQuery, ApiClientFilesListQueryVariables>(ApiClientFilesListDocument, options);
        }
export type ApiClientFilesListQueryHookResult = ReturnType<typeof useApiClientFilesListQuery>;
export type ApiClientFilesListLazyQueryHookResult = ReturnType<typeof useApiClientFilesListLazyQuery>;
export type ApiClientFilesListQueryResult = Apollo.QueryResult<ApiClientFilesListQuery, ApiClientFilesListQueryVariables>;
export const CreateApiClientFileDocument = gql`
    mutation CreateApiClientFile($input: CreateAPIClientFileMutationInput!) {
  createApiClientFile(input: $input) {
    success
    errors {
      type
      message
      attribute
    }
  }
}
    `;
export type CreateApiClientFileMutationFn = Apollo.MutationFunction<CreateApiClientFileMutation, CreateApiClientFileMutationVariables>;

/**
 * __useCreateApiClientFileMutation__
 *
 * To run a mutation, you first call `useCreateApiClientFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateApiClientFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createApiClientFileMutation, { data, loading, error }] = useCreateApiClientFileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateApiClientFileMutation(baseOptions?: Apollo.MutationHookOptions<CreateApiClientFileMutation, CreateApiClientFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateApiClientFileMutation, CreateApiClientFileMutationVariables>(CreateApiClientFileDocument, options);
      }
export type CreateApiClientFileMutationHookResult = ReturnType<typeof useCreateApiClientFileMutation>;
export type CreateApiClientFileMutationResult = Apollo.MutationResult<CreateApiClientFileMutation>;
export type CreateApiClientFileMutationOptions = Apollo.BaseMutationOptions<CreateApiClientFileMutation, CreateApiClientFileMutationVariables>;
export const CustomerEditDocument = gql`
    query CustomerEdit($customerId: ID!) {
  customer(id: $customerId) {
    id
    name
    country
    invoiceEmail
    contactName
    contactEmail
    contactPhone
    supportName
    supportEmail
    supportPhone
    logId
    freeformPickupAddressAllowed
    clienteleKind
  }
}
    `;

/**
 * __useCustomerEditQuery__
 *
 * To run a query within a React component, call `useCustomerEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerEditQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useCustomerEditQuery(baseOptions: Apollo.QueryHookOptions<CustomerEditQuery, CustomerEditQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomerEditQuery, CustomerEditQueryVariables>(CustomerEditDocument, options);
      }
export function useCustomerEditLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomerEditQuery, CustomerEditQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomerEditQuery, CustomerEditQueryVariables>(CustomerEditDocument, options);
        }
export type CustomerEditQueryHookResult = ReturnType<typeof useCustomerEditQuery>;
export type CustomerEditLazyQueryHookResult = ReturnType<typeof useCustomerEditLazyQuery>;
export type CustomerEditQueryResult = Apollo.QueryResult<CustomerEditQuery, CustomerEditQueryVariables>;
export const UpdateCustomerDocument = gql`
    mutation UpdateCustomer($input: UpdateCustomerMutationInput!) {
  updateCustomer(input: $input) {
    success
    errors {
      type
      message
      attribute
    }
  }
}
    `;
export type UpdateCustomerMutationFn = Apollo.MutationFunction<UpdateCustomerMutation, UpdateCustomerMutationVariables>;

/**
 * __useUpdateCustomerMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerMutation, { data, loading, error }] = useUpdateCustomerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCustomerMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCustomerMutation, UpdateCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCustomerMutation, UpdateCustomerMutationVariables>(UpdateCustomerDocument, options);
      }
export type UpdateCustomerMutationHookResult = ReturnType<typeof useUpdateCustomerMutation>;
export type UpdateCustomerMutationResult = Apollo.MutationResult<UpdateCustomerMutation>;
export type UpdateCustomerMutationOptions = Apollo.BaseMutationOptions<UpdateCustomerMutation, UpdateCustomerMutationVariables>;
export const CustomersListDocument = gql`
    query CustomersList($q: CustomerFilter, $first: Int, $after: String) {
  customers(q: $q, first: $first, after: $after) {
    nodes {
      id
      name
      country
    }
    totalNodes
  }
}
    `;

/**
 * __useCustomersListQuery__
 *
 * To run a query within a React component, call `useCustomersListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomersListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomersListQuery({
 *   variables: {
 *      q: // value for 'q'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useCustomersListQuery(baseOptions?: Apollo.QueryHookOptions<CustomersListQuery, CustomersListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomersListQuery, CustomersListQueryVariables>(CustomersListDocument, options);
      }
export function useCustomersListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomersListQuery, CustomersListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomersListQuery, CustomersListQueryVariables>(CustomersListDocument, options);
        }
export type CustomersListQueryHookResult = ReturnType<typeof useCustomersListQuery>;
export type CustomersListLazyQueryHookResult = ReturnType<typeof useCustomersListLazyQuery>;
export type CustomersListQueryResult = Apollo.QueryResult<CustomersListQuery, CustomersListQueryVariables>;
export const CreateCustomerDocument = gql`
    mutation CreateCustomer($input: CreateCustomerMutationInput!) {
  createCustomer(input: $input) {
    success
    customer {
      id
    }
  }
}
    `;
export type CreateCustomerMutationFn = Apollo.MutationFunction<CreateCustomerMutation, CreateCustomerMutationVariables>;

/**
 * __useCreateCustomerMutation__
 *
 * To run a mutation, you first call `useCreateCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomerMutation, { data, loading, error }] = useCreateCustomerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCustomerMutation(baseOptions?: Apollo.MutationHookOptions<CreateCustomerMutation, CreateCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCustomerMutation, CreateCustomerMutationVariables>(CreateCustomerDocument, options);
      }
export type CreateCustomerMutationHookResult = ReturnType<typeof useCreateCustomerMutation>;
export type CreateCustomerMutationResult = Apollo.MutationResult<CreateCustomerMutation>;
export type CreateCustomerMutationOptions = Apollo.BaseMutationOptions<CreateCustomerMutation, CreateCustomerMutationVariables>;
export const CustomerPricesDocument = gql`
    query CustomerPrices($customerId: ID!) {
  customer(id: $customerId) {
    id
    name
    deliveryCountries {
      nodes {
        id
        customerId
        country
        volumeWeightKg
        insuranceValue
        courierDeliverTime
        courierPartnerConfiguration {
          id
          name
          mode
        }
        deliveryLocationPartnerConfiguration {
          id
          name
          mode
        }
        priceRules {
          nodes {
            id
            customerId
            fixedPriceExVat
            minWeightKg
            maxWeightKg
            deliveryKind
            currency
          }
        }
      }
    }
    defaultDeliveryCountries {
      nodes {
        id
        country
        volumeWeightKg
        insuranceValue
        courierDeliverTime
        courierPartnerConfiguration {
          id
          name
          mode
        }
        deliveryLocationPartnerConfiguration {
          id
          name
          mode
        }
        priceRules {
          nodes {
            id
            fixedPriceExVat
            minWeightKg
            maxWeightKg
            deliveryKind
            currency
          }
        }
      }
    }
  }
}
    `;

/**
 * __useCustomerPricesQuery__
 *
 * To run a query within a React component, call `useCustomerPricesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerPricesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerPricesQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useCustomerPricesQuery(baseOptions: Apollo.QueryHookOptions<CustomerPricesQuery, CustomerPricesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomerPricesQuery, CustomerPricesQueryVariables>(CustomerPricesDocument, options);
      }
export function useCustomerPricesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomerPricesQuery, CustomerPricesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomerPricesQuery, CustomerPricesQueryVariables>(CustomerPricesDocument, options);
        }
export type CustomerPricesQueryHookResult = ReturnType<typeof useCustomerPricesQuery>;
export type CustomerPricesLazyQueryHookResult = ReturnType<typeof useCustomerPricesLazyQuery>;
export type CustomerPricesQueryResult = Apollo.QueryResult<CustomerPricesQuery, CustomerPricesQueryVariables>;
export const UpdateCustomerPricesDocument = gql`
    mutation UpdateCustomerPrices($input: UpdateCustomerPricesMutationInput!) {
  updateCustomerPrices(input: $input) {
    success
    errors {
      type
      message
      attribute
    }
  }
}
    `;
export type UpdateCustomerPricesMutationFn = Apollo.MutationFunction<UpdateCustomerPricesMutation, UpdateCustomerPricesMutationVariables>;

/**
 * __useUpdateCustomerPricesMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerPricesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerPricesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerPricesMutation, { data, loading, error }] = useUpdateCustomerPricesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCustomerPricesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCustomerPricesMutation, UpdateCustomerPricesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCustomerPricesMutation, UpdateCustomerPricesMutationVariables>(UpdateCustomerPricesDocument, options);
      }
export type UpdateCustomerPricesMutationHookResult = ReturnType<typeof useUpdateCustomerPricesMutation>;
export type UpdateCustomerPricesMutationResult = Apollo.MutationResult<UpdateCustomerPricesMutation>;
export type UpdateCustomerPricesMutationOptions = Apollo.BaseMutationOptions<UpdateCustomerPricesMutation, UpdateCustomerPricesMutationVariables>;
export const PartnerEditDocument = gql`
    query PartnerEdit($partnerId: ID!) {
  partnerConfiguration(id: $partnerId) {
    id
    mode
    name
    country
    partner
    minHeightCm
    minLengthCm
    minWidthCm
    minWeightKg
    maxHeightCm
    maxLengthCm
    maxWidthCm
    maxWeightKg
    maxEdgeSumCm
    courierMinHeightCm
    courierMinLengthCm
    courierMinWidthCm
    courierMinWeightKg
    courierMaxHeightCm
    courierMaxLengthCm
    courierMaxWidthCm
    courierMaxWeightKg
    courierMaxEdgeSumCm
    facilityAddress
    facilityCity
    facilityZip
    facilityCountry
    returnAddress
    returnCity
    returnZip
    returnCountry
    senderPhone
    recipientPhonePrefix
    recipientPhoneRegexp
    destinationZipRegexp
    insuranceValue
    countryCourierDefault
    countryDeliveryLocationDefault
    directions
    courierDirections
    preferAlternateTrackingNumber
  }
}
    `;

/**
 * __usePartnerEditQuery__
 *
 * To run a query within a React component, call `usePartnerEditQuery` and pass it any options that fit your needs.
 * When your component renders, `usePartnerEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePartnerEditQuery({
 *   variables: {
 *      partnerId: // value for 'partnerId'
 *   },
 * });
 */
export function usePartnerEditQuery(baseOptions: Apollo.QueryHookOptions<PartnerEditQuery, PartnerEditQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PartnerEditQuery, PartnerEditQueryVariables>(PartnerEditDocument, options);
      }
export function usePartnerEditLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PartnerEditQuery, PartnerEditQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PartnerEditQuery, PartnerEditQueryVariables>(PartnerEditDocument, options);
        }
export type PartnerEditQueryHookResult = ReturnType<typeof usePartnerEditQuery>;
export type PartnerEditLazyQueryHookResult = ReturnType<typeof usePartnerEditLazyQuery>;
export type PartnerEditQueryResult = Apollo.QueryResult<PartnerEditQuery, PartnerEditQueryVariables>;
export const UpdatePartnerDocument = gql`
    mutation UpdatePartner($input: UpdatePartnerConfigurationMutationInput!) {
  updatePartnerConfiguration(input: $input) {
    success
    errors {
      type
      message
      attribute
    }
  }
}
    `;
export type UpdatePartnerMutationFn = Apollo.MutationFunction<UpdatePartnerMutation, UpdatePartnerMutationVariables>;

/**
 * __useUpdatePartnerMutation__
 *
 * To run a mutation, you first call `useUpdatePartnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePartnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePartnerMutation, { data, loading, error }] = useUpdatePartnerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePartnerMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePartnerMutation, UpdatePartnerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePartnerMutation, UpdatePartnerMutationVariables>(UpdatePartnerDocument, options);
      }
export type UpdatePartnerMutationHookResult = ReturnType<typeof useUpdatePartnerMutation>;
export type UpdatePartnerMutationResult = Apollo.MutationResult<UpdatePartnerMutation>;
export type UpdatePartnerMutationOptions = Apollo.BaseMutationOptions<UpdatePartnerMutation, UpdatePartnerMutationVariables>;
export const PartnersListDocument = gql`
    query PartnersList($q: PartnerConfigurationFilter, $first: Int, $after: String) {
  partnerConfigurations(q: $q, first: $first, after: $after) {
    nodes {
      id
      country
      name
      mode
      preferAlternateTrackingNumber
    }
    totalNodes
  }
}
    `;

/**
 * __usePartnersListQuery__
 *
 * To run a query within a React component, call `usePartnersListQuery` and pass it any options that fit your needs.
 * When your component renders, `usePartnersListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePartnersListQuery({
 *   variables: {
 *      q: // value for 'q'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function usePartnersListQuery(baseOptions?: Apollo.QueryHookOptions<PartnersListQuery, PartnersListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PartnersListQuery, PartnersListQueryVariables>(PartnersListDocument, options);
      }
export function usePartnersListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PartnersListQuery, PartnersListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PartnersListQuery, PartnersListQueryVariables>(PartnersListDocument, options);
        }
export type PartnersListQueryHookResult = ReturnType<typeof usePartnersListQuery>;
export type PartnersListLazyQueryHookResult = ReturnType<typeof usePartnersListLazyQuery>;
export type PartnersListQueryResult = Apollo.QueryResult<PartnersListQuery, PartnersListQueryVariables>;
export const DefaultPricesDocument = gql`
    query DefaultPrices($q: DefaultDeliveryCountryFilter) {
  defaultDeliveryCountries(q: $q) {
    nodes {
      id
      country
      volumeWeightKg
      insuranceValue
      courierDeliverTime
      courierPartnerConfiguration {
        id
        name
        mode
      }
      deliveryLocationPartnerConfiguration {
        id
        name
        mode
      }
      priceRules {
        nodes {
          id
          fixedPriceExVat
          minWeightKg
          maxWeightKg
          deliveryKind
          currency
        }
      }
    }
  }
}
    `;

/**
 * __useDefaultPricesQuery__
 *
 * To run a query within a React component, call `useDefaultPricesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDefaultPricesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDefaultPricesQuery({
 *   variables: {
 *      q: // value for 'q'
 *   },
 * });
 */
export function useDefaultPricesQuery(baseOptions?: Apollo.QueryHookOptions<DefaultPricesQuery, DefaultPricesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DefaultPricesQuery, DefaultPricesQueryVariables>(DefaultPricesDocument, options);
      }
export function useDefaultPricesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DefaultPricesQuery, DefaultPricesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DefaultPricesQuery, DefaultPricesQueryVariables>(DefaultPricesDocument, options);
        }
export type DefaultPricesQueryHookResult = ReturnType<typeof useDefaultPricesQuery>;
export type DefaultPricesLazyQueryHookResult = ReturnType<typeof useDefaultPricesLazyQuery>;
export type DefaultPricesQueryResult = Apollo.QueryResult<DefaultPricesQuery, DefaultPricesQueryVariables>;
export const UpdateDefaultPricesDocument = gql`
    mutation UpdateDefaultPrices($input: UpdateDefaultPricesMutationInput!) {
  updateDefaultPrices(input: $input) {
    success
    errors {
      type
      message
      attribute
    }
  }
}
    `;
export type UpdateDefaultPricesMutationFn = Apollo.MutationFunction<UpdateDefaultPricesMutation, UpdateDefaultPricesMutationVariables>;

/**
 * __useUpdateDefaultPricesMutation__
 *
 * To run a mutation, you first call `useUpdateDefaultPricesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDefaultPricesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDefaultPricesMutation, { data, loading, error }] = useUpdateDefaultPricesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDefaultPricesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDefaultPricesMutation, UpdateDefaultPricesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDefaultPricesMutation, UpdateDefaultPricesMutationVariables>(UpdateDefaultPricesDocument, options);
      }
export type UpdateDefaultPricesMutationHookResult = ReturnType<typeof useUpdateDefaultPricesMutation>;
export type UpdateDefaultPricesMutationResult = Apollo.MutationResult<UpdateDefaultPricesMutation>;
export type UpdateDefaultPricesMutationOptions = Apollo.BaseMutationOptions<UpdateDefaultPricesMutation, UpdateDefaultPricesMutationVariables>;
export const UserGlobalEditDocument = gql`
    query UserGlobalEdit($userId: ID!) {
  user(id: $userId) {
    id
    name
    email
    phone
    role(customerId: null) {
      id
      globalSettings
      primaryCountry
      active
    }
  }
}
    `;

/**
 * __useUserGlobalEditQuery__
 *
 * To run a query within a React component, call `useUserGlobalEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserGlobalEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserGlobalEditQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserGlobalEditQuery(baseOptions: Apollo.QueryHookOptions<UserGlobalEditQuery, UserGlobalEditQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserGlobalEditQuery, UserGlobalEditQueryVariables>(UserGlobalEditDocument, options);
      }
export function useUserGlobalEditLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserGlobalEditQuery, UserGlobalEditQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserGlobalEditQuery, UserGlobalEditQueryVariables>(UserGlobalEditDocument, options);
        }
export type UserGlobalEditQueryHookResult = ReturnType<typeof useUserGlobalEditQuery>;
export type UserGlobalEditLazyQueryHookResult = ReturnType<typeof useUserGlobalEditLazyQuery>;
export type UserGlobalEditQueryResult = Apollo.QueryResult<UserGlobalEditQuery, UserGlobalEditQueryVariables>;
export const UpdateGlobalUserDocument = gql`
    mutation UpdateGlobalUser($input: UpdateGlobalUserMutationInput!) {
  updateGlobalUser(input: $input) {
    success
    errors {
      type
      message
      attribute
    }
  }
}
    `;
export type UpdateGlobalUserMutationFn = Apollo.MutationFunction<UpdateGlobalUserMutation, UpdateGlobalUserMutationVariables>;

/**
 * __useUpdateGlobalUserMutation__
 *
 * To run a mutation, you first call `useUpdateGlobalUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGlobalUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGlobalUserMutation, { data, loading, error }] = useUpdateGlobalUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateGlobalUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateGlobalUserMutation, UpdateGlobalUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateGlobalUserMutation, UpdateGlobalUserMutationVariables>(UpdateGlobalUserDocument, options);
      }
export type UpdateGlobalUserMutationHookResult = ReturnType<typeof useUpdateGlobalUserMutation>;
export type UpdateGlobalUserMutationResult = Apollo.MutationResult<UpdateGlobalUserMutation>;
export type UpdateGlobalUserMutationOptions = Apollo.BaseMutationOptions<UpdateGlobalUserMutation, UpdateGlobalUserMutationVariables>;
export const CreateGlobalUserDocument = gql`
    mutation CreateGlobalUser($input: CreateGlobalUserMutationInput!) {
  createGlobalUser(input: $input) {
    success
    errors {
      type
      message
      attribute
    }
  }
}
    `;
export type CreateGlobalUserMutationFn = Apollo.MutationFunction<CreateGlobalUserMutation, CreateGlobalUserMutationVariables>;

/**
 * __useCreateGlobalUserMutation__
 *
 * To run a mutation, you first call `useCreateGlobalUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGlobalUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGlobalUserMutation, { data, loading, error }] = useCreateGlobalUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateGlobalUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateGlobalUserMutation, CreateGlobalUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateGlobalUserMutation, CreateGlobalUserMutationVariables>(CreateGlobalUserDocument, options);
      }
export type CreateGlobalUserMutationHookResult = ReturnType<typeof useCreateGlobalUserMutation>;
export type CreateGlobalUserMutationResult = Apollo.MutationResult<CreateGlobalUserMutation>;
export type CreateGlobalUserMutationOptions = Apollo.BaseMutationOptions<CreateGlobalUserMutation, CreateGlobalUserMutationVariables>;
export const UpdateCurrentUserProfileDocument = gql`
    mutation UpdateCurrentUserProfile($input: UpdateCurrentUserInput!) {
  updateCurrentUser(input: $input) {
    success
    errors {
      type
      message
      attribute
    }
    user {
      id
      name
      phone
    }
  }
}
    `;
export type UpdateCurrentUserProfileMutationFn = Apollo.MutationFunction<UpdateCurrentUserProfileMutation, UpdateCurrentUserProfileMutationVariables>;

/**
 * __useUpdateCurrentUserProfileMutation__
 *
 * To run a mutation, you first call `useUpdateCurrentUserProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCurrentUserProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCurrentUserProfileMutation, { data, loading, error }] = useUpdateCurrentUserProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCurrentUserProfileMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCurrentUserProfileMutation, UpdateCurrentUserProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCurrentUserProfileMutation, UpdateCurrentUserProfileMutationVariables>(UpdateCurrentUserProfileDocument, options);
      }
export type UpdateCurrentUserProfileMutationHookResult = ReturnType<typeof useUpdateCurrentUserProfileMutation>;
export type UpdateCurrentUserProfileMutationResult = Apollo.MutationResult<UpdateCurrentUserProfileMutation>;
export type UpdateCurrentUserProfileMutationOptions = Apollo.BaseMutationOptions<UpdateCurrentUserProfileMutation, UpdateCurrentUserProfileMutationVariables>;
export const UpdateOrderDocument = gql`
    mutation UpdateOrder($input: UpdateOrderMutationInput!) {
  updateOrder(input: $input) {
    success
    order {
      id
    }
  }
}
    `;
export type UpdateOrderMutationFn = Apollo.MutationFunction<UpdateOrderMutation, UpdateOrderMutationVariables>;

/**
 * __useUpdateOrderMutation__
 *
 * To run a mutation, you first call `useUpdateOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrderMutation, { data, loading, error }] = useUpdateOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrderMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrderMutation, UpdateOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrderMutation, UpdateOrderMutationVariables>(UpdateOrderDocument, options);
      }
export type UpdateOrderMutationHookResult = ReturnType<typeof useUpdateOrderMutation>;
export type UpdateOrderMutationResult = Apollo.MutationResult<UpdateOrderMutation>;
export type UpdateOrderMutationOptions = Apollo.BaseMutationOptions<UpdateOrderMutation, UpdateOrderMutationVariables>;
export const OrderEditDocument = gql`
    query OrderEdit($orderId: ID!) {
  order(id: $orderId) {
    id
    deliveryKind
    status
    partnerTrackingNumber
    senderReference
    senderComment
    customer {
      id
      name
    }
    pickupLocation {
      id
      name
      defaultHeightCm
      defaultLengthCm
      defaultWeightKg
      defaultWidthCm
    }
    weightKg
    lengthCm
    widthCm
    heightCm
    destinationCountry
    destinationCity
    destinationZip
    destinationAddress
    deliveryLocation {
      id
      name
      country
      city
      address
      minHeightCm
      minLengthCm
      minWeightKg
      minWidthCm
      maxHeightCm
      maxLengthCm
      maxWeightKg
      maxWidthCm
      maxEdgeSumCm
      recipientPhonePrefix
      recipientPhoneRegexp
      directions
    }
    recipientPhone
    recipientEmail
    recipientName
  }
}
    `;

/**
 * __useOrderEditQuery__
 *
 * To run a query within a React component, call `useOrderEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderEditQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useOrderEditQuery(baseOptions: Apollo.QueryHookOptions<OrderEditQuery, OrderEditQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrderEditQuery, OrderEditQueryVariables>(OrderEditDocument, options);
      }
export function useOrderEditLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrderEditQuery, OrderEditQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrderEditQuery, OrderEditQueryVariables>(OrderEditDocument, options);
        }
export type OrderEditQueryHookResult = ReturnType<typeof useOrderEditQuery>;
export type OrderEditLazyQueryHookResult = ReturnType<typeof useOrderEditLazyQuery>;
export type OrderEditQueryResult = Apollo.QueryResult<OrderEditQuery, OrderEditQueryVariables>;
export const OrderShowExternalRequestsDocument = gql`
    query OrderShowExternalRequests($orderId: ID!) {
  order(id: $orderId) {
    id
    customerId
    senderReference
    partnerTrackingNumber
    status
    externalRequests {
      id
      service
      requestMethod
      requestUrl
      requestBody
      responseCode
      responseBody
      createdAt
      updatedAt
    }
  }
}
    `;

/**
 * __useOrderShowExternalRequestsQuery__
 *
 * To run a query within a React component, call `useOrderShowExternalRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderShowExternalRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderShowExternalRequestsQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useOrderShowExternalRequestsQuery(baseOptions: Apollo.QueryHookOptions<OrderShowExternalRequestsQuery, OrderShowExternalRequestsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrderShowExternalRequestsQuery, OrderShowExternalRequestsQueryVariables>(OrderShowExternalRequestsDocument, options);
      }
export function useOrderShowExternalRequestsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrderShowExternalRequestsQuery, OrderShowExternalRequestsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrderShowExternalRequestsQuery, OrderShowExternalRequestsQueryVariables>(OrderShowExternalRequestsDocument, options);
        }
export type OrderShowExternalRequestsQueryHookResult = ReturnType<typeof useOrderShowExternalRequestsQuery>;
export type OrderShowExternalRequestsLazyQueryHookResult = ReturnType<typeof useOrderShowExternalRequestsLazyQuery>;
export type OrderShowExternalRequestsQueryResult = Apollo.QueryResult<OrderShowExternalRequestsQuery, OrderShowExternalRequestsQueryVariables>;
export const OrderShowHistoryDocument = gql`
    query OrderShowHistory($orderId: ID!) {
  order(id: $orderId) {
    id
    customerId
    senderReference
    partnerTrackingNumber
    status
    historyEvents {
      id
      event
      changes
      author
      createdAt
    }
  }
}
    `;

/**
 * __useOrderShowHistoryQuery__
 *
 * To run a query within a React component, call `useOrderShowHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderShowHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderShowHistoryQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useOrderShowHistoryQuery(baseOptions: Apollo.QueryHookOptions<OrderShowHistoryQuery, OrderShowHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrderShowHistoryQuery, OrderShowHistoryQueryVariables>(OrderShowHistoryDocument, options);
      }
export function useOrderShowHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrderShowHistoryQuery, OrderShowHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrderShowHistoryQuery, OrderShowHistoryQueryVariables>(OrderShowHistoryDocument, options);
        }
export type OrderShowHistoryQueryHookResult = ReturnType<typeof useOrderShowHistoryQuery>;
export type OrderShowHistoryLazyQueryHookResult = ReturnType<typeof useOrderShowHistoryLazyQuery>;
export type OrderShowHistoryQueryResult = Apollo.QueryResult<OrderShowHistoryQuery, OrderShowHistoryQueryVariables>;
export const OrdersListDocument = gql`
    query OrdersList($q: OrderFilter, $first: Int, $after: String) {
  orders(q: $q, first: $first, after: $after) {
    nodes {
      id
      customerId
      weightKg
      lengthCm
      widthCm
      heightCm
      deliveryKind
      destinationCountry
      destinationCity
      destinationZip
      destinationAddress
      recipientPhone
      recipientPhonePrefix
      recipientEmail
      recipientName
      senderReference
      status
      partnerTrackingNumber
      pickupLocationName
      customer {
        id
        name
      }
      createdAt
      priceExVat
      priceExVatCurrency
    }
    totalNodes
  }
}
    `;

/**
 * __useOrdersListQuery__
 *
 * To run a query within a React component, call `useOrdersListQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrdersListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrdersListQuery({
 *   variables: {
 *      q: // value for 'q'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useOrdersListQuery(baseOptions?: Apollo.QueryHookOptions<OrdersListQuery, OrdersListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrdersListQuery, OrdersListQueryVariables>(OrdersListDocument, options);
      }
export function useOrdersListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrdersListQuery, OrdersListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrdersListQuery, OrdersListQueryVariables>(OrdersListDocument, options);
        }
export type OrdersListQueryHookResult = ReturnType<typeof useOrdersListQuery>;
export type OrdersListLazyQueryHookResult = ReturnType<typeof useOrdersListLazyQuery>;
export type OrdersListQueryResult = Apollo.QueryResult<OrdersListQuery, OrdersListQueryVariables>;
export const UpdateOrderReadyStateDocument = gql`
    mutation UpdateOrderReadyState($input: UpdateOrderReadyStateInput!) {
  updateOrderReadyState(input: $input) {
    success
    orders {
      id
      status
    }
  }
}
    `;
export type UpdateOrderReadyStateMutationFn = Apollo.MutationFunction<UpdateOrderReadyStateMutation, UpdateOrderReadyStateMutationVariables>;

/**
 * __useUpdateOrderReadyStateMutation__
 *
 * To run a mutation, you first call `useUpdateOrderReadyStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrderReadyStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrderReadyStateMutation, { data, loading, error }] = useUpdateOrderReadyStateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrderReadyStateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrderReadyStateMutation, UpdateOrderReadyStateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrderReadyStateMutation, UpdateOrderReadyStateMutationVariables>(UpdateOrderReadyStateDocument, options);
      }
export type UpdateOrderReadyStateMutationHookResult = ReturnType<typeof useUpdateOrderReadyStateMutation>;
export type UpdateOrderReadyStateMutationResult = Apollo.MutationResult<UpdateOrderReadyStateMutation>;
export type UpdateOrderReadyStateMutationOptions = Apollo.BaseMutationOptions<UpdateOrderReadyStateMutation, UpdateOrderReadyStateMutationVariables>;
export const NewOrderPickupLocationsDocument = gql`
    query NewOrderPickupLocations($q: PickupLocationFilter) {
  pickupLocations(q: $q) {
    nodes {
      id
      name
      defaultHeightCm
      defaultLengthCm
      defaultWeightKg
      defaultWidthCm
    }
  }
}
    `;

/**
 * __useNewOrderPickupLocationsQuery__
 *
 * To run a query within a React component, call `useNewOrderPickupLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewOrderPickupLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewOrderPickupLocationsQuery({
 *   variables: {
 *      q: // value for 'q'
 *   },
 * });
 */
export function useNewOrderPickupLocationsQuery(baseOptions?: Apollo.QueryHookOptions<NewOrderPickupLocationsQuery, NewOrderPickupLocationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NewOrderPickupLocationsQuery, NewOrderPickupLocationsQueryVariables>(NewOrderPickupLocationsDocument, options);
      }
export function useNewOrderPickupLocationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NewOrderPickupLocationsQuery, NewOrderPickupLocationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NewOrderPickupLocationsQuery, NewOrderPickupLocationsQueryVariables>(NewOrderPickupLocationsDocument, options);
        }
export type NewOrderPickupLocationsQueryHookResult = ReturnType<typeof useNewOrderPickupLocationsQuery>;
export type NewOrderPickupLocationsLazyQueryHookResult = ReturnType<typeof useNewOrderPickupLocationsLazyQuery>;
export type NewOrderPickupLocationsQueryResult = Apollo.QueryResult<NewOrderPickupLocationsQuery, NewOrderPickupLocationsQueryVariables>;
export const NewOrderCitiesDocument = gql`
    query NewOrderCities($q: DeliveryLocationFilter!) {
  deliveryLocationCities(q: $q)
}
    `;

/**
 * __useNewOrderCitiesQuery__
 *
 * To run a query within a React component, call `useNewOrderCitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewOrderCitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewOrderCitiesQuery({
 *   variables: {
 *      q: // value for 'q'
 *   },
 * });
 */
export function useNewOrderCitiesQuery(baseOptions: Apollo.QueryHookOptions<NewOrderCitiesQuery, NewOrderCitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NewOrderCitiesQuery, NewOrderCitiesQueryVariables>(NewOrderCitiesDocument, options);
      }
export function useNewOrderCitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NewOrderCitiesQuery, NewOrderCitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NewOrderCitiesQuery, NewOrderCitiesQueryVariables>(NewOrderCitiesDocument, options);
        }
export type NewOrderCitiesQueryHookResult = ReturnType<typeof useNewOrderCitiesQuery>;
export type NewOrderCitiesLazyQueryHookResult = ReturnType<typeof useNewOrderCitiesLazyQuery>;
export type NewOrderCitiesQueryResult = Apollo.QueryResult<NewOrderCitiesQuery, NewOrderCitiesQueryVariables>;
export const NewOrderDeliveryLocationsDocument = gql`
    query NewOrderDeliveryLocations($q: DeliveryLocationFilter) {
  deliveryLocations(q: $q) {
    nodes {
      id
      name
      address
      minHeightCm
      minLengthCm
      minWeightKg
      minWidthCm
      maxHeightCm
      maxLengthCm
      maxWeightKg
      maxWidthCm
      maxEdgeSumCm
      recipientPhonePrefix
      recipientPhoneRegexp
      directions
    }
  }
}
    `;

/**
 * __useNewOrderDeliveryLocationsQuery__
 *
 * To run a query within a React component, call `useNewOrderDeliveryLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewOrderDeliveryLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewOrderDeliveryLocationsQuery({
 *   variables: {
 *      q: // value for 'q'
 *   },
 * });
 */
export function useNewOrderDeliveryLocationsQuery(baseOptions?: Apollo.QueryHookOptions<NewOrderDeliveryLocationsQuery, NewOrderDeliveryLocationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NewOrderDeliveryLocationsQuery, NewOrderDeliveryLocationsQueryVariables>(NewOrderDeliveryLocationsDocument, options);
      }
export function useNewOrderDeliveryLocationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NewOrderDeliveryLocationsQuery, NewOrderDeliveryLocationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NewOrderDeliveryLocationsQuery, NewOrderDeliveryLocationsQueryVariables>(NewOrderDeliveryLocationsDocument, options);
        }
export type NewOrderDeliveryLocationsQueryHookResult = ReturnType<typeof useNewOrderDeliveryLocationsQuery>;
export type NewOrderDeliveryLocationsLazyQueryHookResult = ReturnType<typeof useNewOrderDeliveryLocationsLazyQuery>;
export type NewOrderDeliveryLocationsQueryResult = Apollo.QueryResult<NewOrderDeliveryLocationsQuery, NewOrderDeliveryLocationsQueryVariables>;
export const NewOrderVolumeWeightDocument = gql`
    query NewOrderVolumeWeight($q: CustomerDeliveryCountryFilter) {
  customerDeliveryCountries(q: $q, first: 1) {
    nodes {
      id
      volumeWeightKg
    }
  }
}
    `;

/**
 * __useNewOrderVolumeWeightQuery__
 *
 * To run a query within a React component, call `useNewOrderVolumeWeightQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewOrderVolumeWeightQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewOrderVolumeWeightQuery({
 *   variables: {
 *      q: // value for 'q'
 *   },
 * });
 */
export function useNewOrderVolumeWeightQuery(baseOptions?: Apollo.QueryHookOptions<NewOrderVolumeWeightQuery, NewOrderVolumeWeightQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NewOrderVolumeWeightQuery, NewOrderVolumeWeightQueryVariables>(NewOrderVolumeWeightDocument, options);
      }
export function useNewOrderVolumeWeightLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NewOrderVolumeWeightQuery, NewOrderVolumeWeightQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NewOrderVolumeWeightQuery, NewOrderVolumeWeightQueryVariables>(NewOrderVolumeWeightDocument, options);
        }
export type NewOrderVolumeWeightQueryHookResult = ReturnType<typeof useNewOrderVolumeWeightQuery>;
export type NewOrderVolumeWeightLazyQueryHookResult = ReturnType<typeof useNewOrderVolumeWeightLazyQuery>;
export type NewOrderVolumeWeightQueryResult = Apollo.QueryResult<NewOrderVolumeWeightQuery, NewOrderVolumeWeightQueryVariables>;
export const CreateOrderDocument = gql`
    mutation CreateOrder($input: CreateOrderMutationInput!) {
  createOrder(input: $input) {
    success
    order {
      id
    }
  }
}
    `;
export type CreateOrderMutationFn = Apollo.MutationFunction<CreateOrderMutation, CreateOrderMutationVariables>;

/**
 * __useCreateOrderMutation__
 *
 * To run a mutation, you first call `useCreateOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrderMutation, { data, loading, error }] = useCreateOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrderMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrderMutation, CreateOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrderMutation, CreateOrderMutationVariables>(CreateOrderDocument, options);
      }
export type CreateOrderMutationHookResult = ReturnType<typeof useCreateOrderMutation>;
export type CreateOrderMutationResult = Apollo.MutationResult<CreateOrderMutation>;
export type CreateOrderMutationOptions = Apollo.BaseMutationOptions<CreateOrderMutation, CreateOrderMutationVariables>;
export const NewOrderSourceOrderDocument = gql`
    query NewOrderSourceOrder($sourceOrderId: ID!) {
  order(id: $sourceOrderId) {
    id
    deliveryKind
    customer {
      id
      name
    }
    pickupLocation {
      id
      name
      defaultHeightCm
      defaultLengthCm
      defaultWeightKg
      defaultWidthCm
    }
    weightKg
    lengthCm
    widthCm
    heightCm
    destinationCountry
    destinationCity
    destinationZip
    destinationAddress
    deliveryLocation {
      id
      name
      country
      city
      address
      minHeightCm
      minLengthCm
      minWeightKg
      minWidthCm
      maxHeightCm
      maxLengthCm
      maxWeightKg
      maxWidthCm
      maxEdgeSumCm
      recipientPhonePrefix
      recipientPhoneRegexp
      directions
    }
    recipientPhone
    recipientEmail
    recipientName
  }
}
    `;

/**
 * __useNewOrderSourceOrderQuery__
 *
 * To run a query within a React component, call `useNewOrderSourceOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewOrderSourceOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewOrderSourceOrderQuery({
 *   variables: {
 *      sourceOrderId: // value for 'sourceOrderId'
 *   },
 * });
 */
export function useNewOrderSourceOrderQuery(baseOptions: Apollo.QueryHookOptions<NewOrderSourceOrderQuery, NewOrderSourceOrderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NewOrderSourceOrderQuery, NewOrderSourceOrderQueryVariables>(NewOrderSourceOrderDocument, options);
      }
export function useNewOrderSourceOrderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NewOrderSourceOrderQuery, NewOrderSourceOrderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NewOrderSourceOrderQuery, NewOrderSourceOrderQueryVariables>(NewOrderSourceOrderDocument, options);
        }
export type NewOrderSourceOrderQueryHookResult = ReturnType<typeof useNewOrderSourceOrderQuery>;
export type NewOrderSourceOrderLazyQueryHookResult = ReturnType<typeof useNewOrderSourceOrderLazyQuery>;
export type NewOrderSourceOrderQueryResult = Apollo.QueryResult<NewOrderSourceOrderQuery, NewOrderSourceOrderQueryVariables>;
export const NewOrderHistoryListDocument = gql`
    query NewOrderHistoryList($q: OrderFilter) {
  orders(q: $q, first: 5) {
    nodes {
      id
      recipientName
      partnerTrackingNumber
      createdAt
    }
  }
}
    `;

/**
 * __useNewOrderHistoryListQuery__
 *
 * To run a query within a React component, call `useNewOrderHistoryListQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewOrderHistoryListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewOrderHistoryListQuery({
 *   variables: {
 *      q: // value for 'q'
 *   },
 * });
 */
export function useNewOrderHistoryListQuery(baseOptions?: Apollo.QueryHookOptions<NewOrderHistoryListQuery, NewOrderHistoryListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NewOrderHistoryListQuery, NewOrderHistoryListQueryVariables>(NewOrderHistoryListDocument, options);
      }
export function useNewOrderHistoryListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NewOrderHistoryListQuery, NewOrderHistoryListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NewOrderHistoryListQuery, NewOrderHistoryListQueryVariables>(NewOrderHistoryListDocument, options);
        }
export type NewOrderHistoryListQueryHookResult = ReturnType<typeof useNewOrderHistoryListQuery>;
export type NewOrderHistoryListLazyQueryHookResult = ReturnType<typeof useNewOrderHistoryListLazyQuery>;
export type NewOrderHistoryListQueryResult = Apollo.QueryResult<NewOrderHistoryListQuery, NewOrderHistoryListQueryVariables>;
export const CourierOrdersCustomerDeliveryCountryDocument = gql`
    query CourierOrdersCustomerDeliveryCountry($q: CustomerDeliveryCountryFilter) {
  customerDeliveryCountries(q: $q, first: 1) {
    nodes {
      id
      courierMinHeightCm
      courierMinLengthCm
      courierMinWidthCm
      courierMinWeightKg
      courierMaxHeightCm
      courierMaxLengthCm
      courierMaxWidthCm
      courierMaxWeightKg
      courierMaxEdgeSumCm
      courierRecipientPhoneRegexp
      courierRecipientPhonePrefix
      courierDestinationZipRegexp
      courierDirections
    }
  }
}
    `;

/**
 * __useCourierOrdersCustomerDeliveryCountryQuery__
 *
 * To run a query within a React component, call `useCourierOrdersCustomerDeliveryCountryQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourierOrdersCustomerDeliveryCountryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourierOrdersCustomerDeliveryCountryQuery({
 *   variables: {
 *      q: // value for 'q'
 *   },
 * });
 */
export function useCourierOrdersCustomerDeliveryCountryQuery(baseOptions?: Apollo.QueryHookOptions<CourierOrdersCustomerDeliveryCountryQuery, CourierOrdersCustomerDeliveryCountryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CourierOrdersCustomerDeliveryCountryQuery, CourierOrdersCustomerDeliveryCountryQueryVariables>(CourierOrdersCustomerDeliveryCountryDocument, options);
      }
export function useCourierOrdersCustomerDeliveryCountryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CourierOrdersCustomerDeliveryCountryQuery, CourierOrdersCustomerDeliveryCountryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CourierOrdersCustomerDeliveryCountryQuery, CourierOrdersCustomerDeliveryCountryQueryVariables>(CourierOrdersCustomerDeliveryCountryDocument, options);
        }
export type CourierOrdersCustomerDeliveryCountryQueryHookResult = ReturnType<typeof useCourierOrdersCustomerDeliveryCountryQuery>;
export type CourierOrdersCustomerDeliveryCountryLazyQueryHookResult = ReturnType<typeof useCourierOrdersCustomerDeliveryCountryLazyQuery>;
export type CourierOrdersCustomerDeliveryCountryQueryResult = Apollo.QueryResult<CourierOrdersCustomerDeliveryCountryQuery, CourierOrdersCustomerDeliveryCountryQueryVariables>;
export const CourierCustomerDeliveryCountriesDocument = gql`
    query courierCustomerDeliveryCountries($q: CustomerDeliveryCountryFilter) {
  courierDeliveryLocationCountries(q: $q)
}
    `;

/**
 * __useCourierCustomerDeliveryCountriesQuery__
 *
 * To run a query within a React component, call `useCourierCustomerDeliveryCountriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourierCustomerDeliveryCountriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourierCustomerDeliveryCountriesQuery({
 *   variables: {
 *      q: // value for 'q'
 *   },
 * });
 */
export function useCourierCustomerDeliveryCountriesQuery(baseOptions?: Apollo.QueryHookOptions<CourierCustomerDeliveryCountriesQuery, CourierCustomerDeliveryCountriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CourierCustomerDeliveryCountriesQuery, CourierCustomerDeliveryCountriesQueryVariables>(CourierCustomerDeliveryCountriesDocument, options);
      }
export function useCourierCustomerDeliveryCountriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CourierCustomerDeliveryCountriesQuery, CourierCustomerDeliveryCountriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CourierCustomerDeliveryCountriesQuery, CourierCustomerDeliveryCountriesQueryVariables>(CourierCustomerDeliveryCountriesDocument, options);
        }
export type CourierCustomerDeliveryCountriesQueryHookResult = ReturnType<typeof useCourierCustomerDeliveryCountriesQuery>;
export type CourierCustomerDeliveryCountriesLazyQueryHookResult = ReturnType<typeof useCourierCustomerDeliveryCountriesLazyQuery>;
export type CourierCustomerDeliveryCountriesQueryResult = Apollo.QueryResult<CourierCustomerDeliveryCountriesQuery, CourierCustomerDeliveryCountriesQueryVariables>;
export const OrderShowDocument = gql`
    query OrderShow($orderId: ID!) {
  order(id: $orderId) {
    id
    customerId
    createdAt
    updatedAt
    weightKg
    lengthCm
    widthCm
    heightCm
    destinationCountry
    destinationCity
    destinationZip
    destinationAddress
    recipientPhone
    recipientPhonePrefix
    recipientEmail
    recipientName
    senderReference
    senderComment
    status
    partnerTrackingNumber
    partnerAlternateTrackingNumber
    pickupLocationCountry
    pickupLocationCity
    pickupLocationZip
    pickupLocationAddress
    pickupLocationName
    trackingUrl
    partnerTrackingUrl
    trackingEmailSentAt
    partnerTrackingEmailSentAt
    expectedPickupAt
    deliveryKind
    priceExVat
    priceExVatCurrency
  }
}
    `;

/**
 * __useOrderShowQuery__
 *
 * To run a query within a React component, call `useOrderShowQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderShowQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderShowQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useOrderShowQuery(baseOptions: Apollo.QueryHookOptions<OrderShowQuery, OrderShowQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrderShowQuery, OrderShowQueryVariables>(OrderShowDocument, options);
      }
export function useOrderShowLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrderShowQuery, OrderShowQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrderShowQuery, OrderShowQueryVariables>(OrderShowDocument, options);
        }
export type OrderShowQueryHookResult = ReturnType<typeof useOrderShowQuery>;
export type OrderShowLazyQueryHookResult = ReturnType<typeof useOrderShowLazyQuery>;
export type OrderShowQueryResult = Apollo.QueryResult<OrderShowQuery, OrderShowQueryVariables>;
export const CancelOrderDocument = gql`
    mutation CancelOrder($input: CancelOrderMutationInput!) {
  cancelOrder(input: $input) {
    success
    order {
      id
      status
    }
  }
}
    `;
export type CancelOrderMutationFn = Apollo.MutationFunction<CancelOrderMutation, CancelOrderMutationVariables>;

/**
 * __useCancelOrderMutation__
 *
 * To run a mutation, you first call `useCancelOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelOrderMutation, { data, loading, error }] = useCancelOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelOrderMutation(baseOptions?: Apollo.MutationHookOptions<CancelOrderMutation, CancelOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelOrderMutation, CancelOrderMutationVariables>(CancelOrderDocument, options);
      }
export type CancelOrderMutationHookResult = ReturnType<typeof useCancelOrderMutation>;
export type CancelOrderMutationResult = Apollo.MutationResult<CancelOrderMutation>;
export type CancelOrderMutationOptions = Apollo.BaseMutationOptions<CancelOrderMutation, CancelOrderMutationVariables>;
export const SendOrderTrackingEmailDocument = gql`
    mutation SendOrderTrackingEmail($input: SendOrderTrackingEmailMutationInput!) {
  sendOrderTrackingEmail(input: $input) {
    success
    order {
      id
      trackingEmailSentAt
      partnerTrackingEmailSentAt
    }
  }
}
    `;
export type SendOrderTrackingEmailMutationFn = Apollo.MutationFunction<SendOrderTrackingEmailMutation, SendOrderTrackingEmailMutationVariables>;

/**
 * __useSendOrderTrackingEmailMutation__
 *
 * To run a mutation, you first call `useSendOrderTrackingEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendOrderTrackingEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendOrderTrackingEmailMutation, { data, loading, error }] = useSendOrderTrackingEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendOrderTrackingEmailMutation(baseOptions?: Apollo.MutationHookOptions<SendOrderTrackingEmailMutation, SendOrderTrackingEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendOrderTrackingEmailMutation, SendOrderTrackingEmailMutationVariables>(SendOrderTrackingEmailDocument, options);
      }
export type SendOrderTrackingEmailMutationHookResult = ReturnType<typeof useSendOrderTrackingEmailMutation>;
export type SendOrderTrackingEmailMutationResult = Apollo.MutationResult<SendOrderTrackingEmailMutation>;
export type SendOrderTrackingEmailMutationOptions = Apollo.BaseMutationOptions<SendOrderTrackingEmailMutation, SendOrderTrackingEmailMutationVariables>;
export const OrderShowTrackingDocument = gql`
    query OrderShowTracking($orderId: ID!) {
  order(id: $orderId) {
    id
    customerId
    senderReference
    partnerTrackingNumber
    status
    trackingEvents {
      id
      event
      aliasedEvent
      location
      timestamp
      additionalData
    }
  }
}
    `;

/**
 * __useOrderShowTrackingQuery__
 *
 * To run a query within a React component, call `useOrderShowTrackingQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderShowTrackingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderShowTrackingQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useOrderShowTrackingQuery(baseOptions: Apollo.QueryHookOptions<OrderShowTrackingQuery, OrderShowTrackingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrderShowTrackingQuery, OrderShowTrackingQueryVariables>(OrderShowTrackingDocument, options);
      }
export function useOrderShowTrackingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrderShowTrackingQuery, OrderShowTrackingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrderShowTrackingQuery, OrderShowTrackingQueryVariables>(OrderShowTrackingDocument, options);
        }
export type OrderShowTrackingQueryHookResult = ReturnType<typeof useOrderShowTrackingQuery>;
export type OrderShowTrackingLazyQueryHookResult = ReturnType<typeof useOrderShowTrackingLazyQuery>;
export type OrderShowTrackingQueryResult = Apollo.QueryResult<OrderShowTrackingQuery, OrderShowTrackingQueryVariables>;
export const CustomerApiClientFilesListDocument = gql`
    query CustomerApiClientFilesList($q: CustomerAPIClientFileFilter) {
  customerApiClientFiles(q: $q) {
    nodes {
      id
      platformName
      fileName
      fileUrl
    }
  }
}
    `;

/**
 * __useCustomerApiClientFilesListQuery__
 *
 * To run a query within a React component, call `useCustomerApiClientFilesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerApiClientFilesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerApiClientFilesListQuery({
 *   variables: {
 *      q: // value for 'q'
 *   },
 * });
 */
export function useCustomerApiClientFilesListQuery(baseOptions?: Apollo.QueryHookOptions<CustomerApiClientFilesListQuery, CustomerApiClientFilesListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomerApiClientFilesListQuery, CustomerApiClientFilesListQueryVariables>(CustomerApiClientFilesListDocument, options);
      }
export function useCustomerApiClientFilesListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomerApiClientFilesListQuery, CustomerApiClientFilesListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomerApiClientFilesListQuery, CustomerApiClientFilesListQueryVariables>(CustomerApiClientFilesListDocument, options);
        }
export type CustomerApiClientFilesListQueryHookResult = ReturnType<typeof useCustomerApiClientFilesListQuery>;
export type CustomerApiClientFilesListLazyQueryHookResult = ReturnType<typeof useCustomerApiClientFilesListLazyQuery>;
export type CustomerApiClientFilesListQueryResult = Apollo.QueryResult<CustomerApiClientFilesListQuery, CustomerApiClientFilesListQueryVariables>;
export const ApiTokensListDocument = gql`
    query ApiTokensList($q: CustomerAPITokenFilter, $first: Int, $after: String) {
  customerApiTokens(q: $q, first: $first, after: $after) {
    nodes {
      id
      customerId
      name
      createdAt
      accessedAt
    }
    totalNodes
  }
}
    `;

/**
 * __useApiTokensListQuery__
 *
 * To run a query within a React component, call `useApiTokensListQuery` and pass it any options that fit your needs.
 * When your component renders, `useApiTokensListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApiTokensListQuery({
 *   variables: {
 *      q: // value for 'q'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useApiTokensListQuery(baseOptions?: Apollo.QueryHookOptions<ApiTokensListQuery, ApiTokensListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApiTokensListQuery, ApiTokensListQueryVariables>(ApiTokensListDocument, options);
      }
export function useApiTokensListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApiTokensListQuery, ApiTokensListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApiTokensListQuery, ApiTokensListQueryVariables>(ApiTokensListDocument, options);
        }
export type ApiTokensListQueryHookResult = ReturnType<typeof useApiTokensListQuery>;
export type ApiTokensListLazyQueryHookResult = ReturnType<typeof useApiTokensListLazyQuery>;
export type ApiTokensListQueryResult = Apollo.QueryResult<ApiTokensListQuery, ApiTokensListQueryVariables>;
export const CustomerContactsDocument = gql`
    query CustomerContacts($id: ID!) {
  customer(id: $id) {
    id
    invoiceEmail
    contactName
    contactEmail
    contactPhone
    supportName
    supportEmail
    supportPhone
  }
}
    `;

/**
 * __useCustomerContactsQuery__
 *
 * To run a query within a React component, call `useCustomerContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerContactsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCustomerContactsQuery(baseOptions: Apollo.QueryHookOptions<CustomerContactsQuery, CustomerContactsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomerContactsQuery, CustomerContactsQueryVariables>(CustomerContactsDocument, options);
      }
export function useCustomerContactsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomerContactsQuery, CustomerContactsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomerContactsQuery, CustomerContactsQueryVariables>(CustomerContactsDocument, options);
        }
export type CustomerContactsQueryHookResult = ReturnType<typeof useCustomerContactsQuery>;
export type CustomerContactsLazyQueryHookResult = ReturnType<typeof useCustomerContactsLazyQuery>;
export type CustomerContactsQueryResult = Apollo.QueryResult<CustomerContactsQuery, CustomerContactsQueryVariables>;
export const PickupLocationEditDocument = gql`
    query PickupLocationEdit($pickupLocationId: ID!) {
  pickupLocation(id: $pickupLocationId) {
    id
    customerId
    name
    country
    city
    address
    zip
    email
    phone
    defaultHeightCm
    defaultLengthCm
    defaultWeightKg
    defaultWidthCm
    active
  }
}
    `;

/**
 * __usePickupLocationEditQuery__
 *
 * To run a query within a React component, call `usePickupLocationEditQuery` and pass it any options that fit your needs.
 * When your component renders, `usePickupLocationEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePickupLocationEditQuery({
 *   variables: {
 *      pickupLocationId: // value for 'pickupLocationId'
 *   },
 * });
 */
export function usePickupLocationEditQuery(baseOptions: Apollo.QueryHookOptions<PickupLocationEditQuery, PickupLocationEditQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PickupLocationEditQuery, PickupLocationEditQueryVariables>(PickupLocationEditDocument, options);
      }
export function usePickupLocationEditLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PickupLocationEditQuery, PickupLocationEditQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PickupLocationEditQuery, PickupLocationEditQueryVariables>(PickupLocationEditDocument, options);
        }
export type PickupLocationEditQueryHookResult = ReturnType<typeof usePickupLocationEditQuery>;
export type PickupLocationEditLazyQueryHookResult = ReturnType<typeof usePickupLocationEditLazyQuery>;
export type PickupLocationEditQueryResult = Apollo.QueryResult<PickupLocationEditQuery, PickupLocationEditQueryVariables>;
export const UpdatePickupLocationDocument = gql`
    mutation UpdatePickupLocation($input: UpdatePickupLocationMutationInput!) {
  updatePickupLocation(input: $input) {
    success
    errors {
      type
      message
      attribute
    }
  }
}
    `;
export type UpdatePickupLocationMutationFn = Apollo.MutationFunction<UpdatePickupLocationMutation, UpdatePickupLocationMutationVariables>;

/**
 * __useUpdatePickupLocationMutation__
 *
 * To run a mutation, you first call `useUpdatePickupLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePickupLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePickupLocationMutation, { data, loading, error }] = useUpdatePickupLocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePickupLocationMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePickupLocationMutation, UpdatePickupLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePickupLocationMutation, UpdatePickupLocationMutationVariables>(UpdatePickupLocationDocument, options);
      }
export type UpdatePickupLocationMutationHookResult = ReturnType<typeof useUpdatePickupLocationMutation>;
export type UpdatePickupLocationMutationResult = Apollo.MutationResult<UpdatePickupLocationMutation>;
export type UpdatePickupLocationMutationOptions = Apollo.BaseMutationOptions<UpdatePickupLocationMutation, UpdatePickupLocationMutationVariables>;
export const PickupLocationsListDocument = gql`
    query PickupLocationsList($q: PickupLocationFilter, $first: Int, $after: String) {
  pickupLocations(q: $q, first: $first, after: $after) {
    nodes {
      id
      customerId
      name
      country
      city
      address
      email
      phone
      active
    }
    totalNodes
  }
}
    `;

/**
 * __usePickupLocationsListQuery__
 *
 * To run a query within a React component, call `usePickupLocationsListQuery` and pass it any options that fit your needs.
 * When your component renders, `usePickupLocationsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePickupLocationsListQuery({
 *   variables: {
 *      q: // value for 'q'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function usePickupLocationsListQuery(baseOptions?: Apollo.QueryHookOptions<PickupLocationsListQuery, PickupLocationsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PickupLocationsListQuery, PickupLocationsListQueryVariables>(PickupLocationsListDocument, options);
      }
export function usePickupLocationsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PickupLocationsListQuery, PickupLocationsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PickupLocationsListQuery, PickupLocationsListQueryVariables>(PickupLocationsListDocument, options);
        }
export type PickupLocationsListQueryHookResult = ReturnType<typeof usePickupLocationsListQuery>;
export type PickupLocationsListLazyQueryHookResult = ReturnType<typeof usePickupLocationsListLazyQuery>;
export type PickupLocationsListQueryResult = Apollo.QueryResult<PickupLocationsListQuery, PickupLocationsListQueryVariables>;
export const UpdatePickupLocationActiveStateDocument = gql`
    mutation UpdatePickupLocationActiveState($input: UpdatePickupLocationActiveStateMutationInput!) {
  updatePickupLocationActiveState(input: $input) {
    success
    pickupLocation {
      id
      active
    }
  }
}
    `;
export type UpdatePickupLocationActiveStateMutationFn = Apollo.MutationFunction<UpdatePickupLocationActiveStateMutation, UpdatePickupLocationActiveStateMutationVariables>;

/**
 * __useUpdatePickupLocationActiveStateMutation__
 *
 * To run a mutation, you first call `useUpdatePickupLocationActiveStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePickupLocationActiveStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePickupLocationActiveStateMutation, { data, loading, error }] = useUpdatePickupLocationActiveStateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePickupLocationActiveStateMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePickupLocationActiveStateMutation, UpdatePickupLocationActiveStateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePickupLocationActiveStateMutation, UpdatePickupLocationActiveStateMutationVariables>(UpdatePickupLocationActiveStateDocument, options);
      }
export type UpdatePickupLocationActiveStateMutationHookResult = ReturnType<typeof useUpdatePickupLocationActiveStateMutation>;
export type UpdatePickupLocationActiveStateMutationResult = Apollo.MutationResult<UpdatePickupLocationActiveStateMutation>;
export type UpdatePickupLocationActiveStateMutationOptions = Apollo.BaseMutationOptions<UpdatePickupLocationActiveStateMutation, UpdatePickupLocationActiveStateMutationVariables>;
export const CreatePickupLocationDocument = gql`
    mutation CreatePickupLocation($input: CreatePickupLocationMutationInput!) {
  createPickupLocation(input: $input) {
    success
    errors {
      type
      message
      attribute
    }
  }
}
    `;
export type CreatePickupLocationMutationFn = Apollo.MutationFunction<CreatePickupLocationMutation, CreatePickupLocationMutationVariables>;

/**
 * __useCreatePickupLocationMutation__
 *
 * To run a mutation, you first call `useCreatePickupLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePickupLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPickupLocationMutation, { data, loading, error }] = useCreatePickupLocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePickupLocationMutation(baseOptions?: Apollo.MutationHookOptions<CreatePickupLocationMutation, CreatePickupLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePickupLocationMutation, CreatePickupLocationMutationVariables>(CreatePickupLocationDocument, options);
      }
export type CreatePickupLocationMutationHookResult = ReturnType<typeof useCreatePickupLocationMutation>;
export type CreatePickupLocationMutationResult = Apollo.MutationResult<CreatePickupLocationMutation>;
export type CreatePickupLocationMutationOptions = Apollo.BaseMutationOptions<CreatePickupLocationMutation, CreatePickupLocationMutationVariables>;
export const PickupScheduleEditDocument = gql`
    query PickupScheduleEdit($pickupScheduleId: ID!) {
  pickupSchedule(id: $pickupScheduleId) {
    id
    pickupLocation {
      id
      name
    }
    monday
    tuesday
    wednesday
    thursday
    friday
    saturday
    sunday
    pickupFrom
    pickupTo
  }
}
    `;

/**
 * __usePickupScheduleEditQuery__
 *
 * To run a query within a React component, call `usePickupScheduleEditQuery` and pass it any options that fit your needs.
 * When your component renders, `usePickupScheduleEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePickupScheduleEditQuery({
 *   variables: {
 *      pickupScheduleId: // value for 'pickupScheduleId'
 *   },
 * });
 */
export function usePickupScheduleEditQuery(baseOptions: Apollo.QueryHookOptions<PickupScheduleEditQuery, PickupScheduleEditQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PickupScheduleEditQuery, PickupScheduleEditQueryVariables>(PickupScheduleEditDocument, options);
      }
export function usePickupScheduleEditLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PickupScheduleEditQuery, PickupScheduleEditQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PickupScheduleEditQuery, PickupScheduleEditQueryVariables>(PickupScheduleEditDocument, options);
        }
export type PickupScheduleEditQueryHookResult = ReturnType<typeof usePickupScheduleEditQuery>;
export type PickupScheduleEditLazyQueryHookResult = ReturnType<typeof usePickupScheduleEditLazyQuery>;
export type PickupScheduleEditQueryResult = Apollo.QueryResult<PickupScheduleEditQuery, PickupScheduleEditQueryVariables>;
export const UpdatePickupScheduleDocument = gql`
    mutation UpdatePickupSchedule($input: UpdatePickupScheduleMutationInput!) {
  updatePickupSchedule(input: $input) {
    success
    errors {
      type
      message
      attribute
    }
  }
}
    `;
export type UpdatePickupScheduleMutationFn = Apollo.MutationFunction<UpdatePickupScheduleMutation, UpdatePickupScheduleMutationVariables>;

/**
 * __useUpdatePickupScheduleMutation__
 *
 * To run a mutation, you first call `useUpdatePickupScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePickupScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePickupScheduleMutation, { data, loading, error }] = useUpdatePickupScheduleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePickupScheduleMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePickupScheduleMutation, UpdatePickupScheduleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePickupScheduleMutation, UpdatePickupScheduleMutationVariables>(UpdatePickupScheduleDocument, options);
      }
export type UpdatePickupScheduleMutationHookResult = ReturnType<typeof useUpdatePickupScheduleMutation>;
export type UpdatePickupScheduleMutationResult = Apollo.MutationResult<UpdatePickupScheduleMutation>;
export type UpdatePickupScheduleMutationOptions = Apollo.BaseMutationOptions<UpdatePickupScheduleMutation, UpdatePickupScheduleMutationVariables>;
export const PickupSchedulesListDocument = gql`
    query PickupSchedulesList($q: PickupScheduleFilter, $first: Int, $after: String) {
  pickupSchedules(q: $q, first: $first, after: $after) {
    nodes {
      id
      customerId
      monday
      tuesday
      wednesday
      thursday
      friday
      saturday
      sunday
      pickupFrom
      pickupTo
      pickupLocation {
        id
        name
      }
    }
    totalNodes
  }
}
    `;

/**
 * __usePickupSchedulesListQuery__
 *
 * To run a query within a React component, call `usePickupSchedulesListQuery` and pass it any options that fit your needs.
 * When your component renders, `usePickupSchedulesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePickupSchedulesListQuery({
 *   variables: {
 *      q: // value for 'q'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function usePickupSchedulesListQuery(baseOptions?: Apollo.QueryHookOptions<PickupSchedulesListQuery, PickupSchedulesListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PickupSchedulesListQuery, PickupSchedulesListQueryVariables>(PickupSchedulesListDocument, options);
      }
export function usePickupSchedulesListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PickupSchedulesListQuery, PickupSchedulesListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PickupSchedulesListQuery, PickupSchedulesListQueryVariables>(PickupSchedulesListDocument, options);
        }
export type PickupSchedulesListQueryHookResult = ReturnType<typeof usePickupSchedulesListQuery>;
export type PickupSchedulesListLazyQueryHookResult = ReturnType<typeof usePickupSchedulesListLazyQuery>;
export type PickupSchedulesListQueryResult = Apollo.QueryResult<PickupSchedulesListQuery, PickupSchedulesListQueryVariables>;
export const CreatePickupScheduleDocument = gql`
    mutation CreatePickupSchedule($input: CreatePickupScheduleMutationInput!) {
  createPickupSchedule(input: $input) {
    success
    errors {
      type
      message
      attribute
    }
  }
}
    `;
export type CreatePickupScheduleMutationFn = Apollo.MutationFunction<CreatePickupScheduleMutation, CreatePickupScheduleMutationVariables>;

/**
 * __useCreatePickupScheduleMutation__
 *
 * To run a mutation, you first call `useCreatePickupScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePickupScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPickupScheduleMutation, { data, loading, error }] = useCreatePickupScheduleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePickupScheduleMutation(baseOptions?: Apollo.MutationHookOptions<CreatePickupScheduleMutation, CreatePickupScheduleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePickupScheduleMutation, CreatePickupScheduleMutationVariables>(CreatePickupScheduleDocument, options);
      }
export type CreatePickupScheduleMutationHookResult = ReturnType<typeof useCreatePickupScheduleMutation>;
export type CreatePickupScheduleMutationResult = Apollo.MutationResult<CreatePickupScheduleMutation>;
export type CreatePickupScheduleMutationOptions = Apollo.BaseMutationOptions<CreatePickupScheduleMutation, CreatePickupScheduleMutationVariables>;
export const CustomerPricingDocument = gql`
    query CustomerPricing($id: ID!) {
  customer(id: $id) {
    id
    country
    deliveryCountries {
      nodes {
        id
        customerId
        country
        volumeWeightKg
        minHeightCm
        minLengthCm
        minWidthCm
        maxHeightCm
        maxLengthCm
        maxWidthCm
        courierMinHeightCm
        courierMinLengthCm
        courierMinWidthCm
        courierMaxHeightCm
        courierMaxLengthCm
        courierMaxWidthCm
        priceRules {
          nodes {
            id
            customerId
            fixedPriceExVat
            minWeightKg
            maxWeightKg
            currency
            deliveryKind
          }
        }
      }
    }
  }
}
    `;

/**
 * __useCustomerPricingQuery__
 *
 * To run a query within a React component, call `useCustomerPricingQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerPricingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerPricingQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCustomerPricingQuery(baseOptions: Apollo.QueryHookOptions<CustomerPricingQuery, CustomerPricingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomerPricingQuery, CustomerPricingQueryVariables>(CustomerPricingDocument, options);
      }
export function useCustomerPricingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomerPricingQuery, CustomerPricingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomerPricingQuery, CustomerPricingQueryVariables>(CustomerPricingDocument, options);
        }
export type CustomerPricingQueryHookResult = ReturnType<typeof useCustomerPricingQuery>;
export type CustomerPricingLazyQueryHookResult = ReturnType<typeof useCustomerPricingLazyQuery>;
export type CustomerPricingQueryResult = Apollo.QueryResult<CustomerPricingQuery, CustomerPricingQueryVariables>;
export const UserEditDocument = gql`
    query UserEdit($userId: ID!, $customerId: ID!) {
  user(id: $userId) {
    id
    name
    email
    phone
    role(customerId: $customerId) {
      id
      customerId
      customerSettings
      invoiceAccess
      orderManagement
      userManagement
      active
    }
  }
}
    `;

/**
 * __useUserEditQuery__
 *
 * To run a query within a React component, call `useUserEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserEditQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useUserEditQuery(baseOptions: Apollo.QueryHookOptions<UserEditQuery, UserEditQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserEditQuery, UserEditQueryVariables>(UserEditDocument, options);
      }
export function useUserEditLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserEditQuery, UserEditQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserEditQuery, UserEditQueryVariables>(UserEditDocument, options);
        }
export type UserEditQueryHookResult = ReturnType<typeof useUserEditQuery>;
export type UserEditLazyQueryHookResult = ReturnType<typeof useUserEditLazyQuery>;
export type UserEditQueryResult = Apollo.QueryResult<UserEditQuery, UserEditQueryVariables>;
export const UpdateCustomerUserDocument = gql`
    mutation UpdateCustomerUser($input: UpdateCustomerUserMutationInput!) {
  updateCustomerUser(input: $input) {
    success
    errors {
      type
      message
      attribute
    }
  }
}
    `;
export type UpdateCustomerUserMutationFn = Apollo.MutationFunction<UpdateCustomerUserMutation, UpdateCustomerUserMutationVariables>;

/**
 * __useUpdateCustomerUserMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerUserMutation, { data, loading, error }] = useUpdateCustomerUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCustomerUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCustomerUserMutation, UpdateCustomerUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCustomerUserMutation, UpdateCustomerUserMutationVariables>(UpdateCustomerUserDocument, options);
      }
export type UpdateCustomerUserMutationHookResult = ReturnType<typeof useUpdateCustomerUserMutation>;
export type UpdateCustomerUserMutationResult = Apollo.MutationResult<UpdateCustomerUserMutation>;
export type UpdateCustomerUserMutationOptions = Apollo.BaseMutationOptions<UpdateCustomerUserMutation, UpdateCustomerUserMutationVariables>;
export const UsersListDocument = gql`
    query UsersList($q: UserFilter, $first: Int, $after: String, $customerId: ID) {
  users(q: $q, first: $first, after: $after) {
    nodes {
      id
      name
      email
      phone
      role(customerId: $customerId) {
        id
        active
      }
    }
    totalNodes
  }
}
    `;

/**
 * __useUsersListQuery__
 *
 * To run a query within a React component, call `useUsersListQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersListQuery({
 *   variables: {
 *      q: // value for 'q'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useUsersListQuery(baseOptions?: Apollo.QueryHookOptions<UsersListQuery, UsersListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersListQuery, UsersListQueryVariables>(UsersListDocument, options);
      }
export function useUsersListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersListQuery, UsersListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersListQuery, UsersListQueryVariables>(UsersListDocument, options);
        }
export type UsersListQueryHookResult = ReturnType<typeof useUsersListQuery>;
export type UsersListLazyQueryHookResult = ReturnType<typeof useUsersListLazyQuery>;
export type UsersListQueryResult = Apollo.QueryResult<UsersListQuery, UsersListQueryVariables>;
export const CreateCustomerUserDocument = gql`
    mutation CreateCustomerUser($input: CreateCustomerUserMutationInput!) {
  createCustomerUser(input: $input) {
    success
    errors {
      type
      message
      attribute
    }
  }
}
    `;
export type CreateCustomerUserMutationFn = Apollo.MutationFunction<CreateCustomerUserMutation, CreateCustomerUserMutationVariables>;

/**
 * __useCreateCustomerUserMutation__
 *
 * To run a mutation, you first call `useCreateCustomerUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomerUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomerUserMutation, { data, loading, error }] = useCreateCustomerUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCustomerUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateCustomerUserMutation, CreateCustomerUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCustomerUserMutation, CreateCustomerUserMutationVariables>(CreateCustomerUserDocument, options);
      }
export type CreateCustomerUserMutationHookResult = ReturnType<typeof useCreateCustomerUserMutation>;
export type CreateCustomerUserMutationResult = Apollo.MutationResult<CreateCustomerUserMutation>;
export type CreateCustomerUserMutationOptions = Apollo.BaseMutationOptions<CreateCustomerUserMutation, CreateCustomerUserMutationVariables>;